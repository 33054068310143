import * as React from 'react';

import { PieProps } from '../../Types/Shared';

import { ResponsivePie, DefaultRawDatum } from '@nivo/pie';
import { currency } from '../Pipes/Currency';

function Pie(props: PieProps): React.ReactElement {
    return (
        <ResponsivePie
            data={props.data}
            margin={{ top: 20, right: 0, bottom: 80, left: 0 }}
            innerRadius={0.75}
            padAngle={0.7}
            cornerRadius={3}
            colors={['#9CBFDB', '#76A6CE', '#4E8CBF', '#39719F', '#2B5577']}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableArcLabels={false}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#333333"
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabel={function (e: any): string {
                return currency(e.value, 0);
            }}
            enableArcLinkLabels={false}
            tooltip={(props: {
                datum: DefaultRawDatum;
            }): React.ReactElement => (
                <strong>
                    {props.datum.id}: {currency(props.datum.value, 2)}
                </strong>
            )}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: 56,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                            },
                        },
                    ],
                },
            ]}
        />
    );
}

export default Pie;
