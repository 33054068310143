import * as React from 'react';
import { ReactElement } from 'react';

import LogIn from '../Features/Auth/LogIn';
import SignUp from '../Features/Auth/SignUp';
import ResetEmail from '../Features/Auth/ResetEmail';
import ResetPassword from '../Features/Auth/ResetPassword';

import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AuthGuard from './AuthGuard';

function AuthRoutes(): ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <AuthGuard redirect={true} path={`${path}/login`}>
                <LogIn />
            </AuthGuard>
            <Route path={`${path}/signup`}>
                <SignUp />
            </Route>
            <Route path={`${path}/reset-email`}>
                <ResetEmail />
            </Route>
            <Route path={`${path}/password-reset/:key`}>
                <ResetPassword />
            </Route>

            <Redirect from="/auth" to="/auth/login" />
        </Switch>
    );
}

export default AuthRoutes;
