import { UserInfo } from "../../Types/Data";
import { MailingAddressFormProps } from "../../Types/Shared";

import axios, { AxiosResponse } from "axios";

export function savePersonalSettings(body: MailingAddressFormProps): Promise<AxiosResponse<UserInfo>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/update_user/',
        {
            ...body
        }
    )
}

export function changePassword(body: {oldPassword: string; newPassword: string}): Promise<AxiosResponse<any>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/change_password/',
        {
            ...body
        }
    )
}

export function saveContactInformation(body: {email: string}): Promise<AxiosResponse<UserInfo>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/update_user/',
        {
            ...body
        }
    )
}
