import axios, { AxiosResponse } from 'axios';

export function getDocument(location: string): Promise<AxiosResponse<any>> {
    return axios.post(
        `${process.env.REACT_APP_API_URL}users/document/`,
        {
            location: location,
        },
        {
            responseType: 'blob',
        },
    );
}
