import {
    Property,
    Update,
    Position,
    Document,
    Transaction,
    UserInfo,
    Asset,
    Image,
    Post,
    SignatureDocument,
    Unit,
    DwollaVerificationStatus,
    MicrodepositModalState,
    PlaidModalState,
    Calculations,
} from './Data';

export interface HomeState {
    emailCaptureResponse: string;
    formId?: number;
    toSignup: boolean;
}

export interface SupportState {
    success: boolean;
    error: boolean;
    asyncIsSubmitting: boolean;
}

export interface FaqState {
    aboutList: FaqList[];
    gettingStartedList: FaqList[];
    ourInvestmentsList: FaqList[];
    taxesList: FaqList[];
    activeKey: string;
}

export interface FaqList {
    id: number;
    title: string;
    body: string;
}

export interface HelpListProps {
    classes?: string;
    faqList: FaqList[];
}

export interface DashboardProps {
    userInfo: any;
    cookies: any;
    hasCheckedAuth: boolean;
    properties: Property[];
    setProperties: any;
}

export interface DashboardState {
    toLogin: boolean;
    sidebarActive: boolean;
}

export interface InvestmentProfile {
    positions: Position[];
    newsUpdates: Update[];
    documents: Document[];
    llcs: number[];
}

export interface MarketplaceProps {
    isAuthenticated: boolean;
}

export interface MarketplaceState {
    properties: Property[];
    filteredAssets: Asset[];
    assets: Asset[];
    hasCalledGetProperties: boolean;
    error: boolean;
    loading: boolean;
}

export interface TransactionsProps {
    transactions: Transaction[];
}

export interface TransactionState {
    filteredTransactions: Transaction[];
    ascendingDate: boolean;
}

export interface PortfolioProps {
    llcs: number[];
    userInfo: UserInfo;
}

export interface PortfolioState {
    positions: Property[];
    error: boolean;
    loading: boolean;
}

export interface NewsAndUpdatesProps {
    isAuthenticated: boolean;
    llcs: number[];
    updates: Update[];
    setUpdates: any;
}

export interface NewsAndUpdatesState {
    selectedUpdateIndex?: number;
    error: boolean;
    loading: boolean;
}

export interface DocumentsProps {
    documents: Document[];
}

export interface DocumentState {
    numPages?: number;
    pageNumber: number;
    document: any;
    error: boolean;
    loading: boolean;
}

export interface AccountOverviewProps {
    llcs: number[];
    updates: Update[];
    investmentProfile: InvestmentProfile;
    setUpdates: any;
    properties: Property[];
}

export interface AccountOverviewState {
    toNewsAndUpdates: boolean;
    error: boolean;
    isLoading: boolean;
}

export interface LogInState {
    redirectTo: LogInRedirects;
    loginErrorMessage: string;
}

export enum LogInRedirects {
    TO_DASHBOARD = 'toDashboard',
    TO_SIGN_UP = 'toSignUp',
    TO_PWD_RESET = 'toPwdReset',
    TO_MARKETPLACE = 'toMarketPlace',
    NO_ROUTE = '',
}

export interface ResetPwdState {
    toLogin: boolean;
    unsuccessfulReset: boolean;
    values: object;
}

export interface ReferState {
    referralCode: string;
}

export interface BankingProps {
    userInfo: UserInfo;
}

export interface BankingState {
    transactions: any;
    accounts: any;
    dwollaFundingSources: string[];
    dwolla_balance: number;
    hasFetchedDwollaBalance: boolean;
    selected_account: string;
    amount: number;
    transaction_type: string;
    hasFetchedAccounts: boolean;
    hasFetchedDwollaSources: boolean;
    verificationStatus: DwollaVerificationStatus;
    hasCheckedVerification: boolean;
    showModal: boolean;
    showMicrodepositModal: boolean;
    showTransferModal: boolean;
    linkToken: string;
    hasFetchedLinkToken: boolean;
    showKBAModal: boolean;
    showPlaidSuccessModal: boolean;
    microdepositState: MicrodepositModalState;
    plaidState: PlaidModalState;
}

export interface BankingFormProps {
    accounts: any[];
    selected_account: string;
    handleSubmit: any;
}

export interface MicrodepositFormProps {
    handleSubmit: Function;
}

export interface VerifyMicrodepositFormProps {
    handleSubmit: Function;
    accounts: string[];
}

export interface SignUpProps {
    cookies: any;
    setCookies: any;
    userInfo: any;
    setUserInfo: any;
}

export interface SignUpState {
    redirectTo: SignUpRedirects;
    values: ValidateUserFormValues;
    accountCreationErrorMessage: string;
    showModal: boolean;
    isLoading: boolean;
    notAuthorized: boolean;
}

export enum SignUpRedirects {
    TO_DASHBOARD = 'toDashboard',
    TO_LOG_IN = 'toLogIn',
    NO_ROUTE = '',
}

export interface ValidateUserFormValues {
    email: string;
    phone?: string;
    password: string;
    username?: string;
    firstName: string;
    lastName: string;
    isAccredited: boolean;
    ssn?: string;
    dob?: string;
    street?: string;
    apartment?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface SignUpFormValues {
    email: string;
    isAccredited: boolean;
    agree: boolean;
    password: string;
    username: string;
    firstName: string;
    lastName: string;
    ssn: string;
    phone: string;
    street: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    dob: string;
}

export interface SignUpFormBody {
    email?: string;
    password?: string;
    username?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    ssn?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    dob?: string;
    is_accredited?: boolean;
    referral?: string;
}

export interface AccountProps {
    userInfo: UserInfo;
}

export interface AccountState {
    toLogin: boolean;
    sidebarActive: boolean;
    activeMenuItem: string;
}

export interface PersonalInformationProps {
    settings: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
    };
    email: string;
}

export interface PropertyTimelineProps {
    updates: Update[];
    handleSelect: Function;
    hideCard?: boolean;
}

export interface ImageCarouselProps {
    images: Image[];
}

export interface PropertyMapProps {
    lat: number;
    lng: number;
    symbol: string;
}

export interface AssetDetailsProps {
    purchase_date: string;
    asset: Asset;
    authorizedPurchaseQuantity: number;
}

export interface PropertyFactsProps {
    description: string;
    units: Unit[];
    year_built: number | string;
    sq_foot: number | string;
    lot_size: number | string;
}

export interface ReturnOverviewProps {
    property: Property;
    perShare: boolean;
    totalShares: number;
    marketRent: boolean;
}

export interface SaleDetailsProps {
    asset: Asset;
    signatureDocuments: SignatureDocument[];
    positions: Position[];
    perShareToggled: Function;
    marketRentToggle: Function;
    investmentType: string;
}

export interface FinancialOverviewProps {
    calculations: Calculations;
}

export interface PropertyTabsProps {
    property: Property;
    perShare: boolean;
    asset: Asset;
    marketRent: boolean;
}

export interface PropertyOverviewProps {
    property: Property;
    symbol: string;
}

export interface FinancialDetailsOverviewProps {
    calculations: Calculations;
}

export interface FinancialProjectionsGraphProps {
    data: any;
    keys: string[];
    perShare: boolean;
}

export interface FinancialProjectionsTabsProps {
    property: Property;
    calculations: Calculations;
    perShare: boolean;
    totalShares: number;
}

export interface PropertyDocumentsProps {
    documents: Document[];
}

export interface AssetStatisticsProps {
    asset: Asset;
}

export interface PropertyDiscussionProps {
    posts: Post[];
    propertyId: number;
}

export interface PortfolioDetailsProps {
    equity: number;
    cash: number;
    builtEquity: number;
    dividends: number;
}

export interface PortfolioTableProps {
    positions: Position[];
}

export interface PropertyUpdateProps {
    update?: Update;
}

export interface PositionDetailProps {
    position: Position;
}

export interface RefinanceAnalysisProps {
    property: Property;
}
