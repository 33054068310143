import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { HelpListProps } from "../../../Types/Features";

function HelpList(props: HelpListProps): React.ReactElement {
    return (
        <div className={(props.classes || '')}>
            {props.faqList.map((item) =>
                <div key={item.id}>
                    <div className="d-flex align-items-center mb-2">
                        <FontAwesomeIcon className="pi-font-size-8 pi-color-primary" icon={faCircle} />
                        <h2 className="pi-font-size-20 ml-3 mb-0">{item.title}</h2>
                    </div>
                    <p className="ml-4">{item.body}</p>
                </div>
            )}
        </div>
    )
}

export default HelpList;