import axios, { AxiosResponse } from "axios";
import { Property } from "../../Types/Data";
import { SubmitTradeParams } from "../../Types/Services";

export function submitTrade(body: SubmitTradeParams): Promise<AxiosResponse<Property[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}market/buy_equity/`, body);
}

export function investorMetadata(id: number): Promise<AxiosResponse<string>> {
    return axios.get(`${process.env.REACT_APP_API_URL}market/get_investor_metadata/?id=${id}`);
}