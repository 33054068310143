import * as React from 'react';

import { Route, Switch, useRouteMatch } from "react-router-dom";
import JobPosting from "../Features/Home/Careers/JobPosting";
import Careers from "../Features/Home/Careers/Careers";

function CareerRoutes(): React.ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Careers />
            </Route>

            <Route path={`${path}/:id`}>
                <JobPosting />
            </Route>
        </Switch>
    );
}

export default CareerRoutes;