import * as React from 'react';

import { useSelector } from 'react-redux';

import { AssetStatisticsProps } from '../../../../Types/Features';
import { currency } from '../../../../Shared/Pipes/Currency';

import { ProgressBar } from 'react-bootstrap';

function AssetStatistics(props: AssetStatisticsProps): React.ReactElement {
    const [progress, setProgress] = React.useState<number>(0);

    React.useEffect(() => {
        const newProgress =
            ((props.asset.total_shares - props.asset.unsold_shares) /
                props.asset.total_shares) *
            100;
        setProgress(newProgress);
    }, [props.asset]);

    return (
        <div className="border rounded shadow-sm p-3 h-100">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-4">
                Offering Overview
            </h2>

            <div className="row mb-4 mb-lg-5">
                <div className="col-12">
                    {props.asset.subscription_information &&
                        props.asset.subscription_information.length && (
                            <h3 className="font-weight-bold pi-color-primary mb-2">
                                {currency(
                                    (props.asset.total_shares -
                                        props.asset.unsold_shares) *
                                        props.asset.subscription_information[0]
                                            .offering_price_per_unit,
                                )}
                            </h3>
                        )}
                    <p className="pi-color-primary-gray pi-font-size-14 mb-0">
                        {progress.toFixed(2)}% of Shares Sold
                    </p>
                    <ProgressBar now={progress} />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <h3 className="pi-font-size-16">Total Shares</h3>
                    <p className="pi-color-primary-gray">
                        {props.asset.total_shares}
                    </p>

                    <h3 className="pi-font-size-16">Unsold Shares</h3>
                    <p className="pi-color-primary-gray">
                        {props.asset.unsold_shares}
                    </p>

                    <h3 className="pi-font-size-16">Offering Price</h3>
                    <p className="pi-color-primary-gray">
                        {currency(props.asset.market_price, 2)}
                    </p>
                </div>

                <div className="col-6">
                    <h3 className="pi-font-size-16">Minimum Raise</h3>
                    <p className="pi-color-primary-gray">
                        {props.asset.subscription_information
                            ? currency(
                                  props.asset.subscription_information[0]
                                      ?.minimum_dollars_raised,
                                  0,
                              )
                            : ''}
                    </p>

                    <h3 className="pi-font-size-16">Maximum Raise</h3>
                    <p className="pi-color-primary-gray">
                        {props.asset.subscription_information
                            ? currency(
                                  props.asset.subscription_information[0]
                                      ?.maximum_dollars_raised,
                                  0,
                              )
                            : ''}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AssetStatistics;
