import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/TradingTicket.css';
import { TradingTicketProps } from '../../Types/Shared';
import TradingTicketForm from '../Forms/TradingTicketForm';
import TradingTicketReview from './TradingTicketReview';
import { getDwollaFundingSource } from '../../Services/Banking/BankingServices';
import { submitTrade } from '../../Services/Market/Trading';
import TradingTicketConfirmation from './TradingTicketConfirmation';
import { SubmitTradeParams } from '../../Types/Services';
import ESignatureContainer from '../ESignaturesContainer/ESignatureContainer';

import { Modal } from 'react-bootstrap';
import PersonalInfoModal from '../Modals/PersonalInfoModal';
import { checkDwollaVerificationStatus } from '../../Services/Auth/Auth';
import { StoreState } from '../../Types/Store';
import { useSelector } from 'react-redux';
import { DwollaVerificationStatus } from '../../Types/Data';
import DwollaKBA from '../DwollaKBA/DwollaKBA';
import { investorMetadata } from '../../Services/Market/Trading';

enum TICKET_STEP {
    review,
    confirmation,
    signing,
    tradingForm,
    verificationForm,
    kbaForm,
}

function TradingTicket(props: TradingTicketProps): ReactElement {
    const [step, setStep] = React.useState<TICKET_STEP>(
        TICKET_STEP.tradingForm,
    );
    const [quantity, setQuantity] = React.useState<number>(0);
    const [selectedAccount, setSelectedAccount] = React.useState<string>('');
    const [isSubmittingTrade, setIsSubmittingTrade] =
        React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const address = useSelector((state: StoreState) => {
        return {
            address1: state.userInfo.address1,
            address2: state.userInfo.address2,
            city: state.userInfo.city,
            state: state.userInfo.state,
            zip: state.userInfo.zip,
        };
    });
    const is_accredited = useSelector(
        (state: StoreState) => state.userInfo.is_accredited,
    );

    function checkVerificationStatus(): void {
        checkDwollaVerificationStatus()
            .then((res) => {
                if (res.data === DwollaVerificationStatus.Not_Verified) {
                    setStep(TICKET_STEP.verificationForm);
                } else if (res.data === DwollaVerificationStatus.KBA_Needed) {
                    setStep(TICKET_STEP.kbaForm);
                }
            })
            .catch(() => {
                // Let's add some error handling here...
            });
    }

    function checkAccreditedOnly(id: number): boolean {
        let total = 0;
        let accredited = 0;
        investorMetadata(id).then((res) => {
            total = JSON.parse(res.data)['total'];
            accredited = JSON.parse(res.data)['accredited'];
        });
        if (total - accredited >= 32) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        checkVerificationStatus();
        if (checkAccreditedOnly(props.asset.llc) && !is_accredited) {
            props.asset.unsold_shares = 0;
        }
    }, []);

    function handleTradeSubmit(value: { quantity: number }): void {
        setQuantity(value.quantity);
        setSelectedAccount('Balance');

        if (
            props.hasSignedInvestmentPackage &&
            value.quantity <=
                props.authorizedPurchaseQuantity - props.totalSharesOwned
        ) {
            setStep(TICKET_STEP.review);
        } else {
            setStep(TICKET_STEP.signing);
        }
    }

    function handleSigningSubmit(): void {
        setStep(TICKET_STEP.review);
    }

    function handleReviewSubmit(): void {
        setIsSubmittingTrade(true);
        setStep(TICKET_STEP.confirmation);

        const body: SubmitTradeParams = {
            account_id: selectedAccount,
            shares: quantity,
            symbol: props.asset.symbol,
        };

        submitTrade(body)
            .then(() => {
                setIsSubmittingTrade(false);
                setSuccess(true);
            })
            .catch(() => {
                setIsSubmittingTrade(false);
                setSuccess(false);
            });
    }

    function handleClose(): void {
        if (
            step !== TICKET_STEP.verificationForm &&
            step !== TICKET_STEP.kbaForm
        ) {
            setStep(TICKET_STEP.tradingForm);
        }
        props.close();
    }

    function renderBody(): ReactElement {
        switch (step) {
            case TICKET_STEP.review:
                return (
                    <TradingTicketReview
                        handleSubmit={handleReviewSubmit}
                        quantity={quantity}
                        asset={props.asset}
                        account={selectedAccount}
                    />
                );
            case TICKET_STEP.signing:
                return (
                    <ESignatureContainer
                        asset={props.asset}
                        quantity={quantity}
                        handleSubmit={handleSigningSubmit}
                        hasSignedInvestmentPackage={
                            props.hasSignedInvestmentPackage
                        }
                        authorizedPurchaseQuantity={
                            props.authorizedPurchaseQuantity
                        }
                        totalSharesOwned={props.totalSharesOwned}
                    />
                );
            case TICKET_STEP.confirmation:
                return (
                    <TradingTicketConfirmation
                        success={success}
                        isSubmittingTrade={isSubmittingTrade}
                        account={selectedAccount}
                        symbol={props.asset.symbol}
                        depositAmount={quantity * props.asset.market_price}
                        handleClose={handleClose}
                    />
                );
            case TICKET_STEP.verificationForm:
                return (
                    <PersonalInfoModal
                        handleClose={(): void => {
                            return;
                        }}
                        lightMode={false}
                        address1={address.address1}
                        address2={address.address2 ? address.address2 : ''}
                        city={address.city}
                        state={address.state}
                        zip={address.zip ? address.zip.toString() : ''}
                    />
                );

            default:
                return (
                    <TradingTicketForm
                        handleSubmit={handleTradeSubmit}
                        marketPrice={props.asset.market_price}
                        minInvestment={props.asset.minimum_investment}
                        unsoldShares={props.asset.unsold_shares}
                        availableCash={
                            props.positions.find(
                                (x) => x.asset.symbol === 'cash',
                            )?.num_shares
                        }
                        sharesOwned={props.totalSharesOwned}
                    />
                );
        }
    }

    function renderTitle(): string {
        switch (step) {
            case TICKET_STEP.signing:
                return 'Sign';
            case TICKET_STEP.verificationForm:
                return 'Identity Verification';
            default:
                return `Buy ${props.asset.symbol}`;
        }
    }

    function modalSize(): 'sm' | 'lg' | 'xl' | undefined {
        switch (step) {
            case TICKET_STEP.signing:
                return 'lg';
            case TICKET_STEP.verificationForm:
                return undefined;
            default:
                return 'sm';
        }
    }

    if (step === TICKET_STEP.kbaForm) {
        return <DwollaKBA show={props.show} close={handleClose} />;
    } else {
        return (
            <Modal show={props.show} onHide={handleClose} size={modalSize()}>
                <Modal.Header
                    className="pi-bg-color-dark border-secondary"
                    closeButton>
                    <Modal.Title className="text-white pi-font-size-16">
                        {renderTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pi-bg-color-dark">
                    {renderBody()}
                </Modal.Body>
            </Modal>
        );
    }
}

export default TradingTicket;
