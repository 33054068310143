export function date(date: string, format?: string): string | Date {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    if (!date) {
        return "--";
    }

    const dateObj = new Date(date);

    switch (format) {
        case 'mm/dd/yyyy':
            return [dateObj.getUTCMonth() + 1, dateObj.getUTCDate(), dateObj.getUTCFullYear()].join('-');
        case 'mmm, dd, yyyy':
            return `${monthNames[dateObj.getMonth()]} ${dateObj.getUTCDate()}, ${dateObj.getUTCFullYear()}`;
        default:
            return date;
    }
}