import * as React from 'react';

import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import { getKnowledgeArticles } from "../../../Services/Home/HomeServices";
import { KnowledgeArticle } from "../../../Types/Data";

import { Card, Badge } from 'react-bootstrap';

function KnowledgeCenter(): React.ReactElement {
    const [articles, setArticles] = React.useState<KnowledgeArticle[]>([])

    React.useEffect(() => {
        getKnowledgeArticles().then(res => {
            setArticles(res.data);
        });
    }, [])

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="container">
                <div className="row justify-content-center py-4 py-lg-5">
                    <div className="col-12">
                        <h1 className="pi-font-size-24 text-center">Knowledge Center</h1>
                    </div>
                </div>

                <div className="row justify-content-center pb-5 mb-0 mb-lg-3 align-items-stretch">
                    {articles.map(article =>
                        <div key={article.id} className="col-12 col-lg-3 mb-3">
                            <Card className="w-100 h-100">
                                <Card.Img variant="top" src={article.image} />
                                <Card.Body>
                                    <Card.Title>
                                        <span className="pi-color-primary-gray">{article.title}</span>
                                        <div className="font-weight-light pi-font-size-16 mt-1">
                                            {article.tags.map(tag =>
                                                <Badge
                                                    key={tag.id}
                                                    pill
                                                    variant="primary"
                                                    className="pi-bg-color-primary text-white mr-2 pi-cursor"
                                                >{tag.text}</Badge>
                                            )}
                                        </div>
                                    </Card.Title>
                                    <Card.Text>
                                        <div dangerouslySetInnerHTML={{__html: article.content.substring(0,150) + '...'}}></div>
                                        <a href={`/support/knowledge-center/${article.id}`}>Read More &gt;</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </div>
            </div>

            <HomeFooter />
        </div>
    )
}

export default KnowledgeCenter;
