import * as React from 'react';

import './Styles/Forms.css';
import { TradingTicketFormProps } from '../../Types/Shared';

import { currency } from '../Pipes/Currency';

import { number, object, string } from 'yup';

import { usePlaidLink } from 'react-plaid-link';

import { Button, Form } from 'react-bootstrap';

import { Formik } from 'formik';
import {
    getDwollaFundingSource,
    plaidLinkToken,
    plaidPublicToken,
} from '../../Services/Banking/BankingServices';

const TradingTicketSchema = object().shape({
    quantity: number()
        .min(1, 'Please enter a valid number of shares.')
        .required('Please enter a valid number of shares.')
        .positive('Please enter a valid number of shares.')
        .integer('Please enter a valid number of shares.'),
});

const TradingTicketForm = (
    props: TradingTicketFormProps,
): React.ReactElement => {
    const [minInvestmentError, setMinInvestmentError] =
        React.useState<string>('');
    const [availableCashError, setAvailableCashError] =
        React.useState<string>('');

    return (
        <Formik
            initialValues={{
                quantity: '0',
            }}
            validationSchema={TradingTicketSchema}
            onSubmit={(values): void => {
                setAvailableCashError('');
                setMinInvestmentError('');
                if (
                    parseInt(values.quantity) * props.marketPrice >
                    (props.availableCash ? props.availableCash : 0)
                ) {
                    setAvailableCashError(
                        `Your available cash balance is ${currency(
                            props.availableCash ? props.availableCash : 0,
                        )} please deposit more funds or change your investment amount.`,
                    );
                    return;
                }
                if (
                    parseInt(values.quantity) * props.marketPrice >
                    (props.availableCash ? props.availableCash : 0)
                ) {
                    setAvailableCashError(
                        `Your available cash balance is ${currency(
                            props.availableCash ? props.availableCash : 0,
                        )} please deposit more funds or change your investment amount.`,
                    );
                    return;
                }

                if (
                    (props.sharesOwned + parseInt(values.quantity)) *
                        props.marketPrice >=
                        props.minInvestment &&
                    parseInt(values.quantity) <= props.unsoldShares
                ) {
                    props.handleSubmit(values);
                } else {
                    setMinInvestmentError(
                        `The required minimum investment is ${currency(
                            props.minInvestment,
                        )}.`,
                    );
                }
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                errors,
                touched,
                setFieldValue,
            }): React.ReactElement => (
                <Form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="text-white pi-font-size-14 mb-0">
                            Available Cash
                        </p>
                        <p className="text-white pi-font-size-14 mb-0">
                            {currency(
                                props.availableCash ? props.availableCash : 0,
                            )}
                        </p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <p className="text-white pi-font-size-14 mb-0">
                            Shares
                        </p>
                        <Form.Group
                            className="w-50 mb-0"
                            controlId="purchaseQuantity">
                            <Form.Control
                                type="number"
                                name="quantity"
                                placeholder=""
                                className="text-right bg-transparent text-white"
                                onBlur={(): void => setMinInvestmentError('')}
                                onChange={handleChange}
                                value={values.quantity}
                            />
                        </Form.Group>
                    </div>

                    <div className="mb-3">
                        <small
                            id="quantityHelp"
                            className="form-text pi-color-secondary-gray text-right">
                            {props.unsoldShares} Available
                        </small>
                    </div>

                    <div className="d-flex justify-content-between border-secondary border-bottom">
                        <p className="text-white pi-font-size-14">
                            Market Price
                        </p>
                        <p className="text-white pi-font-size-14">
                            {currency(props.marketPrice)}
                        </p>
                    </div>

                    <div
                        className={
                            'd-flex justify-content-between mt-3 ' +
                            (errors.quantity && touched.quantity
                                ? 'mb-3'
                                : 'mb-4')
                        }>
                        <p className="text-white pi-font-size-14">
                            Estimated Cost
                        </p>
                        <p className="text-white pi-font-size-14">
                            {currency(
                                (values.quantity
                                    ? parseInt(values.quantity)
                                    : 0) * props.marketPrice,
                            )}
                        </p>
                    </div>

                    {errors.quantity && touched.quantity ? (
                        <div className="text-white pi-font-size-12 text-center mb-2">
                            {errors.quantity}
                        </div>
                    ) : null}

                    {minInvestmentError && touched.quantity ? (
                        <div className="text-white pi-font-size-12 text-center mb-2">
                            {minInvestmentError}
                        </div>
                    ) : null}

                    {availableCashError && touched.quantity ? (
                        <div className="text-white pi-font-size-12 text-center mb-2">
                            {availableCashError}
                        </div>
                    ) : null}

                    <Button
                        className="pi-bg-color-primary border-0 w-100 py-3"
                        type="submit">
                        Sign Documents & Review Order
                    </Button>
                </Form>
            )}
        />
    );
};

export default TradingTicketForm;
