import * as React from 'react';

import '../Styles/PropertyView.css';
import '../Styles/PropertyDocuments.css';
import { PropertyDocumentsProps } from '../../../../Types/Features';
import IconButton from '../../../../Shared/BasicComponents/IconButton';
import { getDocument } from '../../../../Services/Documents/Documents';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilePdf,
    faFile,
    faFileWord,
    faFileExcel,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

function PropertyDocuments(props: PropertyDocumentsProps): React.ReactElement {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingIndices, setIsLoadingIndices] = React.useState<number[]>(
        [],
    );

    function getDocumentType(location: string): React.ReactElement {
        if (location.includes('.pdf')) {
            return (
                <FontAwesomeIcon
                    className="pi-font-size-36 text-danger mr-4"
                    icon={faFilePdf}
                />
            );
        }

        if (location.includes('.doc')) {
            return (
                <FontAwesomeIcon
                    className="pi-font-size-36 text-primary mr-4"
                    icon={faFileWord}
                />
            );
        }

        if (location.includes('.xls')) {
            return (
                <FontAwesomeIcon
                    className="pi-font-size-36 text-success mr-4"
                    icon={faFileExcel}
                />
            );
        }

        return (
            <FontAwesomeIcon
                className="pi-font-size-36 text-secondary mr-4"
                icon={faFile}
            />
        );
    }

    function getPropertyDocument(
        location: string,
        id: number,
        callback: typeof openDocument,
    ): void {
        setIsLoading(true);
        const currentIndices = isLoadingIndices;
        currentIndices.push(id);
        setIsLoadingIndices(currentIndices);

        getDocument(location)
            .then((res) => {
                console.log(res.data);
                callback(res.data);
                setIsLoading(false);
                const newIndices = isLoadingIndices.filter(
                    (index) => index !== id,
                );
                setIsLoadingIndices(newIndices);
            })
            .catch(() => {
                setIsLoading(false);
                const newIndices = isLoadingIndices.filter(
                    (index) => index !== id,
                );
                setIsLoadingIndices(newIndices);
            });
    }

    function openDocument(data: any): void {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
    }

    function openIcon(location: string, id: number): React.ReactElement {
        if (isLoadingIndices.includes(id) && isLoading) {
            return (
                <Spinner
                    className="pi-font-size-12"
                    size="sm"
                    animation="border"
                />
            );
        }
        return (
            <IconButton
                classes="bg-transparent p-0"
                onClick={(): void =>
                    getPropertyDocument(location, id, openDocument)
                }>
                <FontAwesomeIcon
                    className="pi-color-tertiary"
                    icon={faExternalLinkAlt}
                />
            </IconButton>
        );
    }

    function documentRows(): React.ReactElement[] {
        let rows: React.ReactElement[] = [];
        const documentsCount: number = props.documents.length;
        for (let i = 0; i < documentsCount / 3; i++) {
            rows.push(
                <div
                    key={props.documents[i].id}
                    className="row align-items-lg-stretch">
                    {documentCols(i)}
                </div>,
            );
        }
        return rows;
    }

    function documentCols(rowIndex: number): React.ReactElement[] {
        let cols: React.ReactElement[] = [];
        for (
            let j = rowIndex * 3;
            j < (rowIndex + 1) * 3 && j < props.documents.length;
            j++
        ) {
            cols.push(
                <div key={props.documents[j].id} className="col-12 col-lg-4">
                    <div className="d-flex align-items-center justify-content-between mb-3 mb-lg-5 h-100 pi-border-bottom py-3 py-lg-0">
                        <div className="d-flex align-items-center">
                            {getDocumentType(props.documents[j].location)}
                            <div>
                                <h3 className="pi-font-size-18 mb-2">
                                    {props.documents[j].name}
                                </h3>
                                <p className="pi-color-primary-gray pi-font-size-14 mb-0 mr-3">
                                    {props.documents[j].description}
                                </p>
                            </div>
                        </div>
                        <div>
                            {openIcon(
                                props.documents[j].location,
                                props.documents[j].id,
                            )}
                        </div>
                    </div>
                </div>,
            );
        }
        return cols;
    }

    return (
        <div className="p-3 border rounded pi-top-border shadow-sm">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-2 mb-lg-4">
                Documents
            </h2>

            {documentRows()}
        </div>
    );
}

export default PropertyDocuments;
