import * as React from "react";

import { TradingTicketConfirmationProps } from "../../Types/Shared";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currency } from "../Pipes/Currency";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function TradingTicketConfirmation(props: TradingTicketConfirmationProps): React.ReactElement {

    if (props.isSubmittingTrade) {
        return (
            <div className="text-center py-5">
                <Spinner animation="border" variant="light" />
                <div className="text-white pi-font-size-20 mt-3">Submitting your transaction...</div>
            </div>
        );
    }

    if (props.success) {
        return (
            <div className="text-center">
                <FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faCheckCircle} />
                <p className="text-white mb-3">You successfully purchased shares of {props.symbol}!</p>
                <p className="text-white mb-3">
                    A deposit will be initiated from {props.account} in the amount
                    of {currency(props.depositAmount)}. Transfers usually take 3-5 business
                    days, once cleared your shares will be deposited into your account.
                </p>
                <Button
                    className="pi-bg-color-primary border-0 w-100 py-3"
                    onClick={props.handleClose}
                >
                    Close
                </Button>
            </div>
        );
    } else {
        return (
            <div className="text-center">
                <FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faExclamationTriangle} />
                <p className="text-white mb-3">Something went wrong while submitting your trade!</p>
                <p className="text-white mb-3">
                    Don&apos;t worry, no transfer has been initiated. Please close this window and try again. If the problem persists, please contact our support team.
                </p>
                <Button
                    className="pi-bg-color-primary border-0 w-100 py-3"
                    onClick={props.handleClose}
                >
                    Close
                </Button>
            </div>
        );
    }
}

export default TradingTicketConfirmation;
