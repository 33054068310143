import * as React from 'react';

import IconButton from '../../../../Shared/BasicComponents/IconButton';
import TradingTicket from '../../../../Shared/Trading/TradingTicket';
import { SaleDetailsProps } from '../../../../Types/Features';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMoneyBill,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { StoreState } from '../../../../Types/Store';
import {
    ContractTypeChoices,
    InvestmentTypeChoiceConversion,
    SignatureDocument,
} from '../../../../Types/Data';
import '../Styles/SaleDetails.css';

import { useSelector } from 'react-redux';

import { Badge, OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap';
import { currency } from '../../../../Shared/Pipes/Currency';
import { investorMetadata } from '../../../../Services/Market/Trading';

function SaleDetails(props: SaleDetailsProps): React.ReactElement {
    const [showTradingTicket, setShowTradingTicket] =
        React.useState<boolean>(false);
    const [hasSignedInvestmentPackage, setHasSignedInvestmentPackage] =
        React.useState<boolean>(false);
    const [authorizedPurchaseQuantity, setAuthorizedPurchaseQuantity] =
        React.useState<number>(0);
    const [totalSharesOwned, setTotalSharesOwned] = React.useState<number>(0);
    const [filteredDocuments, setFilteredDocuments] =
        React.useState<SignatureDocument[]>();
    const [perShare, setPerShare] = React.useState<boolean>(false);
    const [marketRent, setMarketRent] = React.useState<boolean>(false);
    const is_email_verified = useSelector(
        (state: StoreState) => state.userInfo.is_email_verified,
    );
    const is_accredited = useSelector(
        (state: StoreState) => state.userInfo.is_accredited,
    );
    const is_sophisticated = useSelector(
        (state: StoreState) => state.userInfo.is_sophisticated,
    );

    React.useEffect(() => {
        if (
            props.asset &&
            props.signatureDocuments &&
            props.signatureDocuments.length &&
            !filteredDocuments
        ) {
            const documents = props.signatureDocuments.filter(
                (document) => document.asset_id === props.asset.id,
            );
            setFilteredDocuments(documents);

            const signedInvestmentPackage = documents.some((document) => {
                return (
                    document.contract_type === ContractTypeChoices.IP &&
                    document.signed
                );
            });
            setHasSignedInvestmentPackage(signedInvestmentPackage);

            if (signedInvestmentPackage) {
                let totalQuantity = 0;
                documents.forEach((document) => {
                    if (
                        (document.contract_type === ContractTypeChoices.IP ||
                            document.contract_type ===
                                ContractTypeChoices.SA) &&
                        document.signed
                    ) {
                        totalQuantity += document.quantity;
                    }
                });
                setAuthorizedPurchaseQuantity(totalQuantity);
            }

            if (props.positions && props.positions.length) {
                let total = 0;
                props.positions
                    .filter((position) => position.asset.id === props.asset.id)
                    .forEach((position) => {
                        total += position.num_shares;
                    });
                setTotalSharesOwned(total);
            }
        }
    }, [
        props.asset,
        props.signatureDocuments,
        filteredDocuments,
        props.positions,
    ]);

    function openTradingTicket(): void {
        setShowTradingTicket(true);
    }

    function closeTradingTicket(): void {
        setShowTradingTicket(false);
    }

    function perShareToggled(): void {
        setPerShare(!perShare);
        props.perShareToggled(perShare);
    }

    function marketRentToggled(): void {
        setMarketRent(!marketRent);
        props.marketRentToggle(marketRent);
    }

    return (
        <div>
            <TradingTicket
                show={showTradingTicket}
                close={closeTradingTicket}
                asset={props.asset}
                documents={props.signatureDocuments}
                positions={props.positions}
                hasSignedInvestmentPackage={hasSignedInvestmentPackage}
                authorizedPurchaseQuantity={authorizedPurchaseQuantity}
                totalSharesOwned={totalSharesOwned}
            />

            <div className="d-lg-flex justify-content-lg-between">
                <div>
                    <Badge
                        pill
                        variant="primary"
                        className="pi-bg-color-primary shadow-sm mb-4 pi-font-size-14 py-2 px-3 mt-3 mt-lg-0">
                        {props.asset.active_offering
                            ? 'Active Offering'
                            : 'Closed Offering'}
                    </Badge>

                    <Badge
                        pill
                        variant="primary"
                        className="pi-bg-color-primary shadow-sm mb-4 pi-font-size-14 py-2 px-3 mt-3 mt-lg-0 ml-2">
                        {InvestmentTypeChoiceConversion(props.investmentType)}
                    </Badge>

                    <h2 className="pi-font-size-16 pi-color-primary-gray">
                        Share Price
                    </h2>
                    <h3>{currency(props.asset.market_price, 2)}</h3>

                    <div className="d-flex align-items-center pi-font-size-16 pi-color-primary-gray">
                        <span className="mr-1">Minimum Investment</span>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-min-investment">
                                    The minimum amount a new investor can
                                    purchase in the offering.
                                </Tooltip>
                            }>
                            <Button
                                variant="primary"
                                className="bg-transparent border-0 p-0">
                                <FontAwesomeIcon
                                    className="pi-font-size-16 pi-color-primary-gray"
                                    icon={faQuestionCircle}
                                />
                            </Button>
                        </OverlayTrigger>
                        <span className="ml-1">
                            : {currency(props.asset.minimum_investment, 0)}
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-between mt-3 mt-lg-0">
                    {props.asset.unsold_shares > 0 &&
                    props.asset.active_offering &&
                    (is_accredited || is_sophisticated) ? (
                        <div>
                            <IconButton
                                classes="pi-bg-color-accent-primary shadow-sm text-white rounded py-2 px-3 mb-3 w-100"
                                onClick={openTradingTicket}
                                disable={!is_email_verified}>
                                <span className="pi-font-size-20">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faMoneyBill}
                                    />
                                    Buy Shares
                                </span>
                            </IconButton>
                            {!is_email_verified && (
                                <p className="pi-color-primary-gray">
                                    You must verify your email address in order
                                    to invest.
                                </p>
                            )}
                        </div>
                    ) : null}

                    <div>
                        <Form>
                            <Form.Check
                                type="switch"
                                id="per-share-switch"
                                label="Per Share"
                                onClick={perShareToggled}
                            />
                        </Form>

                        <Form>
                            <Form.Check
                                type="switch"
                                id="market-rent-switch"
                                label="Market Rent"
                                onClick={marketRentToggled}
                            />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleDetails;
