import * as React from 'react';

import { FaqSearchFormProps } from "../../Types/Shared";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { Formik } from "formik";

const FaqSearchForm = (props: FaqSearchFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                query: ''
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
            }}
            render={({
                 values,
                 handleSubmit,
                 handleChange
             }): React.ReactElement => (
                <div>
                    <Form className={props.classes} onSubmit={handleSubmit}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-search" className="bg-white">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="py-4 border-left-0"
                                placeholder="Search our help center"
                                name="query"
                                type="text"
                                value={values.query}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </Form>
                </div>
            )}
        />
    )
};

export default FaqSearchForm;