
import * as React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faAngellist } from "@fortawesome/free-brands-svg-icons";
import IconButton from "../BasicComponents/IconButton";

const facebookUrl = 'https://facebook.com/peerinvest';
const twitterUrl = 'https://twitter.com/peerinvestapp';
const angelListUrl = 'https://angel.co/company/peer-invest-1';

class HomeFooter extends React.Component<{}, {}> {

    handleSocialMediaLink(url: string): void {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    render(): React.ReactElement {
        return (
            <div className="p-3 p-lg-5 pi-bg-color-light-gray">
                <div className="d-block d-md-flex justify-content-md-between">
                    <div className="d-block d-md-flex">
                        <div className="mr-0 mr-md-5 mb-5 md-mb-0">
                            <h3 className="pi-font-size-18 mb-4">Company</h3>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/company/about-us">About Us</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/support/request">Support</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/company/terms-of-service">Terms of Service</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/company/privacy-policy">Privacy Policy</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/company/careers">Careers</a>
                        </div>

                        <div className="ml-0 ml-md-5">
                            <h3 className="pi-font-size-18 mb-4">Resources</h3>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/support/how-it-works">How It Works</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/support/faq">Frequently Asked Questions</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/support/knowledge-center">Knowledge Center</a>
                            <a className="d-block mb-3 pi-font-size-16 text-dark" href="/support/blog">Blog</a>
                        </div>
                    </div>

                    <div className="d-flex pi-font-size-32 mt-5 mt-md-0">
                        <div className="pr-4">
                            <IconButton classes="text-black" onClick={(): void => this.handleSocialMediaLink(facebookUrl)}>
                                <FontAwesomeIcon className="text-black" icon={faFacebookSquare} />
                            </IconButton>
                        </div>
                        <div className="pr-4">
                            <IconButton classes="text-black" onClick={(): void => this.handleSocialMediaLink(twitterUrl)}>
                                <FontAwesomeIcon className="text-black" icon={faTwitterSquare} />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton classes="text-black" onClick={(): void => this.handleSocialMediaLink(angelListUrl)}>
                                <FontAwesomeIcon className="text-black" icon={faAngellist} />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <p className="mt-4 pi-font-size-12 pi-color-primary-gray">
                    &#169; Copyright 2021 PeerInvest, Inc. All Rights Reserved.
                </p>
                <p className="mt-4 pi-font-size-12 pi-color-primary-gray">
                    Past performance is no guarantee of future results. Any historical returns,
                    expected returns, or probability projections may not reflect actual future
                    performance. All securities involve risk and may result in partial or total loss.
                    PeerInvest does not provide tax advice and does not represent in any manner
                    that the outcomes described herein will result in any particular tax consequence.
                    Prospective investors should confer with their personal tax advisors regarding
                    the tax consequences based on their particular circumstances. PeerInvest
                    does not assume responsibility for the tax consequences for any investor of any
                    investment.
                </p>
            </div>
        );
    }
}

export default HomeFooter;