import * as React from 'react';
import { ReactElement } from 'react';

import { ImageContentCardProps } from '../../Types/Shared';
import { totalSquareFeet } from '../../Services/Properties/Properties';
import './Styles/ContentCard.css';
import './Styles/ImageContentCard.css';

import Card from 'react-bootstrap/Card';

import { useHistory } from 'react-router-dom';

import { sumBy } from 'lodash';
import { Badge } from 'react-bootstrap';

function ImageContentCard(props: ImageContentCardProps): ReactElement {
    const history = useHistory();

    function handleClick(url: string | undefined): void {
        if (url) {
            history.push(url);
        }
    }

    return (
        <div
            className={(props.classes || '') + (props.url ? ' pi-cursor' : '')}
            onClick={(): void => handleClick(props.url)}>
            <Card className="w-100">
                <Card.Img variant="top" src={props.image} />
                {props.activeOffering && (
                    <Card.ImgOverlay className="pt-2 pr-2">
                        <div className="row">
                            <div className="col-12 text-right">
                                <Badge
                                    pill
                                    variant="primary"
                                    className="pi-bg-color-accent-primary pi-font-size-12">
                                    Active Offering
                                </Badge>
                            </div>
                        </div>
                    </Card.ImgOverlay>
                )}
                <Card.ImgOverlay className="pi-overlay-height d-flex align-items-end p-0">
                    <div className="pi-text-overlay-background flex-grow-1 px-4 py-2">
                        <Card.Title className="text-white align-self-end mb-1">
                            {props.title}
                        </Card.Title>
                        {props.property && (
                            <p className="text-white pi-font-size-14 mb-0">
                                {sumBy(props.property.units, 'bedrooms')} bds,{' '}
                                {sumBy(props.property.units, (unit) => {
                                    return unit.bathrooms;
                                })}{' '}
                                ba | {totalSquareFeet(props.property)} sqft |
                                Built in {props.property.year_built}
                            </p>
                        )}
                    </div>
                </Card.ImgOverlay>
                <Card.Body>{props.children}</Card.Body>
            </Card>
        </div>
    );
}

export default ImageContentCard;
