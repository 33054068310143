import { Position, Property, TransactionType } from '../Types/Data';

export function getMonthlyRent(
    property: Property,
    marketRent: boolean = false,
): number {
    if (property) {
        let rent = 0;
        property.units.forEach((unit) => {
            if (marketRent) {
                rent += unit.market_rent;
            } else {
                rent += unit.rent;
            }
        });
        return rent;
    }
    return 0;
}

export function getTotalDividends(position: Position): number {
    let total = 0;

    position.transactions.forEach((transaction) => {
        if (transaction.transaction_type === TransactionType.Dividend) {
            total += transaction.num_shares * transaction.cost;
        }
    });

    return total;
}

export function getAllDividends(positions: Position[]): number {
    let total = 0;

    positions.forEach((position) => {
        position.transactions.forEach((transaction) => {
            if (transaction.transaction_type === TransactionType.Dividend) {
                total += transaction.num_shares * transaction.cost;
            }
        });
    });

    return total;
}

export function getGainLoss(position: Position): number {
    const totalDividends = getTotalDividends(position);

    let costBasis = 0;
    let costBasisCount = 0;
    position.transactions.forEach((transaction) => {
        if (transaction.transaction_type === TransactionType.Buy) {
            costBasis += transaction.cost;
            costBasisCount++;
        }
    });
    costBasis = costBasis / costBasisCount;

    if (costBasisCount === 0) return 0;

    return (
        (position.asset.market_price +
            totalDividends / position.num_shares -
            costBasis) /
        costBasis
    );
}

export function getTotalGainLoss(positions: Position[]): number {
    let total = 0;

    positions.forEach((position) => {
        if (position.asset.symbol !== 'cash') {
            let costBasis = 0;
            position.transactions.forEach((transaction) => {
                if (transaction.transaction_type === TransactionType.Buy) {
                    costBasis += transaction.cost * transaction.num_shares;
                }
            });
            total +=
                position.asset.market_price * position.num_shares - costBasis;
        }
    });

    return total;
}

export function getTotalBalance(
    positions: Position[],
    excludeCash: boolean = false,
): number {
    let balance = 0;

    positions.forEach((position) => {
        if (excludeCash && position.asset.symbol !== 'cash') {
            balance += position.num_shares * position.asset.market_price;
        } else if (!excludeCash) {
            balance += position.num_shares * position.asset.market_price;
        }
    });

    return balance;
}

export function getTotalCash(positions: Position[]): number {
    let cash = 0;
    const cashPosition = positions.find(
        (position) => position.asset.symbol === 'cash',
    );

    if (cashPosition) {
        cash = cashPosition.num_shares * cashPosition.cost;
    }

    return cash;
}
