import * as React from "react";
import { states } from "../../Assets/states";
import { PersonalInformationFormProps}  from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import { string, object } from "yup";

import Col from "react-bootstrap/Col";

const PersonalInformationSchema = object().shape({
    ssn: string()
        .min(11, 'Too short!')
        .max(11, 'Too long!')
        .required('*Required'),
    dob: string()
        .required('*Required')
        .matches(
            /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
            "Must be in the form of MM/DD/YYYY"
        ),
    address1: string()
        .required('*Required'),
    address2: string(),
    city: string()
        .required('*Required'),
    state: string()
        .required('*Required'),
    zip: string()
        .max(5, "Too long!")
        .min(5, "Too short!")
        .required('*Required')
});

const PersonalInformationForm = (props: PersonalInformationFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                ssn: props.values.ssn ? props.values.ssn : '',
                dob: props.values.dob ? props.values.dob : '',
                address1: props.values.address1,
                address2: props.values.address2,
                city: props.values.city,
                state: props.values.state ? props.values.state : states[0],
                zip: props.values.zip
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
            }}
            validationSchema={PersonalInformationSchema}
            render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleSubmit,
                 isSubmitting,
                 setFieldValue
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <p className={props.lightMode ? 'pi-color-primary-gray' : 'text-white'}>
                        We must verify your identity before you are able invest on our platform.
                        All data is transferred securely to a third party to handle the verification process.
                    </p>

                    <Form.Group controlId="personalInformationForm1">
                        <Form.Label>
                            <span className={props.lightMode ? '' : 'text-white'}>Social Security Number (SSN)</span>
                            {errors.ssn === '*Required' && touched.ssn ? (
                                <span className="text-danger ml-2">{errors.ssn}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            className="py-2"
                            name="ssn"
                            type="text"
                            onChange={(e: any): void => {
                                if (e.target.value.length > 11) {
                                    setFieldValue('ssn', e.target.value.substring(0,11));
                                } else {
                                    const SSN = e.target.value.match(/(\d{3})(\d{2})(\d{4})/);
                                    let SSNFormatted;
                                    if (SSN && SSN.length > 0) {
                                        SSNFormatted = SSN[1] + "-" + SSN[2] + "-" + SSN[3];
                                    } else {
                                        SSNFormatted = e.target.value;
                                    }
                                    setFieldValue('ssn', SSNFormatted);
                                }
                            }}
                            value={values.ssn}
                        />
                        {errors.ssn !== '*Required' && touched.ssn ? (
                            <span className="text-danger">{errors.ssn}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="personalInformationForm2">
                        <Form.Label>
                            <span className={props.lightMode ? '' : 'text-white'}>Date of Birth (MM/DD/YYYY)</span>
                            {errors.dob === '*Required' && touched.dob ? (
                                <span className="text-danger ml-2">{errors.dob}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="dob"
                            type="text"
                            className="py-2"
                            onChange={(e: any): void => {
                                if (e.target.value.length > 10) {
                                    setFieldValue('dob', e.target.value.substring(0,10));
                                } else {
                                    const dob = e.target.value.match(/(\d{2})(\d{2})(\d{4})/);
                                    let dobFormatted;
                                    if (dob && dob.length > 0) {
                                        dobFormatted = dob[1] + "/" + dob[2] + "/" + dob[3];
                                    } else {
                                        dobFormatted = e.target.value;
                                    }
                                    setFieldValue('dob', dobFormatted);
                                }
                            }}
                            value={values.dob}
                        />
                        {errors.dob !== '*Required' && touched.dob ? (
                            <span className="text-danger">{errors.dob}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="personalInformationForm3">
                        <Form.Label>
                            <span className={props.lightMode ? '' : 'text-white'}>Address</span>
                            {errors.address1 === '*Required' && touched.address1 ? (
                                <span className="text-danger ml-2">{errors.address1}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            className="py-2"
                            name="address1"
                            type="text"
                            placeholder="Street address"
                            onChange={handleChange}
                            value={values.address1}
                        />
                        {errors.address1 !== '*Required' && touched.address1 ? (
                            <span className="text-danger">{errors.address1}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="personalInformationForm4">
                        <Form.Label>
                            <span className={props.lightMode ? '' : 'text-white'}>Address 2</span>
                            {errors.address2 === '*Required' && touched.address2 ? (
                                <span className="text-danger ml-2">{errors.address2}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            className="py-2"
                            name="address2"
                            type="text"
                            placeholder="Apartment, studio, or floor"
                            onChange={handleChange}
                            value={values.address2}
                        />
                        {errors.address2 !== '*Required' && touched.address2 ? (
                            <span className="text-danger">{errors.address2}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="personalInformationForm5">
                            <Form.Label>
                                <span className={props.lightMode ? '' : 'text-white'}>City</span>
                                {errors.city === '*Required' && touched.city ? (
                                    <span className="text-danger ml-2">{errors.city}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                className="py-2"
                                name="city"
                                type="text"
                                onChange={handleChange}
                                value={values.city}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="personalInformationForm6">
                            <Form.Label>
                                <span className={props.lightMode ? '' : 'text-white'}>State</span>
                                {errors.state === '*Required' && touched.state ? (
                                    <span className="text-danger ml-2">{errors.state}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                className="py-2"
                                name="state"
                                as="select"
                                onChange={handleChange}
                                value={values.state}
                            >
                                {states.map((state: string) =>
                                    <option key={state} value={state}>{state}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="personalInformationForm7">
                            <Form.Label>
                                <span className={props.lightMode ? '' : 'text-white'}>Zip Code</span>
                                {errors.zip === '*Required' && touched.zip ? (
                                    <span className="text-danger ml-2">{errors.zip}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                className="py-2"
                                name="zip"
                                type="number"
                                onChange={handleChange}
                                value={values.zip}
                            />
                        </Form.Group>
                    </Form.Row>

                    {errors.city !== '*Required' && touched.city ? (
                        <div className="text-danger">{errors.city}</div>
                    ) : null}

                    {errors.state !== '*Required' && touched.state ? (
                        <div className="text-danger">{errors.state}</div>
                    ) : null}

                    {errors.zip !== '*Required' && touched.zip ? (
                        <div className="text-danger">{errors.zip}</div>
                    ) : null}

                    <div className="row mx-0 mt-2">
                        <div className="col-12 pr-0 pl-0">
                            <Button className="w-100 pi-primary-btn py-2 pi-font-size-20" disabled={isSubmitting} type="submit">
                                Verify
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        />
    )
};

export default PersonalInformationForm;
