import * as React from 'react';

import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import { JobPosting } from "../../../Types/Data";
import { getJobPostings } from "../../../Services/Home/HomeServices";
import { date } from "../../../Shared/Pipes/Date";

function Careers(): React.ReactElement {
    const [careers, setCareers] = React.useState<JobPosting[]>([]);

    React.useEffect(() => {
        getJobPostings().then(res => {
            if (res.data && res.data.length) {
                setCareers(res.data.filter(posting => posting.active));
            }
        });
    }, [])

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="pi-bg-color-secondary">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pi-bg-color-secondary py-5">
                            <h1 className="text-white text-center">We Are Hiring!</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 py-5">
                        <h2 className="pi-font-size-20 text-center mb-3">WHY PEERINVEST</h2>

                        <p className="text-center text-md-left">
                            PeerInvest&apos;s mission is democratize real estate investing. By lowering barriers to entry, we can unlock
                            the wealth generating potential of this asset class. We seek to hire talented and motivated individuals
                            who share this belief that real estate investing is for everyone.
                        </p>
                    </div>
                </div>
            </div>

            <div className="pi-bg-color-primary-gray">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 pt-5 pb-3">
                            <h2 className="pi-font-size-20 text-white text-center mb-3">CURRENT JOB OPENINGS</h2>
                        </div>
                    </div>

                    {careers.map((career, index) =>
                        <div key={career.id} className={'row justify-content-center pi-bg-color-primary-gray ' + ((index + 1) === careers.length ? 'pb-5' : '')}>
                            <div className="col-12 col-md-8">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <a href={`/company/careers/${career.id}`} className="pi-font-size-20 pi-color-primary">
                                            {career.title}
                                        </a>

                                        <p className="pi-font-size-18 text-white mb-0">
                                            {career.location === 'RM' ? 'Remote' : 'Open for Discussion'}
                                        </p>
                                    </div>
                                    <div className="text-white pi-font-size-18">
                                        Posted: {date(career.created_at, 'mmm, dd, yyyy')}
                                    </div>
                                </div>

                                <hr className="border-white"></hr>
                            </div>
                        </div>
                    )}
                </div>

                <HomeFooter />
            </div>
        </div>
    );
}

export default Careers;
