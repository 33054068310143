import * as React from "react";

import { ImageCarouselProps } from "../../../../Types/Features";
import { Carousel } from "react-bootstrap";
import { Image } from "../../../../Types/Data";
import "../Styles/ImageCarousel.css";

function ImageCarousel(props: ImageCarouselProps): React.ReactElement {

    function getSortedImages(images: Image[]): Image[] {
        return images.sort(image => {
            return image.classifier === 'hero' ? -1 : 1;
        });
    }

    return (
        <Carousel interval={10000000} className="border rounded shadow-sm pi-max-height pi-bg-color-dark">
            {getSortedImages(props.images).map(image =>
                <Carousel.Item className="text-center" key={image.location}>
                    <img
                        className="pi-carousel-image"
                        src={image.location}
                        alt="First slide"
                    />
                </Carousel.Item>
            )}
        </Carousel>
    )
}

export default ImageCarousel;
