import { addToken, removeToken } from "../Utils/AuthUtils";
import { SupportRequestParams } from "../../Types/Services";
import { JobPosting, KnowledgeArticle, Post } from "../../Types/Data";

import axios, { AxiosResponse } from "axios";

export function supportRequest(body: SupportRequestParams): Promise<AxiosResponse<string>>{
    const token = removeToken();

    const request: Promise<AxiosResponse<string>> =
        axios.post(process.env.REACT_APP_API_URL + 'support/support_request/', {
            ...body,
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function captureEmail(email: string): Promise<AxiosResponse<string>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<string>> =
        axios.post(process.env.REACT_APP_API_URL + 'support/email/', {
            email: email,
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function getBlogPosts(): Promise<AxiosResponse<Post[]>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<Post[]>> =
        axios.get(process.env.REACT_APP_API_URL + 'support/blog/', {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function getBlogPost(id: number): Promise<AxiosResponse<Post>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<Post>> =
        axios.get(process.env.REACT_APP_API_URL + `support/blog_post/?id=${id}`, {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function getJobPostings(): Promise<AxiosResponse<JobPosting[]>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<JobPosting[]>> =
        axios.get(process.env.REACT_APP_API_URL + 'support/job_postings/', {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function getJobPosting(id: number): Promise<AxiosResponse<JobPosting>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<JobPosting>> =
        axios.get(process.env.REACT_APP_API_URL + `support/job_posting/?id=${id}`, {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function verifyEmail(token: string): Promise<AxiosResponse<string>> {
    return axios.post(process.env.REACT_APP_API_URL + `support/verify_email/`, {
       token
    });
}

export function getKnowledgeArticles(): Promise<AxiosResponse<KnowledgeArticle[]>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<KnowledgeArticle[]>> =
        axios.get(process.env.REACT_APP_API_URL + 'support/knowledge_articles/', {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}

export function getKnowledgeArticle(id: string): Promise<AxiosResponse<KnowledgeArticle>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<KnowledgeArticle>> =
        axios.get(process.env.REACT_APP_API_URL + `support/knowledge_article/?id=${id}`, {
            headers: {
                'Authorization': null
            }
        });

    addToken(token);

    return request;
}