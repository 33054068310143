import * as React from 'react';

import { AccountBalancePoint } from '../../../Types/Data';
import ContentCard from '../../../Shared/BasicComponents/ContentCard';
import { StoreState } from '../../../Types/Store';
import {
    getAllDividends,
    getTotalBalance,
    getTotalCash,
    getTotalGainLoss,
} from '../../../Utils/Calculations';
import PortfolioDetails from './PortfolioDetails';

import { useSelector, useDispatch } from 'react-redux';

import { Serie } from '@nivo/line';
import PortfolioTable from './PortfolioTable';
import { getProperties } from '../../../Services/Properties/Properties';
import { ActionTypes } from '../../../Store/Actions';
import { lineDataManipulator } from '../../../Utils/ChartData';
import PortfolioBalanceChart from './PortfolioBalanceChart';

function PortfolioView(): React.ReactElement {
    const [series, setSeries] = React.useState<Serie[]>([]);
    const [graphValue, setGraphValue] = React.useState<number>(0);
    const positions = useSelector(
        (store: StoreState) => store.userInfo.positions,
    );
    const accountBalancePoints = useSelector(
        (store: StoreState) => store.userInfo.account_balance_points,
    );
    const propertiesFetched = useSelector(
        (store: StoreState) => store.properties && store.properties.length,
    );
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (positions) {
            setGraphValue(getTotalBalance(positions));
        }

        if (accountBalancePoints) {
            setChartSeries(sortPoints(accountBalancePoints));
        }

        if (!propertiesFetched) {
            getProperties().then((res) => {
                dispatch({
                    type: ActionTypes.SET_PROPERTIES,
                    newProperties: res.data,
                });
            });
        }
    }, [accountBalancePoints, positions, dispatch, propertiesFetched]);

    function setChartSeries(points: AccountBalancePoint[]): void {
        setSeries(lineDataManipulator(points));
    }

    function sortPoints(points: AccountBalancePoint[]): AccountBalancePoint[] {
        return points.sort((a, b) => {
            return (
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            );
        });
    }

    return (
        <div className="container mt-3">
            <div className="row mb-3 align-items-stretch">
                <div className="col-12 col-lg-8">
                    <div className="flex-fill border rounded shadow-sm px-4 py-3">
                        <PortfolioBalanceChart />
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <ContentCard classes={'h-100'}>
                        <PortfolioDetails
                            equity={getTotalBalance(positions, true)}
                            cash={getTotalCash(positions)}
                            builtEquity={getTotalGainLoss(positions)}
                            dividends={getAllDividends(positions)}
                        />
                    </ContentCard>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <ContentCard>
                        <div>
                            <h1 className="pi-font-size-20 mb-2">Portfolio</h1>
                            <PortfolioTable positions={positions} />
                        </div>
                    </ContentCard>
                </div>
            </div>
        </div>
    );
}

export default PortfolioView;
