import * as React from 'react';

import { currency } from '../../../../Shared/Pipes/Currency';
import { percent } from '../../../../Shared/Pipes/Percent';
import { ReturnOverviewProps } from '../../../../Types/Features';
import { getMonthlyRent } from '../../../../Utils/Calculations';
import ComingSoonPlaceholder from './ComingSoonPlaceholder';
import { Calculations } from '../../../../Types/Data';

import { OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function ReturnOverview(props: ReturnOverviewProps): React.ReactElement {
    const [returnIndex, setReturnIndex] = React.useState<number>(0);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [calculations, setCalculations] = React.useState<Calculations>(
        props.property.calculations,
    );

    React.useEffect(() => {
        if (props.marketRent) {
            setCalculations(props.property.market_rent_calculations);
        } else {
            setCalculations(props.property.calculations);
        }
    }, [
        props.marketRent,
        props.property.market_rent_calculations,
        props.property.calculations,
    ]);

    function handleClose(): void {
        setShowModal(false);
    }

    function perShareAdjustor(num: number): number {
        if (props.perShare) {
            return num / props.totalShares;
        }
        return num;
    }

    return (
        <div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Calculation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Calculation explanations will be provided in this modal when
                    available.
                    <ComingSoonPlaceholder />
                </Modal.Body>
            </Modal>

            <div className="d-lg-flex rounded border pi-top-border shadow-sm p-3">
                <div className="col-12 col-lg-6">
                    <div className="d-flex h-100 justify-content-between flex-column">
                        <div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center">
                                    <h2 className="pi-font-size-16 mb-0 mr-2">
                                        Purchase Price
                                    </h2>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-price">
                                                <span className="text-left">
                                                    The price paid for the
                                                    property by PeerInvest when
                                                    first acquired on the open
                                                    market.
                                                </span>
                                            </Tooltip>
                                        }>
                                        <Button
                                            variant="primary"
                                            className="bg-transparent border-0 p-0">
                                            <FontAwesomeIcon
                                                className="pi-font-size-16 pi-color-primary-gray"
                                                icon={faQuestionCircle}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                                <span className="pi-font-size-18 pi-color-primary-gray">
                                    {currency(props.property.purchase_price, 0)}
                                </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center">
                                    <h2 className="pi-font-size-16 mb-0 mr-2">
                                        Down Payment
                                    </h2>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-down-payment">
                                                The amount PeerInvest paid
                                                upfront and the initial equity
                                                in the property.
                                            </Tooltip>
                                        }>
                                        <Button
                                            variant="primary"
                                            className="bg-transparent border-0 p-0">
                                            <FontAwesomeIcon
                                                className="pi-font-size-16 pi-color-primary-gray"
                                                icon={faQuestionCircle}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                                <span className="pi-font-size-18 pi-color-primary-gray">
                                    {currency(props.property.down_payment, 0)}
                                </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center">
                                    <h2 className="pi-font-size-16 mb-0 mr-2">
                                        Rent
                                    </h2>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-rent">
                                                The monthly rent amount being
                                                collected from current tenants.
                                            </Tooltip>
                                        }>
                                        <Button
                                            variant="primary"
                                            className="bg-transparent border-0 p-0">
                                            <FontAwesomeIcon
                                                className="pi-font-size-16 pi-color-primary-gray"
                                                icon={faQuestionCircle}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                                <span className="pi-font-size-18 pi-color-primary-gray">
                                    {currency(
                                        getMonthlyRent(
                                            props.property,
                                            props.marketRent,
                                        ),
                                        0,
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className="d-none d-lg-block">
                            <Button
                                className="p-0"
                                variant="link"
                                onClick={(): void => setShowModal(true)}>
                                How are these calculated?
                            </Button>
                        </div>
                    </div>
                </div>

                <hr className="d-lg-none"></hr>

                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Total Return
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-total-return">
                                        The projected total return the property
                                        is expected to generate over the
                                        selected timeframe.
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {currency(
                                perShareAdjustor(
                                    calculations.total_return[returnIndex],
                                ),
                                0,
                            )}
                        </span>
                    </div>

                    <div className="d-flex border rounded mb-2">
                        <div className="col-3 px-0">
                            <Button
                                variant={
                                    returnIndex === 0 ? 'primary' : 'light'
                                }
                                onClick={(): void => setReturnIndex(0)}
                                className={
                                    'p-2 w-100 border-0 rounded-0 pi-font-size-14 rounded-left ' +
                                    (returnIndex === 0
                                        ? 'pi-bg-color-primary text-white'
                                        : 'bg-transparent text-black')
                                }>
                                5 Yrs
                            </Button>
                        </div>

                        <div className="col-3 px-0">
                            <Button
                                variant={
                                    returnIndex === 1 ? 'primary' : 'light'
                                }
                                onClick={(): void => setReturnIndex(1)}
                                className={
                                    'p-2 w-100 border-0 pi-font-size-14 rounded-0 ' +
                                    (returnIndex === 1
                                        ? 'pi-bg-color-primary text-white'
                                        : 'bg-transparent text-black')
                                }>
                                10 Yrs
                            </Button>
                        </div>

                        <div className="col-3 px-0">
                            <Button
                                variant={
                                    returnIndex === 2 ? 'primary' : 'light'
                                }
                                onClick={(): void => setReturnIndex(2)}
                                className={
                                    'p-2 w-100 border-0 pi-font-size-14 rounded-0 ' +
                                    (returnIndex === 2
                                        ? 'pi-bg-color-primary text-white'
                                        : 'bg-transparent text-black')
                                }>
                                20 Yrs
                            </Button>
                        </div>

                        <div className="col-3 px-0">
                            <Button
                                variant={
                                    returnIndex === 3 ? 'primary' : 'light'
                                }
                                onClick={(): void => setReturnIndex(3)}
                                className={
                                    'p-2 w-100 border-0 rounded-0 pi-font-size-14 rounded-right ' +
                                    (returnIndex === 3
                                        ? 'pi-bg-color-primary text-white'
                                        : 'bg-transparent text-black')
                                }>
                                30 Yrs
                            </Button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Annualized Return
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-annualized-return">
                                        An annualized total return is the
                                        average amount of money earned by an
                                        investment each year over a given time
                                        period. Also referred to as the internal
                                        rate of return (IRR).
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {percent(calculations.annualized_return, 1)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Cap Rate
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-cap-rate">
                                        The cap rate is used in the world of
                                        real estate to indicate the rate of
                                        return that is expected to be generated
                                        on a real estate investment property.
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {percent(calculations.cap_rate, 1)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Gross Yield
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-gross-yield">
                                        Simply, 12 months of rent payments
                                        divided by the property price.
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {percent(calculations.gross_yield, 1)}
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Cash Flow
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-cash-flow">
                                        Cash flow is the net amount of cash
                                        being transferred into and out of the
                                        property. Net operating expenses minus
                                        mortgage payments.
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {currency(
                                perShareAdjustor(calculations.cash_flow),
                                props.perShare ? 2 : 0,
                            )}
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="pi-font-size-16 mb-0 mr-2">
                                Appreciation
                            </h2>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-appreciation">
                                        Estimated rate of increase in the value
                                        of the property each year.
                                    </Tooltip>
                                }>
                                <Button
                                    variant="primary"
                                    className="bg-transparent border-0 p-0">
                                    <FontAwesomeIcon
                                        className="pi-font-size-16 pi-color-primary-gray"
                                        icon={faQuestionCircle}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <span className="pi-font-size-18 pi-color-primary-gray">
                            {percent(calculations.appreciation, 1)}
                        </span>
                    </div>

                    <div className="d-lg-none mt-3 mt-lg-0">
                        <Button
                            className="p-0"
                            variant="link"
                            onClick={(): void => setShowModal(true)}>
                            How are these calculated?
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnOverview;
