import * as React from 'react';

import { date } from '../Pipes/Date';
import { currency } from '../Pipes/Currency';

import { ResponsiveLine } from '@nivo/line';
import { AccountBalanceProps } from '../../Types/Shared';

function AccountBalance(props: AccountBalanceProps): React.ReactElement {
    return (
        <ResponsiveLine
            data={props.data}
            margin={{ top: 25, right: 10, bottom: 25, left: 10 }}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
            curve="natural"
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                format: '%b %d',
                tickValues: `every ${props.increment} days`,
                legendOffset: 0,
            }}
            enableGridX={false}
            enableGridY={false}
            colors={['#0190E8']}
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            enableArea={true}
            areaBaselineValue={props.min}
            areaOpacity={0.3}
            tooltip={({ point }): React.ReactElement => (
                <strong>
                    {date(point.data.x.toString(), 'mm/dd/yyyy')}:{' '}
                    {currency(point.data.y as number, 0)}
                </strong>
            )}
            crosshairType="x"
            onMouseMove={(point) => {
                props.mouseOverHandler(point.data.y);
            }}
        />
    );
}

export default AccountBalance;
