import axios, { AxiosResponse } from "axios";

export function getContract(
    symbol: string, template_id: string, contract_type: string, quantity: number
): Promise<AxiosResponse<any>> {
    return axios.post(
        `${process.env.REACT_APP_API_URL}users/sign_page_url/`,
        {
            symbol: symbol,
            template_id: template_id,
            contract_type: contract_type,
            quantity: quantity
        }
    );
}