import * as React from 'react';

import MasterHeader from "../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../Shared/HomeFooter/HomeFooter";

function Error404Page(): React.ReactElement {
    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="container">
                <div className="row my-5">
                    <div className="col-12 py-5">
                        <h1 className="text-center my-5">Oops! This page does not exist. Please try another.</h1>
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    )
}

export default Error404Page;
