import * as React from 'react';

import { PortfolioDetailsProps } from "../../../Types/Features";
import { currency } from "../../../Shared/Pipes/Currency";

function PortfolioDetails(props: PortfolioDetailsProps): React.ReactElement {
    return (
        <div className="d-flex flex-column justify-content-between h-100">
            <h1 className="pi-font-size-20 mb-3">Portfolio Overview</h1>
            <div>
                <div className="d-flex justify-content-between mb-2">
                    <h2 className="pi-font-size-18">Equity</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.equity, 2)}
                    </h2>
                </div>

                <div className="d-flex justify-content-between mb-2 border-bottom">
                    <h2 className="pi-font-size-18">Cash</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.cash, 2)}
                    </h2>
                </div>

                <div className="d-flex justify-content-between">
                    <h2 className="pi-font-size-18">Total</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.equity + props.cash, 2)}
                    </h2>
                </div>
            </div>

            <div>
                <div className="d-flex justify-content-between mb-2">
                    <h2 className="pi-font-size-18">Equity Growth</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.builtEquity, 2)}
                    </h2>
                </div>

                <div className="d-flex justify-content-between mb-2 border-bottom">
                    <h2 className="pi-font-size-18">Dividends</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.dividends, 2)}
                    </h2>
                </div>

                <div className="d-flex justify-content-between">
                    <h2 className="pi-font-size-18">Total</h2>
                    <h2 className="pi-font-size-18 pi-color-primary-gray">
                        {currency(props.builtEquity + props.dividends, 2)}
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default PortfolioDetails;
