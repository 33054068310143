import * as React from "react";
import {ReactElement, useState} from "react";

import HomeNav from "../../Shared/HomeNav/HomeNav";
import ResetPwdForm2 from "../../Shared/Forms/ResetPwdForm2";

import {Redirect, useParams} from "react-router-dom";
import { resetPwdSubmission } from "../../Services/Auth/Auth";

function ResetPassword(): ReactElement {
    const [toLogin, setToLogin] = useState<boolean>(false)
    const { key } = useParams();

    function handlePwdReset(values: {password: string; passwordConfirmation: string}): void {
        if (key) {
            resetPwdSubmission(key, values.password).then(() => {
                setToLogin(true);
            })
        }
    }

    if (toLogin) {
        return <Redirect to={"/auth/login"} />
    }

    return (
        <div className="container">
            <HomeNav />
            <div className="row justify-content-center mt-5 mx-0">
                <div className="col-12 col-md-5 col-lg-4 col-xl-3 mt-5">
                    <h1 className="display-4 pi-font-size-32 text-center mb-3">Reset Your Password</h1>

                    <ResetPwdForm2 handlePwdReset={handlePwdReset} />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;