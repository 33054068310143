import React from 'react';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { number, object, string } from "yup";
import {MicrodepositFormProps} from "../../Types/Features";
import { Formik } from "formik";

const MicrodepositSchema = object().shape({
    routingNumber: string()
        .min(9, 'Routing number must be exactly 9 characters.')
        .max(9, 'Routing number must be exactly 9 characters.')
        .required('Please enter a valid routing number.'),
    accountNumber: string()
        .min(0, 'Please enter a valid account number.')
        .required('Please enter a valid account number.'),
    bankName: string()
        .required('Please enter a bank.'),
    accountType: string()
    	.required('Please enter an account type.')
});

const MicrodepositForm = (props: MicrodepositFormProps): React.ReactElement => {
	return(
		<Formik
			initialValues={{
              routingNumber: "",
              accountNumber: "",
              bankName: "",
              accountType: "",
          	}}
          	validationSchema={MicrodepositSchema}
          	onSubmit={(values): void => {
              	props.handleSubmit(values);
          	}}
          	render={({
          		values,
          		errors,
          		touched,
              dirty,
              isValid,
          		handleSubmit,
          		handleChange,
          		setFieldValue,
              isSubmitting
          	}): React.ReactElement => ( 
          		<Form onSubmit={handleSubmit}>
          			<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Bank Name</p>
	                    <Form.Group className="w-50" controlId="bankName">
	                    	{errors.bankName && touched.bankName ? (
	                    		<span className="text-danger ml-2">{errors.bankName}</span>) : null}
	                    	<Form.Control
                              type="string"
                              name="bankName"
                              placeholder=""
                              className="text-right pi-border-color-primary bg-transparent text-white"
                              onChange={handleChange}
                              value={values.bankName}
                          />
	                    </Form.Group>
	          		</div>
	          		<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Routing Number</p>
	                    <Form.Group className="w-50" controlId="routingNumber">
	                    	{errors.routingNumber && touched.routingNumber ? (
                                <span className="text-danger ml-2">{errors.routingNumber}</span>) : null}
	                    	<Form.Control
                              type="string"
                              name="routingNumber"
                              placeholder=""
                              className="text-right pi-border-color-primary bg-transparent text-white"
                              onChange={handleChange}
                              value={values.routingNumber}
                             />
	                    </Form.Group>
	          		</div>
	          		<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Account Number</p>
	                    <Form.Group className="w-50" controlId="accountNumber">
	                    	{errors.accountNumber && touched.accountNumber ? (
                                <span className="text-danger ml-2">{errors.accountNumber}</span>) : null}
	                    	<Form.Control
                              type="string"
                              name="accountNumber"
                              placeholder=""
                              className="text-right pi-border-color-primary bg-transparent text-white"
                              onChange={handleChange}
                              value={values.accountNumber}
                            />
	                    </Form.Group>
	          		</div>
	          		<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Account Type</p>
	          			<Form.Group className="w-50" controlId="accountType">
	          				{errors.accountType && touched.accountType ? (
                                <span className="text-danger ml-2">{errors.accountType}</span>) : null}
	          				<Form.Control
			                        as="select"
			                        name="accountType"
			                        className="pi-font-size-14 pi-border-color-primary bg-transparent text-white"
			                        onChange={(e: any): void => {
			                           setFieldValue('accountType', e.target.value)
			                        }}
			                        value={values.accountType}>
			                    <option value={''}>Select an Account Type</option>
			                    <option value={'checking'}>Checking</option>
			                    <option value={'savings'}>Savings</option>
			                 </Form.Control>
	          			</Form.Group>
	          		</div>
	          		<Button
		                className="pi-bg-color-primary border-0 w-100 py-3"
		                type="submit"
                    disabled={!(isValid && dirty) || isSubmitting}
		            >
		            	Link Bank
		            </Button>
          		</Form>
          	)}
		/>
	);
}

export default MicrodepositForm;