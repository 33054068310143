import * as React from 'react';
import { ReactElement } from "react";

import './Styles/Home.css';
import HomeNav from "../../Shared/HomeNav/HomeNav";
import EmailCaptureForm from "../../Shared/Forms/EmailCaptureForm";
import HomeFooter from "../../Shared/HomeFooter/HomeFooter";
import { captureEmail } from "../../Services/Home/HomeServices";
import { errorMap } from "../../Services/Errors/ErrorMap";
import { HomeState } from "../../Types/Features";
import AppImg from "../../Assets/91dae714-2820-4373-91dd-c0bd12a692b6.png";
import DestinationImg from "../../Assets/undraw_destinations_fpv7.svg"
import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';


class Home extends React.Component<{}, HomeState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            emailCaptureResponse: '',
            formId: undefined,
            toSignup: false
        };

        this.handleEmailCapture = this.handleEmailCapture.bind(this);
        this.handleToSignup = this.handleToSignup.bind(this);
    }

    handleEmailCapture(values: {email: string}, formId: number): void {
        this.setState({
           emailCaptureResponse: ''
        });
        captureEmail(values.email).then(() => {
            this.setState({
                emailCaptureResponse: 'Thank you for joining! You will receive an email from us shortly.',
                formId
            });
        }).catch((err) => {
            if (err.message === 'Request failed with status code 400') {
                this.setState({
                    emailCaptureResponse: 'Email already submitted. You should been hearing from us shortly!',
                    formId
                });
            } else {
                this.setState({
                    emailCaptureResponse: errorMap.default,
                    formId
                });
            }
        });
    }

    handleToSignup(): void {
        this.setState({
            toSignup: true
        });
    }

    render(): ReactElement {
        if (this.state.toSignup) {
            return <Redirect to='/auth/signup' />;
        }

        return (
            <div>
                <div className="container mb-5">
                    <HomeNav
                        root={true}
                    />
                </div>

                <div className="container mb-5">
                    <div className="row justify-content-center mb-4 mb-lg-5 mt-lg-5 px-3">
                        <div className="col-12 col-lg-5 mt-0 mt-lg-5 text-center text-md-left">
                            <h1 className="pi-font-size-56 primary-color header-weight text-left font-weight-bold mb-3">
                                Real estate investing built for the people
                            </h1>
                            <div className="d-block d-lg-flex align-items-md-center text-left">
                                <h2 className="pi-font-size-20 secondary-color mr-3 pi-color-primary-gray mb-3 mb-md-0">
                                    PeerInvest makes owning a slice of real estate is as easy as buying a share of stock.
                                </h2>
                                <div>
                                    <Button
                                        className="pi-primary-btn pi-font-size-20 py-2 mb-4 mb-lg-0 pi-secondary-btn"
                                        onClick={this.handleToSignup}
                                    ><span className="text-nowrap">Start Investing</span></Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mb-0 mb-lg-5 px-3">
                        <div className="col-12 col-lg-7 mb-0 mb-lg-5">
                            <div className="browser shadow-lg">
                                <div className="mask">
                                    <img
                                        src={AppImg}
                                        alt="screenshot of App in Browser"
                                        className="mask-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pi-bg-color-light-gray">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="row py-5 justify-content-around text-center text-lg-left">
                                    <div className="col-12 col-lg-3 mb-4 mg-lg-4">
                                        <i className="icon secondary-bg mx-auto mx-lg-0">
                                            <svg className="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                                <path
                                                    d="M25 7.5v12a1.502 1.502 0 0 1-1.5 1.5h-18A1.502 1.502 0 0 1 4 19.5V19h18a1 1 0 0 0 1-1V6h.5A1.502 1.502 0 0 1 25 7.5zM20.5 18h-18A1.5 1.5 0 0 1 1 16.5v-12A1.5 1.5 0 0 1 2.5 3h18A1.5 1.5 0 0 1 22 4.5v12a1.5 1.5 0 0 1-1.5 1.5zM10 10V8h3.5a.5.5 0 0 0 0-1H12v-.5a.5.5 0 0 0-1 0V7h-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h3v2H9.5a.5.5 0 0 0 0 1H11v.5a.5.5 0 0 0 1 0V14h1a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z"></path>
                                            </svg>
                                        </i>
                                        <h3 className="pi-font-size-32 primary-color">Low Minimums</h3>
                                        <p className="pi-font-size-18 secondary-color mt-3">
                                            Own a slice of a property for as little as $500.</p>
                                    </div>

                                    <div className="col-12 col-lg-3 mb-4 mg-lg-4">
                                        <i className="icon secondary-bg mx-auto mx-lg-0">
                                            <svg className="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                                <path
                                                    d="M20 22.494v1a.5.5 0 0 1-1 0v-1a.5.5 0 1 1 1 0zm-14.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 1 0v-1a.5.5 0 0 0-.5-.5zm7 1a10.924 10.924 0 0 1-2.494-.391l-.27 1.76a.528.528 0 0 0 .097.454.498.498 0 0 0 .387.183h4.56a.5.5 0 0 0 .49-.598l-.275-1.8a10.928 10.928 0 0 1-2.495.392zm9.06-7.44L18 11.995v9h2.5a1.5 1.5 0 0 0 1.5-1.5v-2.879a1.5 1.5 0 0 0-.44-1.06zM17 5.995v15a18.472 18.472 0 0 1-4 .974v-7.474a.5.5 0 0 0-1 0v7.474a18.47 18.47 0 0 1-4-.974v-15C8 3.67 11.553.244 12.5 0c.947.244 4.5 3.67 4.5 5.994zm-2.168 1.127A4.374 4.374 0 0 0 12.5 5.994a4.113 4.113 0 0 0-2.343 1.136.5.5 0 0 0 .686.729 3.213 3.213 0 0 1 1.657-.865 3.417 3.417 0 0 1 1.668.874.5.5 0 0 0 .664-.748zM3 16.614v2.88a1.5 1.5 0 0 0 1.5 1.5H7v-9l-3.56 3.56a1.5 1.5 0 0 0-.44 1.06z"></path>
                                            </svg>
                                        </i>
                                        <h3 className="pi-font-size-32 primary-color">No Hassle</h3>
                                        <p className="pi-font-size-18 secondary-color mt-3">
                                            No agents, no lenders, no closing; real estate investing made simple.</p>
                                    </div>

                                    <div className="col-12 col-lg-3">
                                        <i className="icon secondary-bg mx-auto mx-lg-0">
                                            <svg className="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                                <path
                                                    d="M8.543 16.858l7.635-4.192.202 3.028-8.068 4.632zM8 10V9a1 1 0 0 1 1-1V4.5a3.49 3.49 0 0 1 3-3.45V.5a.5.5 0 0 1 1 0v.55a3.49 3.49 0 0 1 3 3.45V8a1 1 0 0 1 1 1v1a.993.993 0 0 1-.934.987l.038.578-7.482 4.109.312-4.687A.993.993 0 0 1 8 10zm5-2h2V6h-2zm-3 0h2V6h-2zm-1 2h7V9H9zm8.5-5a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 17.5 5zm-.158 2.975l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zm-16-5l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zM1.5 10a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 1.5 10zM25 24.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1 0-1h7.567l.165-2.475 8.222-4.72.48 7.195H24.5a.5.5 0 0 1 .5.5zm-11-2a1.5 1.5 0 0 0-3 0V24h3z"></path>
                                            </svg>
                                        </i>
                                        <h3 className="pi-font-size-32 pi-primary-color">Transparent Fees</h3>
                                        <p className="pi-font-size-18 mt-3 secondary-color">
                                            We believe in simple and transparent fees.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pi-bg-color-secondary">
                    <div className="container py-5">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="row justify-content-center">
                                    <div className="col-11 text-center text-lg-left mb-4 mb-lg-0">
                                        <h2 className="pi-font-size-28 text-white">
                                            Ready to start building passive income?
                                        </h2>
                                    </div>
                                </div>

                                <div className="row justify-content-around">
                                    <div className="col-11 text-center text-lg-left">
                                        <Button
                                            className="pi-primary-btn px-4 pi-font-size-20"
                                            onClick={this.handleToSignup}
                                        >Get Started</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="row justify-content-around align-items-center py-5">
                                <div className="col-12 col-lg-5 text-center text-lg-left">
                                    <h2 className="pi-font-size-24 pi-color-primary font-weight-bold mb-3">
                                        Our Mission
                                    </h2>
                                    <h3 className="pi-font-size-28 mb-4">
                                        Opening access to private real estate assets for passive income
                                    </h3>
                                    <p className="pi-font-size-18 pi-color-primary-gray text-justify text-lg-left">
                                        Real estate is proven to be a powerful asset class for building wealth, but it
                                        is hard, time consuming, restricted to only the wealthy. Our mission is to change that. By
                                        opening access to private real estate in a truly passive form, we hope to bring financial
                                        freedom to as many people as we can. We hope you join us on our journey to unlock real
                                        estate investing for the masses.
                                    </p>
                                </div>

                                <div></div>

                                <div className="col-12 col-lg-4 text-center text-lg-left">
                                    <img alt={'our vision'} className="w-100 mb-2 mx-auto" src={DestinationImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pi-bg-color-light-gray">
                    <div className="container py-5">
                        <div className="row justify-content-center mb-4 align-items-stretch">
                            <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                                <div className="p-5 bg-white rounded h-100 shadow">
                                    <h2 className="pi-font-size-24 mb-3">
                                        How does it work?
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14">
                                        Owning a slice of real estate is as easy as buying a share of stock. Here&apos;s
                                        how it works!
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                                <div className="p-5 bg-white rounded h-100 shadow">
                                    <h2 className="pi-font-size-24 mb-3">
                                        Pick a property
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14">
                                        Browse our marketplace for properties that fit your investment goals. We
                                        strive to provide accurate and transparent data needed to make an informed decision.
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 col-lg-3">
                                <div className="p-5 bg-white rounded h-100 shadow">
                                    <h2 className="pi-font-size-24 mb-3">
                                        Purchase shares
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14">
                                        Click buy shares. Enter your desired quantity. Submit your order. No agents, no lenders,
                                        no closing, real estate investing made easy.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center align-items-stretch">
                            <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                                <div className="p-5 bg-white rounded h-100 shadow">
                                    <h2 className="pi-font-size-24 mb-3">
                                        Earn passive income & build equity
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14">
                                        You will be paid a dividend quarterly and your share of the equity will grow as
                                        mortgage payments are made and the price of the property appreciates.
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                                <div className="p-5 bg-white rounded h-100 shadow">
                                    <h2 className="pi-font-size-24 mb-3">
                                        Reinvest
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14">
                                        Compounding is the 8th wonder of the world. We are always adding new investment
                                        opportunities to the platform, reinvest distributions to compound wealth.
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                                <div className="p-5 pi-bg-color-secondary rounded h-100 shadow">
                                    <div className="h-100 d-flex flex-column justify-content-between">
                                        <h2 className="pi-font-size-24 mb-4 text-white">
                                            Ready to begin your journey?
                                        </h2>
                                        <div>
                                            <Button
                                                className="pi-primary-btn px-4 pi-font-size-18"
                                                onClick={this.handleToSignup}
                                            >Get Started</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pi-email-background">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-9 col-xl-6 py-5">
                                <EmailCaptureForm
                                    classes="mx-auto text-center mx-0 mx-md-5"
                                    captureEmail={this.handleEmailCapture}
                                    submissionError={this.state.emailCaptureResponse}
                                    buttonClasses="pi-bg-color-secondary"
                                    formId={0}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pi-bg-color-light-gray">
                    <div className="container">
                        <HomeFooter />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
