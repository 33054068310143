import * as React from 'react';

import KnowledgeCenter from "../Features/Home/KnowledgeCenter/KnowledgeCenter";
import KnowledgeCenterArticle from "../Features/Home/KnowledgeCenter/KnowledgeCenterArticle";

import { useRouteMatch, Switch, Route } from 'react-router-dom';

function KnowledgeCenterRoutes(): React.ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <KnowledgeCenter />
            </Route>

            <Route path={`${path}/:id`}>
                <KnowledgeCenterArticle />
            </Route>
        </Switch>
    );
}

export default KnowledgeCenterRoutes;
