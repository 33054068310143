import * as React from 'react';

import { FinancialProjectionsTabsProps } from "../../../../Types/Features";

import { Tabs, Tab } from 'react-bootstrap';
import FinancialProjectionsGraph from "./FinancialProjectionsGraph";
import RefinanceAnalysis from "./RefinanceAnalysis";
import { InvestmentType } from "../../../../Types/Data";

function FinancialProjectionsTabs(props: FinancialProjectionsTabsProps): React.ReactElement {
    const [totalData, setTotalData] = React.useState<any>();
    const [equityData, setEquityData] = React.useState<any>();
    const [cashFlowData, setCashFlowData] = React.useState<any>();

    React.useEffect(() => {
        const divisor = props.perShare ? props.totalShares : 1;
        const rawTotalData = [];
        const rawEquityData = [];
        const rawCashFlowData = [];
        for (let i = 0; i < 30; i++) {
            rawTotalData.push({
                'year': (i + 1).toString(),
                'Cash Flow': props.calculations.cash_flow_series[i] / divisor,
                'Equity': props.calculations.equity_series[i] / divisor,
                'Appreciation': props.calculations.appreciation_series[i] / divisor,
            });
            rawEquityData.push({
                'year': (i + 1).toString(),
                'Equity': (props.calculations.equity_series[i] + props.calculations.appreciation_series[i]) / divisor,
            });
            rawCashFlowData.push({
                'year': (i + 1).toString(),
                'Cash Flow': props.calculations.cash_flow_series[i] / divisor,
            });
        }
        setTotalData(rawTotalData);
        setEquityData(rawEquityData);
        setCashFlowData(rawCashFlowData);
    }, [props])

    return (
        <div className="mt-4">
            <Tabs defaultActiveKey="total" id="uncontrolled-tab-example">
                <Tab eventKey="total" title="Overview">
                    <h2 className="pi-font-size-20 mt-3">Investment Returns</h2>
                    <FinancialProjectionsGraph
                        perShare={props.perShare}
                        data={totalData}
                        keys={[ 'Cash Flow', 'Equity', 'Appreciation' ]}
                    />
                </Tab>
                <Tab eventKey="equity" title="Equity">
                    <h2 className="pi-font-size-20 mt-3">Equity Returns</h2>
                    <div className="row">
                        {props.property.investment_type === InvestmentType.FlipToRent &&
                            <div className="col-12 col-lg-4">
                                <RefinanceAnalysis
                                    property={props.property}
                                />
                            </div>
                        }

                        <div className={props.property.investment_type === InvestmentType.FlipToRent ? 'col-12 col-lg-8' : 'col-12'}>
                            <FinancialProjectionsGraph
                                perShare={props.perShare}
                                data={equityData}
                                keys={[ 'Equity' ]}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="cash flow" title="Cash Flow">
                    <h2 className="pi-font-size-20 mt-3">Cash Flow Returns</h2>
                    <FinancialProjectionsGraph
                        perShare={props.perShare}
                        data={cashFlowData}
                        keys={[ 'Cash Flow' ]}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default FinancialProjectionsTabs;
