import * as React from 'react';

import { ContactInformationFormProps } from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import { string, object } from 'yup';

const MailingAddressSchema = object().shape({
    email: string()
        .email('Please enter a valid email address')
        .required('*Required')
});

const ContactInformationForm = (props: ContactInformationFormProps): React.ReactElement => {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.settings}
            onSubmit={(values, actions): void => {
                props.handleSave(values);
                actions.setSubmitting(false);
            }}
            validationSchema={MailingAddressSchema}
            render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleSubmit,
                 isSubmitting,
             }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="mailingAddressForm1">
                        <Form.Label>
                            Email Address
                            {errors.email === '*Required' && touched.email ? (
                                <span className="text-danger ml-2">{errors.email}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="email"
                            type="text"
                            placeholder="Street address"
                            onChange={handleChange}
                            value={values.email}
                        />
                        {errors.email !== '*Required' && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                        ) : null}
                    </Form.Group>


                    <Button className="w-100 pi-primary-btn" disabled={isSubmitting} type="submit">
                        Save Contact Information
                    </Button>
                </Form>
            )}
        />
    )
};

export default ContactInformationForm;