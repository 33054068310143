import * as React from 'react';
import { ReactElement } from 'react';
import PasswordResetForm from '../../../Shared/Forms/PasswordResetForm';
import { changePassword } from '../../../Services/Settings/SettingsServices';

class Security extends React.Component<{}, {}> {
    handleSubmit(values: any): void {
        changePassword(values)
            .then(() => {
                // To be implemented
            })
            .catch(() => {
                // To be implemented
            });
    }

    render(): ReactElement {
        return (
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="border rounded shadow p-3">
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mb-3 border-bottom">
                                Change Password
                            </h1>
                            <PasswordResetForm
                                handleSubmit={this.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Security;
