import { MenuItem } from '../../Types/Shared';

import {
    faChartPie,
    faCoins,
    faExchangeAlt,
    faNewspaper,
    faFile,
    faBullhorn,
    faCog,
} from '@fortawesome/free-solid-svg-icons';

import { faBuilding } from '@fortawesome/free-regular-svg-icons';

export const menuItems: MenuItem[][] = [
    [
        {
            id: 0,
            text: 'Dashboard',
            icon: faChartPie,
            route: '/account-overview',
        },
    ],
    [
        {
            id: 1,
            text: 'Properties',
            icon: faBuilding,
            route: '/marketplace',
        },
    ],
    [
        {
            id: 2,
            text: 'Portfolio',
            icon: faCoins,
            route: '/portfolio',
        },
        {
            id: 3,
            text: 'Transactions',
            icon: faExchangeAlt,
            route: '/transactions',
        },
        {
            id: 4,
            text: 'News & Updates',
            icon: faNewspaper,
            route: '/news-and-updates',
        },
        {
            id: 5,
            text: 'Documents',
            icon: faFile,
            route: '/documents',
        },
        {
            id: 6,
            text: 'Get Free Shares',
            icon: faBullhorn,
            route: '/refer',
        },
        {
            id: 7,
            text: 'Settings',
            icon: faCog,
            route: '/account',
        },
    ],
];
