import * as React from 'react';

import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import { getKnowledgeArticle } from "../../../Services/Home/HomeServices";
import { KnowledgeArticle } from "../../../Types/Data";

import { useParams, useHistory } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function KnowledgeCenterArticle(): React.ReactElement {
    const [article, setArticle] = React.useState<KnowledgeArticle>()
    const { id } = useParams();

    const history = useHistory();

    function handleBack(): void {
        history.push('/support/knowledge-center');
    }

    React.useEffect(() => {
        if (id && !article) {
            getKnowledgeArticle(id).then(res => {
                setArticle(res.data);
            });
        }
    });

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="container">
                <div className="row justify-content-center pt-4 pt-lg-5">
                    <div className="col-12 col-lg-6">
                        <Button
                            onClick={handleBack}
                            className="px-0 pi-font-size-20 d-flex align-items-center"
                            variant="link"
                        >
                            <FontAwesomeIcon className="pi-font-size-16 mr-2" icon={faChevronLeft} />
                            <span>Back</span>
                        </Button>
                    </div>
                </div>
                <div className="row justify-content-center pb-5">
                    <div className="col-12 col-lg-6">
                        {article &&
                            <div>
                                <img className="w-100 rounded" src={article.image} alt={'knowledge article hero'}/>
                                <h1 className="mt-3">{article.title}</h1>
                                <div className="font-weight-light pi-font-size-16 mt-1 mb-3">
                                    {article.tags.map(tag =>
                                        <Badge
                                            key={tag.id}
                                            pill
                                            variant="primary"
                                            className="pi-bg-color-primary text-white mr-2 pi-cursor"
                                        >{tag.text}</Badge>
                                    )}
                                </div>
                                <div className="text-justify" dangerouslySetInnerHTML={{__html: article.content}}></div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    )
}

export default KnowledgeCenterArticle;
