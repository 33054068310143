import * as React from "react";
import { ReactElement } from "react";

import PersonalInformation from "../Features/Account/PersonalInformation/PersonalInformation";
import Security from "../Features/Account/Security/Security";
import Preferences from "../Features/Account/Preferences/Preferences";
import Banking from "../Features/Account/Plaid/Banking";
import { AccountRoutesProps } from "../Types/Routes";

import {Redirect, Switch, useRouteMatch} from "react-router";
import { Route } from "react-router-dom";

function AccountRoutes(props: AccountRoutesProps): ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/information`}>
                <PersonalInformation settings={props.settings} email={props.email} />
            </Route>
            <Route path={`${path}/banking`}>
                <Banking />
            </Route>
            <Route path={`${path}/security`}>
                <Security />
            </Route>
            <Route path={`${path}/preferences`}>
                <Preferences />
            </Route>

            <Redirect from="/account" to="/account/information" />
        </Switch>
    );
}

export default AccountRoutes;