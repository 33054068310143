import * as React from 'react';

import { TransferBalanceFormProps } from '../../Types/Shared';
import { currency } from '../Pipes/Currency';
import { number, object, string } from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { usePlaidLink } from 'react-plaid-link';
import {
    getDwollaFundingSource,
    plaidLinkToken,
    plaidPublicToken,
} from '../../Services/Banking/BankingServices';

import './Styles/Forms.css';

const TransferTicketSchema = object().shape({
    amount: number()
        .min(0, 'Please enter a valid dollar amount.')
        .required('Please enter a valid dollar amount.')
        .positive('Please enter a valid dollar amount.'),
    account: string().required('Please select an account.'),
});

const TransferBalanceForm = (
    props: TransferBalanceFormProps,
): React.ReactElement => {
    const [minTransferError, setMinTransferError] = React.useState<string>('');
    const [linkToken, setLinkToken] = React.useState<string>('');

    React.useEffect(() => {
        plaidLinkToken().then((res) => {
            setLinkToken(res.data);
        });
    }, []);

    const onSuccess = React.useCallback(
        (token) => {
            plaidPublicToken(token).then(() => {
                getDwollaFundingSource('True').then((res) => {
                    const accounts: string[] = [];
                    res.data.forEach((account) => {
                        accounts.push(account);
                    });
                    props.handleNewAccounts(accounts);
                });
            });
        },
        [props],
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open } = usePlaidLink(config);

    return (
        <Formik
            initialValues={{
                amount: '0.00',
                account: '',
                action: '',
            }}
            validationSchema={TransferTicketSchema}
            onSubmit={(values): void => {
                props.handleSubmit(values);
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
            }): React.ReactElement => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="actionSelect">
                        <Form.Control
                            as="select"
                            name="action"
                            className="pi-font-size-14 pi-border-color-primary bg-transparent text-white"
                            onChange={(e: any): void => {
                                setFieldValue('action', e.target.value);
                            }}
                            value={values.action}>
                            <option value={''}>Select an Action</option>
                            <option value={'deposit'}>
                                Transfer to PeerInvest
                            </option>
                            <option value={'withdraw'}>Transfer to Bank</option>
                        </Form.Control>
                    </Form.Group>

                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="text-white pi-font-size-14 mb-0">
                            Amount ($)
                        </p>
                        <div className="d-flex justify-content-end align-items-center">
                            <Form.Group
                                className="w-50 mb-0 mr-2"
                                controlId="amount">
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    placeholder=""
                                    className="text-right pi-border-color-primary bg-transparent text-white mb-0"
                                    onChange={handleChange}
                                    value={values.amount}
                                />
                            </Form.Group>
                            <a
                                href="#"
                                className="text-white"
                                onClick={() =>
                                    setFieldValue(
                                        'amount',
                                        props.cashBalance.toString(),
                                    )
                                }
                                style={{ textDecoration: 'underline' }}>
                                Max
                            </a>
                        </div>
                    </div>
                    <Form.Group controlId="accountSelect">
                        <Form.Control
                            as="select"
                            name="account"
                            className="pi-font-size-14 pi-border-color-primary bg-transparent text-white"
                            onChange={(e: any): void => {
                                if (e.target.value === 'add') {
                                    open();
                                }
                                setFieldValue('account', e.target.value);
                            }}
                            value={values.account}>
                            <option value={''}>Select an Account</option>
                            {props.accounts.map((account) => (
                                <option value={account} key={account}>
                                    {account}
                                </option>
                            ))}
                            <option value={'add'}>Add New Account</option>
                        </Form.Control>
                    </Form.Group>
                    <Button
                        className="pi-bg-color-primary border-0 w-100 py-3"
                        type="submit">
                        Submit Transfer
                    </Button>
                </Form>
            )}
        />
    );
};

export default TransferBalanceForm;
