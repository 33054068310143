import * as React from 'react';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {Formik} from "formik";
import {MarketplaceFilterFormProps} from "../../Types/Shared";
import {states} from "../../Assets/states";
import InputGroup from "react-bootstrap/InputGroup";

const TokenForm = (props: MarketplaceFilterFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                state: '',
                capRate: '',
                minimum: '',
                type: ''
            }}
            onSubmit={(values): void => {
                props.handleFilterSubmit(values);
            }}
            render={({
                values,
                handleSubmit,
                handleChange
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
                            <Form.Group className="mb-0" controlId="formState">
                                <Form.Control
                                    as="select"
                                    name="state"
                                    onChange={handleChange}
                                    value={values.state}>
                                    <option>Location</option>
                                    {states.map((state: string) =>
                                        <option key={state} value={state}>{state}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
                            <InputGroup className="mb-0">
                                <Form.Control
                                    type="number"
                                    name="capRate"
                                    placeholder="Cap Rate"
                                    onChange={handleChange}
                                    value={values.capRate}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text className="pi-bg-color-secondary-gray text-white">%</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>

                        <div className="col-12 col-md-6 col-xl-2 mb-3 mb-md-0">
                            <InputGroup className="mb-0">
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="pi-bg-color-secondary-gray text-white">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    name="minimum"
                                    placeholder="Minimum Investment"
                                    onChange={handleChange}
                                    value={values.minimum}
                                />
                            </InputGroup>
                        </div>

                        <div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
                            <Form.Group className="mb-0" controlId="formType">
                                <Form.Control
                                    as="select"
                                    name="type"
                                    onChange={handleChange}
                                    value={values.type}
                                >
                                    <option>Type</option>
                                    <option value="SF">Single-family</option>
                                    <option value="MF">Multi-family</option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <Button className="pi-primary-btn flex-grow-1 flex-md-grow-0 mx-3 mx-md-0" type="submit">
                            Apply
                        </Button>
                    </div>
                </Form>
            )}
        />
    )
};

export default TokenForm;