import * as React from "react";
import * as ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import * as serviceWorker from './serviceWorker';
import App from "./App";
import ReactGA from 'react-ga'

ReactGA.initialize('UA-179103178-1')

if (process.env.REACT_APP_PLAID_ENV === 'production') {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
