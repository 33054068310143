import * as React from "react";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";

class PrivacyPolicy extends React.Component<{}, {}> {
    render(): React.ReactElement {
        return (
            <div>
                <div className="container">
                    <MasterHeader/>
                </div>

                <div className="pi-bg-color-secondary">
                    <div className="container">
                        <div className="row justify-content-center py-5">
                            <div className="col-12">
                                <h1 className="text-white text-center">Privacy Policy</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="p-3 px-md-5">
                        <div className="mx-0 mx-md-5 p-0 p-md-5 bg-white rounded">
                            <p>At PeerInvest, accessible from https://peerinvest.io, one of our main priorities is the
                                privacy of our visitors. This Privacy Policy document contains types of information that is
                                collected and recorded by Peer Invest and how we use it.</p>
                            <p>If you have additional questions or require more information about our Privacy Policy, do not
                                hesitate to contact us.</p>
                            <h2 className="pi-font-size-24">Log Files</h2>
                            <p>PeerInvest follows a standard procedure of using log files. These files log visitors when
                                they visit websites. All hosting companies do this and a part of hosting
                                services&apos; analytics. The information collected by log files include internet protocol
                                (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                                referring/exit pages, and possibly the number of clicks. These are not linked to any
                                information that is personally identifiable. The purpose of the information is for analyzing
                                trends, administering the site, tracking users&apos; movement on the website, and gathering
                                demographic information.</p>
                            <h2 className="pi-font-size-24">Cookies and Web Beacons</h2>
                            <p>Like any other website, PeerInvest uses &apos;cookies&apos;. These cookies are used to store
                                information including visitors&apos; preferences, and the pages on the website that the
                                visitor accessed or visited. The information is used to optimize the users&apos; experience
                                by customizing our web page content based on visitors&apos; browser type and/or other
                                information.</p>
                            <h2 className="pi-font-size-24">Privacy Policies</h2>
                            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
                                Beacons that are used in their respective advertisements and links that appear on
                                PeerInvest, which are sent directly to users&apos; browser. They automatically receive your
                                IP address when this occurs. These technologies are used to measure the effectiveness of
                                their advertising campaigns and/or to personalize the advertising content that you see on
                                websites that you visit.</p>
                            <p>Note that PeerInvest has no access to or control over these cookies that are used by
                                third-party advertisers.</p>
                            <h2 className="pi-font-size-24">Third Party Privacy Policies</h2>
                            <p>PeerInvest&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we
                                are advising you to consult the respective Privacy Policies of these third-party ad servers
                                for more detailed information. It may include their practices and instructions about how to
                                opt-out of certain options. You may find a complete list of these Privacy Policies and their
                                links here: Privacy Policy Links.</p>
                            <p>You can choose to disable cookies through your individual browser options. To know more
                                detailed information about cookie management with specific web browsers, it can be found at
                                the browsers&apos; respective websites. What Are Cookies?</p>
                            <h2 className="pi-font-size-24">Third Party Data Sharing</h2>
                            <p>On occasions we may be required to share Personal Identifiable Information with third party
                                vendors for purposes including, but not limited to, identity verification and payment processing.
                                In order to transact on PeerInvest, we must first verify a user&apos;s identity to comply with
                                federal regulations.</p>
                            <h2 className="pi-font-size-24">Children&apos;s Information</h2>
                            <p>Another part of our priority is adding protection for children while using the internet. We
                                encourage parents and guardians to observe, participate in, and/or monitor and guide their
                                online activity.</p>
                            <p>PeerInvest does not knowingly collect any Personal Identifiable Information from children
                                under the age of 18. If you think that your child provided this kind of information on our
                                website, we strongly encourage you to contact us immediately and we will do our best efforts
                                to promptly remove such information from our records.</p>
                            <h2 className="pi-font-size-24">Online Privacy Policy Only</h2>
                            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our
                                website with regards to the information that they shared and/or collect in PeerInvest. This
                                policy is not applicable to any information collected offline or via channels other than
                                this website.</p>
                            <h2 className="pi-font-size-24">Consent</h2>
                            <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and
                                Conditions.</p>
                        </div>
                    </div>
                </div>

                <HomeFooter/>
            </div>
        );
    }
}

export default PrivacyPolicy;
