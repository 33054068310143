import { ActionTypes } from './Actions';
import { Action, StoreState } from '../Types/Store';
import { UserInfoImpl } from '../Types/Data';

const initialState: StoreState = {
    userInfo: new UserInfoImpl(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        0,
        true,
        false,
        false,
        [],
        [],
        [],
        [],
        [],
    ),
    updates: [],
    cookies: {},
    isAuthenticated: true,
    hasCheckedAuth: false,
    properties: [],
};

const reducer = (
    state: StoreState = initialState,
    action: Action,
): StoreState => {
    switch (action.type) {
        case ActionTypes.SET_USER_INFO:
            return {
                ...state,
                userInfo: {
                    ...action.newUserInfo,
                },
            };
        case ActionTypes.SET_UPDATES:
            return {
                ...state,
                updates: [...action.newUpdates],
            };
        case ActionTypes.SET_COOKIES:
            return {
                ...state,
                cookies: {
                    ...action.newCookies,
                },
            };
        case ActionTypes.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.newIsAuthenticated,
            };
        case ActionTypes.SET_HAS_CHECKED_AUTH:
            return {
                ...state,
                hasCheckedAuth: action.newHasCheckedAuth,
            };
        case ActionTypes.SET_PROPERTIES:
            return {
                ...state,
                properties: action.newProperties,
            };
        default:
            return state;
    }
};

export default reducer;
