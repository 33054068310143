import * as React from 'react';

import PropertyOverview from "./PropertyOverview";
import FinancialOverview from "./FinancialOverview";
import { PropertyTabsProps } from "../../../../Types/Features";
import FinancialDetailsOverview from "./FinancialDetailsOverview";
import FinancialProjectionsTabs from "./FinancialProjectionsTabs";
import ComingSoonPlaceholder from "./ComingSoonPlaceholder";
import PropertyDocuments from "./PropertyDocuments";
import PropertyTimeline from "./PropertyTimeline";
import BuyProcess from "./BuyProcess";
import AssetStatistics from "./AssetStatistics";
import PropertyDiscussion from "./PropertyDiscussion";
import { Update } from "../../../../Types/Data";

import { Tabs, Tab } from 'react-bootstrap';
import PropertyUpdate from "./PropertyUpdate";

function PropertyTabs(props: PropertyTabsProps): React.ReactElement {
    const [selectedUpdate, setSelectedUpdate] = React.useState<Update>();

    return (
        <div>
            <Tabs defaultActiveKey="summary" id="property-tabs">
                <Tab eventKey="summary" title="Summary">
                    <div className="row mt-3">
                        <div className="col-12 col-lg-8 mb-3 mb-lg-0">
                            <PropertyOverview property={props.property} symbol={props.asset.symbol} />
                        </div>

                        <div className="col-12 col-lg-4">
                            <FinancialOverview calculations={props.marketRent ? props.property.market_rent_calculations : props.property.calculations} />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <BuyProcess />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="financials" title="Financials">
                    <div className="row">
                        <div className="col-12">
                            <FinancialDetailsOverview
                                calculations={props.marketRent ? props.property.market_rent_calculations : props.property.calculations}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FinancialProjectionsTabs
                                property={props.property}
                                calculations={props.marketRent ? props.property.market_rent_calculations : props.property.calculations}
                                perShare={props.perShare}
                                totalShares={props.asset.total_shares}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="analysis" title="Analysis">
                    <div className="row mt-3">
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <AssetStatistics asset={props.asset}/>
                        </div>

                        <div className="col-12 col-lg-8">
                            <ComingSoonPlaceholder />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="discussion" title="Discussion">
                    <div className="row mt-3">
                        <div className="col-12">
                            <PropertyDiscussion posts={props.property.posts} propertyId={props.property.id} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="updates" title="Updates">
                    <div className="row mt-3">
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <PropertyTimeline
                                updates={props.property.updates}
                                handleSelect={(update: Update): void => setSelectedUpdate(update)}
                            />
                        </div>

                        <div className="col-12 col-lg-8">
                            <PropertyUpdate update={selectedUpdate} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="documents" title="Documents">
                    <div className="row mt-3">
                        <div className="col-12">
                            <PropertyDocuments documents={props.property.documents} />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}

export default PropertyTabs;
