import * as React from 'react';

import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import { getJobPosting } from "../../../Services/Home/HomeServices";
import { JobPosting as Posting } from "../../../Types/Data";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHandshake,
    faMapMarkerAlt,
    faBriefcase,
    faClock,
    faDollarSign
} from '@fortawesome/free-solid-svg-icons';

import { useParams } from "react-router-dom";
import { date } from "../../../Shared/Pipes/Date";

function JobPosting(): React.ReactElement {
    const { id } = useParams();
    const [posting, setPosting] = React.useState<Posting>();

    React.useEffect(() => {
        if (id) {
            getJobPosting(parseInt(id)).then(res => {
                setPosting(res.data);
            });
        }
    }, [id])

    return (
        <div>
            <div className="container">
                <MasterHeader />

                <div className="row py-5 pi-bg-color-secondary justify-content-center">
                    <div className="col-12 col-lg-8">
                        <h1 className="text-white pi-font-size-24">{posting?.title}</h1>
                        <p className="text-white mb-0 pi-font-size-20">{posting?.location === 'RM' ? 'Remote': ''}</p>
                    </div>
                </div>

                <div className="row pt-4 pb-5 justify-content-center">
                    <div className="col-12 col-lg-5 order-1 order-md-0">
                        <p className="pi-font-size-18">
                            PeerInvest is one of the leading financial tech real estate asset managers in the US and rapidly growing.
                            We&apos;re looking for a highly skilled and motivated Front-End Developer for a full-time, permanent position.
                        </p>
                        <p className="pi-font-size-18">
                            Expected work schedule is 50 hours/week, Monday to Friday. In case you will be working from outside
                            the US, please be aware this position requires an overlap with EST business hours.
                        </p>
                        <h2 className="pi-font-size-22">Responsibilities</h2>

                        <div className="pi-font-size-18" dangerouslySetInnerHTML={{__html: posting ? posting.responsibilities : ''}}></div>

                        <h2 className="pi-font-size-22">Qualifications</h2>

                        <div className="pi-font-size-18" dangerouslySetInnerHTML={{__html: posting ? posting.qualifications : ''}}></div>

                        <h2 className="pi-font-size-22">Ready to Apply?</h2>

                        <p className="pi-font-size-18">Send a copy of your latest resume at: support@peerinvest.io</p>

                    </div>

                    <div className="col-12 col-lg-3 order-0 order-md-1 mb-5 mb-md-0">
                        <h2 className="pi-font-size-24 mb-4">Job Snapshot</h2>

                        <div className="d-flex align-items-center mb-2">
                            <FontAwesomeIcon className="pi-font-size-24 mr-2" icon={faHandshake}/>
                            <p className="mb-0 pi-font-size-18 font-weight-bold">Employee Type:</p>
                        </div>
                        <p className="pi-font-size-18">{posting?.type === 'FT' ? 'Full Time' : 'Part Time'}</p>

                        <div className="d-flex align-items-center mb-2">
                            <FontAwesomeIcon className="pi-font-size-24 mr-2" icon={faMapMarkerAlt}/>
                            <p className="mb-0 pi-font-size-18 font-weight-bold">Location:</p>
                        </div>
                        <p className="pi-font-size-18">{posting?.location === 'RM' ? 'Remote' : ''}</p>

                        <div className="d-flex align-items-center mb-2">
                            <FontAwesomeIcon className="pi-font-size-24 mr-2" icon={faBriefcase}/>
                            <p className="mb-0 pi-font-size-18 font-weight-bold">Job Type:</p>
                        </div>
                        <p className="pi-font-size-18">{posting?.title}</p>

                        <div className="d-flex align-items-center mb-2">
                            <FontAwesomeIcon className="pi-font-size-24 mr-2" icon={faClock}/>
                            <p className="mb-0 pi-font-size-18 font-weight-bold">Date Posted:</p>
                        </div>
                        <p className="pi-font-size-18">{posting ? date(posting.created_at, 'mm/dd/yyyy') : ''}</p>

                        <div className="d-flex align-items-center mb-2">
                            <FontAwesomeIcon className="pi-font-size-24 mr-2" icon={faDollarSign}/>
                            <p className="mb-0 pi-font-size-18 font-weight-bold">Pay Rate:</p>
                        </div>
                        <p className="pi-font-size-18">Very competitive salary based on prior experience and qualifications</p>
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    );
}

export default JobPosting;