import * as React from 'react';

import './Styles/Marketplace.css';
import { getProperties } from "../../../Services/Properties/Properties";
import CardBody from "../../../Shared/BasicComponents/CardBody";
import ImageContentCard from "../../../Shared/BasicComponents/ImageContentCard";
import ErrorContainer from "../../../Shared/BasicComponents/ErrorContainer";
import { MarketplaceProps, MarketplaceState } from "../../../Types/Features";
import { Asset, Property } from "../../../Types/Data";
import { currency } from "../../../Shared/Pipes/Currency";
import MarketplaceFilterForm from "../../../Shared/Forms/MarketplaceFilterForm";
import { percent } from "../../../Shared/Pipes/Percent";
import { getAssets } from "../../../Services/Users/Assets";
import blankOfferingCardImage from "../../../Assets/new_offering_card_image.png"

import { sumBy, find, filter } from "lodash";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from "react";

class Marketplace extends React.Component<MarketplaceProps, MarketplaceState> {
    constructor(props: MarketplaceProps) {
        super(props);

        this.state = {
            properties: [],
            filteredAssets: [],
            assets: [],
            hasCalledGetProperties: false,
            error: false,
            loading: true
        }

        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    }

    componentDidMount(): void {
        this.callGetProperties();
    }

    componentDidUpdate(): void {
        if (!this.state.hasCalledGetProperties) {
            this.callGetProperties();
        }
    }

    callGetProperties(): void {
        if (this.props.isAuthenticated) {
            this.setState({
                hasCalledGetProperties: true
            });
            Promise.all([getProperties(), getAssets()]).then(res => {
                this.setState({
                    properties: res[0].data,
                    filteredAssets: this.sortByActiveOffering(res[1].data.filter(asset => asset.symbol !== 'cash')),
                    assets: res[1].data,
                    loading: false
                });
            }).catch(() => {
                this.setState({
                    error: true,
                    loading: false
                });
            });
        }
    }

    getImageUrl(property: Property): string {
        const img = find(property.images, ['classifier', 'hero']);
        if (img) {
            return img.location;
        } else {
            return '';
        }
    }

    handleFilterSubmit(values: any): void {
        let filteredProperties: Property[] = this.state.properties;
        let filteredOpenOfferings: Asset[] = [];
        if (values.state && values.state !== 'Location') {
            filteredProperties = filter(filteredProperties, {'state': values.state});
            filteredOpenOfferings = this.state.assets.filter(asset => {
                if (asset.subscription_information && asset.subscription_information[0]) {
                    return asset.subscription_information[0].state === values.state;
                }
                return false;
            });
        }

        if (values.capRate) {
            filteredProperties = filter(filteredProperties, property => { return (property.calculations.cap_rate*100) > values.capRate });
        }

        if (values.minimum) {
            filteredProperties = filter(filteredProperties, property => { return this.assetMinimumFilter(property, values.minimum) });
        }

        if (values.type && values.type !== 'Type') {
            filteredProperties = filter(filteredProperties, {'property_type': values.type});
        }

        const filteredAssets = this.state.assets.filter(assets => filteredProperties.some(property => property.llc === assets.llc))

        if (filteredOpenOfferings && filteredOpenOfferings.length) {
            filteredOpenOfferings.forEach(offering => {
                if (filteredAssets.some(asset => asset.id === offering.id)) {
                    return;
                }

                filteredAssets.push(offering);
            });
        }

        const sortedAssets = this.sortByActiveOffering(filteredAssets);

        if (!values || !Object.keys(values).length ||
            (filteredProperties.length === this.state.properties.length && !filteredOpenOfferings.length)) {
            this.setState({
                filteredAssets: this.state.assets
            });
        } else  {
            this.setState({
                filteredAssets: sortedAssets
            });
        }
    }

    sortByActiveOffering(assets: Asset[]): Asset[] {
        return assets.sort((a, b) => {
            if (a.active_offering && !b.active_offering) {
                return -1;
            } else if (!a.active_offering && b.active_offering) {
                return 1;
            }
            return 0;
        });
    }

    getAssetPrice(property: Property): string {
        const asset = find(this.state.assets, {'llc': property.llc});
        return asset ? currency(asset.market_price, 2) : '--';
    }

    assetMinimumFilter(property: Property, value: string): boolean {
        const asset = find(this.state.assets, {'llc': property.llc});
        return asset ? asset.minimum_investment <= parseFloat(value) : false;
    }

    displayFilteredResults(): ReactElement {
        if (this.state.filteredAssets.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="text-center">
                        <p className="pi-font-size-20 pi-color-primary-gray">No results found - please update the filter</p>
                        <FontAwesomeIcon className="pi-font-size-80 pi-color-primary" icon={faSearchLocation} />
                    </div>
                </div>
            );
        } else {
            const cards: ReactElement[] = [];
            this.state.filteredAssets.forEach(asset => {
                const property = this.state.properties.find(prop => prop.llc === asset.llc)

                if (property) {
                    cards.push(
                        <div className="col-12 col-md-6 col-xl-3 mb-3 h-100" key={property.llc}>
                            <ImageContentCard
                                classes="shadow-sm"
                                title={property.property_type === 'MF' ? "Multi Family Rental" : "Single Family Rental"}
                                image={this.getImageUrl(property)}
                                url={`/dashboard/property/${property.llc}`}
                                property={property}
                                activeOffering={asset.active_offering}
                            >
                                <CardBody>
                                    <div className="row pi-font-size-14">
                                        <div className="col-6 border-bottom border-right pb-1">
                                            <h4 className="mb-0 pi-font-size-14">Current Rent</h4>
                                            <p className="mb-0 pi-color-primary-gray">{currency(sumBy(property.units, 'rent'), 0)}</p>
                                        </div>
                                        <div className="col-6 border-bottom pb-1">
                                            <h4 className="mb-0 pi-font-size-14">Cap Rate</h4>
                                            <p className="mb-0 pi-color-primary-gray">{percent(property.calculations.cap_rate, 2)}</p>
                                        </div>
                                    </div>
                                    <div className="row pi-font-size-14 mb-2">
                                        <div className="col-6 border-right pt-1">
                                            <h4 className="mb-0 pi-font-size-14">Share Price</h4>
                                            <p className="mb-0 pi-color-primary-gray">
                                                {this.getAssetPrice(property)}
                                            </p>
                                        </div>
                                        <div className="col-6 pt-1">
                                            <h4 className="mb-0 pi-font-size-14">Class</h4>
                                            <p className="mb-0 pi-color-primary-gray">{property.property_class}</p>
                                        </div>
                                    </div>
                                    <p className="text-secondary pi-font-size-14 mb-0 pt-2 border-top">{property.address1}</p>
                                    <p className="text-secondary pi-font-size-14 mb-0">{property.address2}</p>
                                    <p className="text-secondary pi-font-size-14 mb-0">{`${property.city}, ${property.state} ${property.zip}`}</p>
                                </CardBody>
                            </ImageContentCard>
                        </div>
                    );
                } else if (!property && asset.symbol !== 'cash') {
                    cards.push(
                        <div className="col-12 col-md-6 col-xl-3 mb-3 h-100" key={asset.llc}>
                            <ImageContentCard
                                classes="shadow-sm"
                                title={'New Offering'}
                                image={blankOfferingCardImage}
                                url={`/dashboard/property/${asset.llc}`}
                                activeOffering={asset.active_offering}
                            >
                                <CardBody>
                                    <div className="row pi-font-size-14">
                                        <div className="col-6 border-bottom border-right pb-1">
                                            <p className="mb-0">Offering Price</p>
                                            <p className="mb-0">{currency(asset.market_price, 0)}</p>
                                        </div>
                                        <div className="col-6 border-bottom pb-1">
                                            <p className="mb-0">Minimum Investment</p>
                                            <p className="mb-0">{currency(asset.minimum_investment, 0)}</p>
                                        </div>
                                    </div>
                                    <div className="row pi-font-size-14 mb-2">
                                        <div className="col-6 border-right pt-1">
                                            <p className="mb-0">Total Shares</p>
                                            <p className="mb-0">{asset.total_shares}</p>
                                        </div>
                                        <div className="col-6 pt-1">
                                            <p className="mb-0">Unsold Shares</p>
                                            <p className="mb-0">{asset.unsold_shares}</p>
                                        </div>
                                    </div>
                                    <p className="text-secondary pi-font-size-14 mb-0 pt-2 border-top"></p>
                                    <p className="text-secondary pi-font-size-14 mb-0">TBD</p>
                                    <p className="text-secondary pi-font-size-14 mb-0">
                                        {asset.subscription_information &&
                                            `${asset.subscription_information[0].city}, ${asset.subscription_information[0].state}`
                                        }
                                    </p>
                                </CardBody>
                            </ImageContentCard>
                        </div>
                    )
                }
            });
            return (
                <div className="row justify-content-start">
                    {cards}
                </div>
            );
        }
    }

    render(): ReactElement {
        if (this.state.error) {
            return (
                <ErrorContainer />
            );
        }

        if (this.state.loading) {
            return (
                <div className="d-flex h-100 justify-content-center">
                    <Spinner className="align-self-center" animation="border" variant="dark" />
                </div>
            );
        }

        return (
            <div className="container py-3 h-100">
                <div className="row">
                    <div className="col-12">
                        <Button
                            className="pi-bg-color-secondary-gray text-white py-1 px-3 font-weight-light pi-font-size-14 mr-3 border-0 rounded-pill mb-3"
                            onClick={(): void => this.handleFilterSubmit({})}
                        >All</Button>
                        <Button
                            className="pi-bg-color-secondary-gray text-white py-1 px-3 font-weight-light pi-font-size-14 mr-3 border-0 rounded-pill mb-3"
                            onClick={(): void => this.handleFilterSubmit({type: 'MF'})}
                        >2-4 Units</Button>
                    </div>
                </div>

                <MarketplaceFilterForm handleFilterSubmit={this.handleFilterSubmit}/>

                <div className="row">
                    <div className="col-12">
                        {this.state.filteredAssets.length > 0 && <p>
                            Showing 1-{this.state.filteredAssets ? this.state.filteredAssets.length : ''} of {this.state.filteredAssets ? this.state.filteredAssets.length : ''} properties
                        </p>}
                    </div>
                </div>

                { this.displayFilteredResults() }
            </div>
        );
    }
}

export default Marketplace;
