import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';

function ComingSoonPlaceholder(): React.ReactElement {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center pi-bg-color-light-gray rounded border shadow-sm p-5">
            <div className="my-5"></div>
            <FontAwesomeIcon className="pi-color-primary-gray pi-font-size-36 mt-5" icon={faHardHat} />
            <p className="pi-color-primary-gray mb-5">Coming Soon</p>
            <div className="my-5"></div>
        </div>
    )
}

export default ComingSoonPlaceholder;