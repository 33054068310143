export function TimeAgo(date: string): string {
    let seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return `about ${Math.floor(interval)} year${determinePlurality(interval)} ago`
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `about ${Math.floor(interval)} month${determinePlurality(interval)} ago`
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} day${determinePlurality(interval)} ago`

    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hour${determinePlurality(interval)} ago`
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} minute${determinePlurality(interval)} ago`
    }
    return `${Math.floor(interval)} second${determinePlurality(interval)} ago`
}

function determinePlurality(interval: number): string {
    if (interval >= 2) {
        return 's';
    }
    return '';
}