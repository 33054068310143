import * as React from "react";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import HelpList from "./HelpList";
import { aboutList, gettingStartedList, ourInvestmentsList, taxesList } from "./FaqLists";
import FaqSearchForm from "../../../Shared/Forms/FaqSearchForm";
import { FaqList, FaqState } from "../../../Types/Features";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";

class Faq extends React.Component<{}, FaqState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            aboutList: aboutList,
            gettingStartedList: gettingStartedList,
            ourInvestmentsList: ourInvestmentsList,
            taxesList: taxesList,
            activeKey: '0'
        }

        this.search = this.search.bind(this);
        this.customToggle = this.customToggle.bind(this);
    }

    customFilter(keywords: string[], list: FaqList[]): FaqList[] {
        const filteredList: FaqList[] = [];

        list.forEach(item => {
            for (let i = 0; i < keywords.length; i++) {
                if (item.title.toLowerCase().includes(keywords[i].toLowerCase()) || item.body.toLowerCase().includes(keywords[i].toLowerCase())) {
                    filteredList.push(item);
                    break;
                }
            }
        });

        return filteredList;
    }

    toggleAccordions(activePanels: boolean[]): number {
        for(let i = 0; i < activePanels.length; i++) {
            if (activePanels[i]) {
                return i;
            }
        }
        return 0;
    }

    shouldToggle(list: FaqList[]): boolean {
        return list && list.length > 0;
    }

    customToggle(eventKey: string): void {
        this.setState({
            activeKey: eventKey
        });
    }

    search(values: {query: string}): void {
        const keywords = values.query.split(' ');

        const filteredAboutList: FaqList[] = this.customFilter(keywords, aboutList);
        const filteredGettingStartedList: FaqList[] = this.customFilter(keywords, gettingStartedList);
        const filteredOurInvestmentsList: FaqList[] = this.customFilter(keywords, ourInvestmentsList);
        const filteredTaxesList: FaqList[] = this.customFilter(keywords, taxesList);

        const index = this.toggleAccordions([
            this.shouldToggle(filteredAboutList),
            this.shouldToggle(filteredGettingStartedList),
            this.shouldToggle(filteredOurInvestmentsList),
            this.shouldToggle(filteredTaxesList)
        ]);

        this.setState({
            aboutList: filteredAboutList,
            gettingStartedList: filteredGettingStartedList,
            ourInvestmentsList: filteredOurInvestmentsList,
            taxesList: filteredTaxesList,
            activeKey: index.toString()
        });
    }

    render(): React.ReactElement {
        return (
            <div>
                <div className="container">
                    <MasterHeader />
                </div>

                <div className="pi-bg-color-secondary">
                    <div className="container">
                        <div className="row justify-content-center py-5 ">
                            <div className="col-12">
                                <h1 className="text-white text-center">Frequently Asked Questions</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center my-5">
                        <div className="col-12 col-md-6">
                            <h1 className="text-center mb-4 pi-font-size-24">HOW CAN WE HELP YOU?</h1>

                            <FaqSearchForm handleSubmit={this.search} />
                        </div>
                    </div>

                    <div className="row justify-content-center mb-5">
                        <div className="col-12 col-md-8">
                            <Accordion defaultActiveKey="0" activeKey={this.state.activeKey}>
                                <Card>
                                    <Accordion.Toggle className="pi-bg-color-primary-gray" as={Card.Header} eventKey="0" onClick={(): void => this.customToggle('0')}>
                                        <span className="pi-font-size-24 text-white">About PeerInvest</span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <HelpList faqList={this.state.aboutList} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Accordion.Toggle className="pi-bg-color-primary-gray" as={Card.Header} eventKey="1" onClick={(): void => this.customToggle('1')}>
                                        <span className="pi-font-size-24 text-white">Getting started</span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <HelpList faqList={this.state.gettingStartedList} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Accordion.Toggle className="pi-bg-color-primary-gray" as={Card.Header} eventKey="2" onClick={(): void => this.customToggle('2')}>
                                        <span className="pi-font-size-24 text-white">Our investments</span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <HelpList faqList={this.state.ourInvestmentsList} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Accordion.Toggle className="pi-bg-color-primary-gray" as={Card.Header} eventKey="3" onClick={(): void => this.customToggle('3')}>
                                        <span className="pi-font-size-24 text-white">Taxes</span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            <HelpList faqList={this.state.taxesList} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>

                <HomeFooter />
            </div>
        );
    }
}

export default Faq;
