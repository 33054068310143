import * as React from 'react';

import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import "./Styles/AboutUs.css";
import jeff from "../../../Assets/Jeff_He_headshot.jpeg";
import grant from "../../../Assets/Grant_Thompson_headshot.jpeg";
import ben from "../../../Assets/Ben_Seibert_headshot.jpeg";

import { Button } from "react-bootstrap";

import { Redirect, useHistory } from 'react-router-dom';

function AboutUs(): React.ReactElement {
    const [redirect, setRedirect] = React.useState<boolean>(false);
    const history = useHistory();

    function redirectToCareers(): void {
        setRedirect(true);
    }

    if (redirect) {
        history.push('/company/about-us');
        return <Redirect to='/company/careers' />;
    }

    return (
        <div>
            <div className="container">
                <MasterHeader />

                <div className="my-5">
                    <div className="row pi-hero mt-5 justify-content-center">
                        <div className="col-12 col-lg-8 text-center mt-0 mt-md-5 mb-5 mb-md-0">
                            <h2 className="pi-font-size-36  mx-auto my-5">
                                We are on a mission to make real estate investing simple and accessible to everyone.
                            </h2>
                            <Button
                                className="pi-bg-color-primary text-white border-0 mt-5 mb-5 mb-md-0 px-4 py-2"
                                onClick={redirectToCareers}
                            >View Openings</Button>
                        </div>
                    </div>

                    <div className="row px-0 pi-shadow pi-bg-color-primary text-white justify-content-center">
                        <div className="col-12 col-md-6">
                            <h2 className="text-center mt-5 mb-4 pi-font-size-32">Our Principals</h2>

                            <p className="text-center pi-font-size-20">
                                Here at PeerInvest, our mission is to do good. To accomplish this, we are guided by these top principals.
                            </p>

                            <div className="row mb-3">
                                <div className="col-12 col-md-12 pi-color-primary-gray">
                                    <div className="bg-white shadow rounded p-3 h-100">
                                        <strong>Transparency</strong>. We value our user’s trust. We believe the best way to earn our user’s trust
                                        is through transparency. We believe that all information available to us should be available to our users. We
                                        strive to attain a level of disclosure that exceeds the SEC requirements.
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-12 pi-color-primary-gray h-100">
                                    <div className="bg-white shadow rounded p-3 h-100">
                                        <strong>Compliance</strong>. We find it paramount that we abide by all laws and regulation. The securities space
                                        is complex and filled with rules. We do our due diligence to make a best effort to ensure that everything we do
                                        is legal and falls into compliance. PeerInvest has currently hired PepperLaw to provide expert advice and to review
                                        all legal documents.
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-12 pi-color-primary-gray">
                                    <div className="bg-white shadow rounded p-3 h-100">
                                        <strong>Protect and Grow User Capital</strong>. Our users come to PeerInvest because they want to see their wealth
                                        grow. At PeerInvest, we do too. We would never list a property on our platform that the founders themselves would
                                        not be comfortable investing in. We offer low fees because we want to deliver as much value as possible to our users.
                                        The fee structure is defined in such a way that if the investors don’t see profit, neither does PeerInvest.
                                        As a matter of fact, the founders believe in real estate and the platform so much that they also invest alongside
                                        the investors. The financial interest of PeerInvest is always aligned with the financial interest of its users.
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-12 pi-color-primary-gray">
                                    <div className="bg-white shadow rounded p-3 h-100">
                                        <strong>Protect User Data</strong>. User data is important, especially financial data. We do our best to protect user
                                        data and to never share or sell it to third parties. PeerInvest does not store any user banking information and relies
                                        on trusted vendors like Plaid and Stripe to handle banking.
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-12 pi-color-primary-gray">
                                    <div className="bg-white shadow rounded p-3">
                                        <strong>Ethical</strong>. At PeerInvest, we believe that all our actions shall be ethical and should contribute to doing
                                        good. We uphold this high standard of integrity for our contractors as well (Realtors, Property Managers, Contractors,
                                        etc). PeerInvest does not tolerate any sort of discrimination or fraud in any form. We will refuse to work with any
                                        entity that does not meet our standards.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12 mt-5">
                            <h2 className="text-center mb-4">Meet the Founders</h2>
                            <div className="row justify-content-around">
                                <div className="col-12 col-md-3 text-center mb-5 mb-md-0">
                                    <img alt={'jeffrey he - cofounder'} src={jeff} className="w-75 rounded-circle mb-3" />
                                    <h3>Jeffrey He</h3>
                                    <p>Cofounder</p>
                                </div>

                                <div className="col-12 col-md-3 text-center mb-5 mb-md-0">
                                    <img alt={'benjamin seibert - cofounder'} src={ben} className="w-75 rounded-circle mb-3" />
                                    <h3>Benjamin Seibert</h3>
                                    <p>Cofounder</p>
                                </div>

                                <div className="col-12 col-md-3 text-center mb-5 mb-md-0">
                                    <img alt={'grant thompson - cofounder'} src={grant} className="w-75 rounded-circle mb-3" />
                                    <h3>Grant Thompson</h3>
                                    <p>Cofounder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    );
}

export default AboutUs;
