import * as React from "react";

import { MicrodepositModalProps } from "../../Types/Shared";
import { MicrodepositModalState } from "../../Types/Data";
import MicrodepositForm from '../Forms/MicrodepositForm';
import VerifyMicrodepositForm from '../Forms/VerifyMicrodepositForm';


import { getDwollaFundingSource, createMicrodeposit, postVerifyMicrodeposit} from "../../Services/Banking/BankingServices";
import { checkDwollaVerificationStatus } from "../../Services/Auth/Auth";

import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function MicrodepositModal (props: MicrodepositModalProps): React.ReactElement {
	const [accounts, setAccounts] = React.useState<string[]>([]);
	const [isLoadingAccounts, setIsLoadingAccounts] = React.useState<boolean>(false);
	const [createMicrodepositMessage, setCreateMicrodepositMessage] = React.useState<string>("");
	const [verifyMicrodepositMessage, setVerifyMicrodepositMessage] = React.useState<string>("");

	function submitMicrodeposit(values: any): void {
		createMicrodeposit(values.routingNumber, values.accountNumber, values.accountType, values.bankName).then(res => {
			setCreateMicrodepositMessage("Success");
			props.handleState(MicrodepositModalState.postDeposit);
		}).catch(err => {
			setCreateMicrodepositMessage("Could not submit microdeposit. Please verify your information is correct");
		});
	}
	function verifyMicrodeposit(values: any): void {
		console.log("submitting verify microdeposit")
		postVerifyMicrodeposit(values.account, values.amount1, values.amount2).then(res => {
			setVerifyMicrodepositMessage("Verified");
			props.handleState(MicrodepositModalState.postVerify);
		}).catch(err => {
			setVerifyMicrodepositMessage("Could not verify");
		});
	}

	function checkVerificationStatus(): void {
        checkDwollaVerificationStatus().catch(() => {
            // Let's add some error handling here...
        });
    }


	React.useEffect(() => {
        setIsLoadingAccounts(true);
        checkVerificationStatus();
        getDwollaFundingSource("False").then(res => {
            console.log(res.data);
            if (res.data && res.data.length) {
                let newAccounts: string[] = [];
                res.data.forEach(acc => {
                	if (acc != "Balance"){
                    	newAccounts.push(acc)
                    }
                });
                setAccounts(newAccounts);
            }
            setIsLoadingAccounts(false);
        }).catch(() => {
            setIsLoadingAccounts(false);
        });
    }, []);
	if(props.state == MicrodepositModalState.deposit){
		return(
			<div>
				<MicrodepositForm
					handleSubmit={submitMicrodeposit}
				/>
				<p className="text-white pi-font-size-14">{createMicrodepositMessage}</p>
			</div>

		)
	} else if (props.state == MicrodepositModalState.postDeposit){	
		return(
			<div className="text-center">
				<FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faCheckCircle} />
				<p className="text-white pi-font-size-14">
					A microdeposit has been succesfully initiated.
					We will make two deposits (less than $1) into
					your bank account. 
				</p>
				<p className="text-white pi-font-size-14">
				    In 2 - 3 days, check your bank
					account and enter the 2 amounts using the verify
					button.
				</p>
			</div>

		)
	} else if (props.state == MicrodepositModalState.postVerify){
		return(
			<div className="text-center">
				<FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faCheckCircle} />
				<p className="text-white pi-font-size-14">
					Your bank account has been linked and is now
					ready to use. 
				</p>
			</div>		
		)
	} else if (props.state == MicrodepositModalState.verify){
		const { innerWidth: width, innerHeight: height } = window;
		return(
			<div>
				<VerifyMicrodepositForm
					accounts={accounts}
					handleSubmit={verifyMicrodeposit}
				/>
				<p className="text-white pi-font-size-14">{verifyMicrodepositMessage}</p>
			</div>
		)
	} else {
		return(
			<div>				
				<p className="text-white pi-font-size-14">Invalid State</p>
			</div>
		)
	}
	
}

export default MicrodepositModal;
