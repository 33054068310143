import * as React from "react";

import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import ContactUsForm from "../../../Shared/Forms/ContactUsForm";
import { supportRequest } from "../../../Services/Home/HomeServices";
import { SupportState } from "../../../Types/Features";
import { SupportRequestParams } from "../../../Types/Services";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";

class Support extends React.Component<{}, SupportState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            success: false,
            error: false,
            asyncIsSubmitting: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values: SupportRequestParams): void {
        this.setState({
            asyncIsSubmitting: true
        })
        supportRequest(values).then(() => {
            this.setState({
                success: true,
                asyncIsSubmitting: false,
                error: false
            });
        }).catch(() => {
            this.setState({
                error: true,
                asyncIsSubmitting: false,
                success: false
            });
        });
    }

    render(): React.ReactElement {
        let submitMessage;
        if (this.state.success) {
            submitMessage = 'Your inquiry has been received. We will respond as soon as possible.';
        } else if (this.state.error) {
            submitMessage = 'An error occurred sending your request. Please try again, if the issue persists then send us an email directly at support@peerinvest.io';
        }

        return (
            <div>
                <div className="container">
                    <MasterHeader />
                </div>

                <div className="pi-bg-color-secondary">
                    <div className="container">
                        <div className="row justify-content-center py-5 pi-bg-color-secondary">
                            <div className="col-12 col-md-8 col-xl-6">
                                <h1 className="text-white text-center">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center py-5">
                        <div className="col-12 col-md-8 col-xl-6">
                            <p className="mb-4 pi-font-size-20 text-center">
                                Got a question? We&apos;d love to hear from you. Send us a message and we&apos;ll respond as soon as possible.
                            </p>

                            <ContactUsForm
                                classes=""
                                asyncIsSubmitting={this.state.asyncIsSubmitting}
                                success={this.state.success}
                                handleSubmit={this.handleSubmit}
                            />

                            <div className="text-center text-white mt-3">
                                {submitMessage}
                            </div>
                        </div>
                    </div>
                </div>

                <HomeFooter />
            </div>
        );
    }
}

export default Support;
