import * as React from 'react';
import './Styles/ContentCard.css';
import { ContentCardProps } from "../../Types/Shared";

function ContentCard(props: ContentCardProps): React.ReactElement {
    return (
        <div className={'card shadow-sm ' + (props.classes || '')}>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}

export default ContentCard;