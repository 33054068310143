import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function ErrorContainer(): React.ReactElement {
    return (
        <div className="container-fluid py-3 h-100">
            <div className="row justify-content-center h-100">
                <div className="col-12 col-lg-6 align-self-center text-center">
                    <FontAwesomeIcon className="pi-font-size-120 pi-color-primary mb-3" icon={faExclamationCircle} />
                    <h1 className="mb-3 pi-color-primary-gray">An Error Occurred</h1>
                    <p className="pi-color-primary-gray">Please try refreshing the page and trying again. If the issue persists, send us an email at support@peerinvest.io.</p>
                </div>
            </div>
        </div>
    );
}

export default ErrorContainer;