import * as React from 'react';
import { ReactElement } from "react";

class Preferences extends React.Component<{}, {}> {
    render(): ReactElement {
        return (
            <div>
                Preferences works!
            </div>
        )
    }
}

export default Preferences;