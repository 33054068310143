import * as React from 'react';

import { DiscussionReplyFormProps } from "../../Types/Shared";

import { Formik } from 'formik';

import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';

function DiscussionReplyForm(props: DiscussionReplyFormProps): React.ReactElement {
    return (
        <Formik
            initialValues={{
                reply: ''
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
                values.reply = '';
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                isSubmitting
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <InputGroup>
                        <Form.Control
                            name="reply"
                            type="text"
                            placeholder="Type your reply here..."
                            onChange={handleChange}
                            value={values.reply}
                        />
                        <InputGroup.Append>
                            <Button className="pi-primary-btn px-4" type="submit" disabled={!values.reply || isSubmitting}>
                                {isSubmitting ? <Spinner size="sm" animation="border" /> : 'Comment'}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form>
            )}
        />
    )
}

export default DiscussionReplyForm;