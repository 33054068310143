import * as React from "react";
import { useEffect } from "react";

import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import { getBlogPost, getBlogPosts } from "../../../Services/Home/HomeServices";
import { Post } from "../../../Types/Data";

import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { date } from "../../../Shared/Pipes/Date";

function Blog(): React.ReactElement {
    const [posts, setPosts] = React.useState<Post[]>([]);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getBlogPost(parseInt(id)).then(res => {
                if (res.data) {
                    setPosts([res.data]);
                }
            })
        } else {
            getBlogPosts().then(res => {
                if (res.data && res.data.length) {
                    setPosts(res.data.sort((a, b) => {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    }));
                }
            });
        }

    }, [id]);

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="row justify-content-center py-4 py-lg-5">
                <div className="col-12">
                    <h1 className="pi-font-size-24 text-center">PeerInvest Blog</h1>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center align-items-stretch pb-5 mb-0 mb-lg-3">
                    {posts.map(post =>
                        <div key={post.id} className="col-12 col-lg-3 mb-3">
                            <Card className="w-100 h-100">
                                <Card.Img variant="top" src={post.img} />
                                <Card.Body>
                                    <Card.Title>
                                        <div className="pi-color-primary-gray mb-1">{post.title}</div>
                                        <div className="pi-color-primary-gray pi-font-size-14">{date(post.created_at, 'mmm, dd, yyyy')}</div>
                                    </Card.Title>
                                    <Card.Text>
                                        <div dangerouslySetInnerHTML={{__html: post.content.substring(0,150) + '...'}}></div>
                                        <a href={`/support/blog/${post.id}`}>Read More &gt;</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </div>
            </div>

            <HomeFooter />
        </div>
    );
}

export default Blog;
