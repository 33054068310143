import * as React from 'react';

import { KBAFormProps } from "../../Types/Shared";

import { Formik } from 'formik';

import { Form, Button } from 'react-bootstrap';

function KBAForm(props: KBAFormProps): React.ReactElement {
    return (
        <Formik
            initialValues={{
                q1: '',
                q2: '',
                q3: '',
                q4: ''
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
            }}
            render={({
                handleSubmit,
                handleChange,
                isSubmitting
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    { props.questions.map((question, index) =>
                        <Form.Group key={question.id} controlId={'q' + (index + 1)}>
                            <Form.Label>{question.text}</Form.Label>
                            <Form.Control
                                as="select"
                                name={'q' + (index + 1)}
                                onChange={handleChange}
                            >
                                <option value={''}>Select</option>
                                { question.answers.map(answer =>
                                    <option value={question.id + '-' + answer.id} key={answer.id}>{answer.text}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    )}

                    <div className="pb-4 mb-2">
                        <Button
                            className="w-100 pi-primary-btn py-2 pi-font-size-20"
                            disabled={isSubmitting || props.isLoading}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        />
    )
}

export default KBAForm;