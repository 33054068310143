import * as React from "react";
import { ReactElement } from "react";

import AccountOverview from "../Features/Dashboard/AccountOverview/AccountOverview";
import Marketplace from "../Features/Dashboard/Marketplace/Marketplace";
import Transactions from "../Features/Dashboard/Transactions/Transactions";
import NewsAndUpdates from "../Features/Dashboard/NewsAndUpdates/NewsAndUpdates";
import { DashboardRoutesProps } from "../Types/Routes";
import PropertyView from "../Features/Dashboard/PropertyDetails/PropertyView/PropertyView";

import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { flatMap } from "lodash";
import Documents from "../Features/Dashboard/Documents/Documents";
import PortfolioView from "../Features/Dashboard/Portfolio/PortfolioView";
import Refer from "../Features/Account/Refer/Refer"
import AuthGuard from './AuthGuard';

function DashboardRoutes(props: DashboardRoutesProps): ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/account-overview`}>
                <AccountOverview
                    investmentProfile={props.investmentProfile}
                    llcs={props.investmentProfile.llcs}
                />
            </Route>

            <Route path={`${path}/portfolio`}>
                <PortfolioView />
            </Route>

            <Route path={`${path}/marketplace`}>
                <Marketplace
                    isAuthenticated={props.isAuthenticated}
                />
            </Route>

            <Route path={`${path}/property/:id`}>
                <PropertyView />
            </Route>

            <Route path={`${path}/transactions`}>
                <Transactions
                    transactions={flatMap(props.investmentProfile.positions, pos => pos.transactions)}
                />
            </Route>

            <Route path={`${path}/news-and-updates`}>
                <NewsAndUpdates
                    isAuthenticated={props.isAuthenticated}
                    llcs={props.investmentProfile.llcs.filter(llc => llc !== 0)}
                />
            </Route>

            <Route path={`${path}/documents`}>
                <Documents
                    documents={props.investmentProfile.documents}
                />
            </Route>

            <Route path={`${path}/refer`}>
                <Refer/>
            </Route>

            <AuthGuard redirect={true} path={`${path}/account`}>
                <Redirect to={'/account'} />
            </AuthGuard>

            <Redirect from="/dashboard" to="/dashboard/account-overview" />
        </Switch>
    );
}

export default DashboardRoutes;
