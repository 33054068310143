import * as React from 'react';
import { ReactElement } from 'react';

import HomeNav from '../../Shared/HomeNav/HomeNav';
import { errorMap } from '../../Services/Errors/ErrorMap';
import LogInForm from '../../Shared/Forms/LogInForm';
import { LogInRedirects } from '../../Types/Features';
import { StoreState } from '../../Types/Store';
import { ActionTypes } from '../../Store/Actions';
import { logIn } from '../../Services/Auth/Auth';
import './Styles/LogIn.css';

import Button from 'react-bootstrap/Button';

import { Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import Cookies from 'universal-cookie';
import axios from 'axios';

function LogIn(): ReactElement {
    const isAuthenticated = useSelector(
        (store: StoreState) => store.isAuthenticated,
    );
    const hasCheckedAuth = useSelector(
        (store: StoreState) => store.hasCheckedAuth,
    );
    const [redirectTo, setRedirectTo] = React.useState<LogInRedirects>();
    const [loginErrorMessage, setLoginErrorMessage] = React.useState<string>();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const redirectParam = new URLSearchParams(search).get('redirectTo');

    function handleSubmit(values: { email: string; password: string }): void {
        logIn(values.email, values.password)
            .then((res) => {
                const cookies = new Cookies();
                cookies.set('authCookie', res.data.token, {
                    path: '/',
                    maxAge: 10000,
                });
                axios.defaults.headers.common[
                    'Authorization'
                ] = `JWT ${res.data.token}`;
                dispatch({
                    type: ActionTypes.SET_COOKIES,
                    newCookies: cookies,
                });
                dispatch({
                    type: ActionTypes.SET_USER_INFO,
                    newUserInfo: res.data.user,
                });
                dispatch({
                    type: ActionTypes.SET_IS_AUTHENTICATED,
                    newIsAuthenticated: true,
                });

                if (
                    res.data.user.positions &&
                    res.data.user.positions.filter(
                        (pos) => pos.asset.symbol !== 'cash',
                    ).length === 0
                ) {
                    setRedirectTo(LogInRedirects.TO_MARKETPLACE);
                } else {
                    setRedirectTo(LogInRedirects.TO_MARKETPLACE);
                    // TODO(jeffhe): Return to original behavior
                    // when we figure out why this fork doesn't work.
                    // setRedirectTo(LogInRedirects.TO_DASHBOARD);
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    const errorCode = err.response.status;
                    let errorMessage;

                    switch (errorCode) {
                        case 400:
                            errorMessage = errorMap.invalidCredentials;
                            break;
                        case 401:
                            errorMessage = errorMap.tooManyAttempts;
                            break;
                        case 500:
                            errorMessage = errorMap.internalServerError;
                            break;
                        default:
                            errorMessage = errorMap.default;
                    }

                    setLoginErrorMessage(errorMessage);
                }
            });
    }

    function handleNavigate(route: LogInRedirects): void {
        setRedirectTo(route);
    }

    if (redirectTo === LogInRedirects.TO_SIGN_UP) {
        return <Redirect to="/auth/signup" />;
    }

    if (redirectTo === LogInRedirects.TO_PWD_RESET) {
        return <Redirect to="/auth/reset-email" />;
    }

    if (
        redirectTo === LogInRedirects.TO_DASHBOARD ||
        (isAuthenticated && hasCheckedAuth)
    ) {
        if (redirectParam) {
            return <Redirect to={redirectParam} />;
        }
        return <Redirect to="/dashboard/account-overview" />;
    }

    if (
        redirectTo === LogInRedirects.TO_MARKETPLACE ||
        (isAuthenticated && hasCheckedAuth)
    ) {
        if (redirectParam) {
            return <Redirect to={redirectParam} />;
        }
        return <Redirect to="/dashboard/marketplace" />;
    }

    return (
        <div className="min-vh-100">
            <div className="container px-0">
                <HomeNav />
            </div>

            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-12 col-md-5 col-lg-4 col-xl-3 mt-0 mt-lg-5">
                        <h1 className="d-none d-lg-block display-4 pi-font-size-32 text-center mb-4">
                            Log in to PeerInvest
                        </h1>
                        <h1 className="d-block d-lg-none display-4 pi-font-size-28 text-center mb-4">
                            Log in to PeerInvest
                        </h1>

                        {loginErrorMessage ? (
                            <p className="text-danger text-left pi-montserrat">
                                {loginErrorMessage}
                            </p>
                        ) : null}

                        <LogInForm handleLogin={handleSubmit} />

                        <div className="text-center mt-1">
                            <Button
                                variant="link"
                                onClick={(): void =>
                                    handleNavigate(LogInRedirects.TO_SIGN_UP)
                                }
                                className="pi-font-size-16 pi-montserrat">
                                New user? Sign up here.
                            </Button>
                        </div>
                        <div className="text-center mt-1">
                            <Button
                                variant="link"
                                onClick={(): void =>
                                    handleNavigate(LogInRedirects.TO_PWD_RESET)
                                }
                                className="pi-font-size-16 pi-montserrat">
                                Reset password
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;
