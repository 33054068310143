import React, { Component } from 'react';

import Card from 'react-bootstrap/Card'

import {
    getAccounts,
    getDwollaFundingSource,
    plaidLinkToken,
    plaidPublicToken,
    removeAccount,
    removeDwollaAccount,
    createDwollaFunding,
    getDwollaBalanceAmount,
} from '../../../Services/Banking/BankingServices';
import { BankingProps, BankingState } from '../../../Types/Features';
import { connect } from 'react-redux';
import { StoreState } from '../../../Types/Store';
import IconButton from '../../../Shared/BasicComponents/IconButton';
import { checkDwollaVerificationStatus } from '../../../Services/Auth/Auth';
import './Styles/Banking.css';

import { 
    usePlaidLink,
    PlaidLinkOptions,
    PlaidLinkOnSuccess,
    PlaidLink
} from 'react-plaid-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faLink } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal } from 'react-bootstrap';

import PersonalInfoModal from '../../../Shared/Modals/PersonalInfoModal';
import TransferModal from '../../../Shared/Modals/TransferModal';
import MicrodepositModal from '../../../Shared/Modals/MicrodepositModal';
import PlaidSelectAccountModal from '../../../Shared/Modals/PlaidSucessModal';

import {
    DwollaVerificationStatus,
    PlaidAccount,
    UserInfo,
    MicrodepositModalState,
    PlaidModalState,
} from '../../../Types/Data';
import DwollaKBA from '../../../Shared/DwollaKBA/DwollaKBA';

class Banking extends Component<BankingProps, BankingState> {
    constructor(props: any) {
        super(props);

        this.state = {
            transactions: [],
            accounts: [],
            dwollaFundingSources: [],
            dwolla_balance: 0,
            hasFetchedDwollaBalance: false,
            selected_account: '',
            amount: 0,
            transaction_type: 'DP', // Transfer to PeerInvest (Deposit)
            hasFetchedAccounts: false,
            hasFetchedDwollaSources: false,
            verificationStatus: DwollaVerificationStatus.Not_Verified,
            hasCheckedVerification: false,
            showModal: false,
            showMicrodepositModal: false,
            showTransferModal: false,
            linkToken: '',
            hasFetchedLinkToken: false,
            showKBAModal: false,
            showPlaidSuccessModal: false,
            microdepositState: MicrodepositModalState.deposit,
            plaidState: PlaidModalState.select
        };

        this.handleOnSuccess = this.handleOnSuccess.bind(this);
        this.fetchAccounts = this.fetchAccounts.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showTransferModal = this.showTransferModal.bind(this);
        this.showMicrodepositModal = this.showMicrodepositModal.bind(this);
        this.showVerifyMicrodepositModal =
            this.showVerifyMicrodepositModal.bind(this);        
        this.handleMicrodepositModalState = 
            this.handleMicrodepositModalState.bind(this)
        this.handlePlaidModalState = this.handlePlaidModalState.bind(this)
        this.handleSelectPlaidAccount = this.handleSelectPlaidAccount.bind(this)
        this.handleClose = this.handleClose.bind(this);    
    }

    componentDidMount(): void {
        if (this.props.userInfo.first_name) {
            this.fetchAccounts();
            this.fetchDwollaFundingSources();
            this.checkVerificationStatus();
            this.getPlaidLinkToken();
            this.fetchDwollaBalanceAmount();
        }
    }

    componentDidUpdate(): void {
        if (this.props.userInfo.first_name) {
            if (!this.state.hasFetchedAccounts) {
                this.fetchAccounts();
            }

            if (!this.state.hasFetchedDwollaBalance) {
                this.fetchDwollaBalanceAmount();
            }

            if (!this.state.hasFetchedDwollaSources) {
                this.fetchDwollaFundingSources();
            }

            if (!this.state.hasCheckedVerification) {
                this.checkVerificationStatus();
            }

            if (!this.state.hasFetchedLinkToken) {
                this.getPlaidLinkToken();
            }
        }
    }

    getPlaidLinkToken(): void {
        this.setState({
            hasFetchedLinkToken: true,
        });

        plaidLinkToken().then((res) => {
            this.setState({
                linkToken: res.data,
            });
        });
    }

    handleOnSuccess(public_token: string): void {
        // send token to client server
        // Use this to show a modal to walk user through linking an account.
        // This should be deprecated because Plaid has phased out
        // public tokens for link tokens.
        this.fetchAccounts()
        this.setState({
            showPlaidSuccessModal: true,
            plaidState: PlaidModalState.select
        });
        /*
        plaidPublicToken(public_token).then(() => {
            this.fetchAccounts()
            this.setState({
                showPlaidSuccessModal: true,
                plaidState: PlaidModalState.select
            });
        });*/
    }

    fetchAccounts(): void {
        this.setState({
            hasFetchedAccounts: true,
        });
        getAccounts().then((res) => {
            console.log(res)
            this.setState({
                accounts: res.data,
            });
        });
    }

    fetchDwollaFundingSources(): void {
        this.setState({
            hasFetchedDwollaSources: true,
        });
        getDwollaFundingSource('True').then((res) => {
            if (res.data.length > 0) {
                this.setState({
                    dwollaFundingSources: res.data,
                    showModal: false,
                });
            } else {
                this.setState({
                    dwollaFundingSources: res.data,
                    showModal: true,
                });
            }
        });
    }

    fetchDwollaBalanceAmount(): void {
        this.setState({
            hasFetchedDwollaBalance: true,
        });
        getDwollaBalanceAmount().then((res) => {
            this.setState({
                dwolla_balance: res.data,
            });
        });
    }

    checkVerificationStatus(): void {
        this.setState({
            hasCheckedVerification: true,
        });
        checkDwollaVerificationStatus()
            .then((res) => {
                if (res.data === DwollaVerificationStatus.KBA_Needed) {
                    this.setState({
                        showKBAModal: true,
                    });
                }
                this.setState({
                    verificationStatus: res.data,
                });
            })
            .catch(() => {
                // Let's add some error handling here...
            });
    }

    handleOnExit(): void {
        // handle the case when the user exits Link
    }

    handleRemoveAccount(account: string): void {
        removeAccount(account).then((res) => {
            this.setState({
                accounts: res.data,
            });
        });
    }

    handleRemoveDwolla(account: string): void {
        removeDwollaAccount(account).then((res) => {
            getDwollaFundingSource('True').then((res) => {
                this.setState({
                    dwollaFundingSources: res.data,
                });
            });
        });
    }

    handleCreateDwollaFunding(account: string, item: string): void {
        createDwollaFunding(account, item).then((res) => {
            this.fetchDwollaFundingSources();
            this.setState({
                showPlaidSuccessModal: true,
                plaidState: PlaidModalState.success,
            });
        }).catch(() => {
            this.setState({
                showPlaidSuccessModal: true,
                plaidState: PlaidModalState.error,
            });
        });
    }

    handleSelectPlaidAccount(account_label: string, item_id: string): void {
        this.handleCreateDwollaFunding(account_label, item_id)
    }

    showModal(): void {
        this.setState({
            showModal: true,
        });
    }

    showMicrodepositModal(): void {
        this.setState({
            showMicrodepositModal: true,
            microdepositState: MicrodepositModalState.deposit,
        });
    }

    showVerifyMicrodepositModal(): void {
        this.setState({
            microdepositState: MicrodepositModalState.verify,
            showMicrodepositModal: true,
        });
    }

    handleMicrodepositModalState(state : MicrodepositModalState): void{
        this.setState({
            microdepositState: state,
            showMicrodepositModal: true,
        });
    }

    handlePlaidModalState(state : PlaidModalState): void{
        this.setState({
            plaidState: state,
            showPlaidSuccessModal: true,
        });
    }

    showTransferModal(): void {
        this.setState({
            showTransferModal: true,
        });
    }

    handleClose(): void {
        this.setState({
            showModal: false,
            showTransferModal: false,
            showMicrodepositModal: false,
            showPlaidSuccessModal: false,
            verificationStatus: DwollaVerificationStatus.Verified, // This might not be the right place for this.
        });
    }

    render(): React.ReactElement {
        return (
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mb-3 border-bottom">
                            Financial Institutions
                        </h1>
                        <p>
                            Follow the instructions to link a financial
                            institution. You'll need to link a bank account
                            to begin investing.
                        </p>

                        {this.state.verificationStatus ===
                            DwollaVerificationStatus.KBA_Needed && (
                            <DwollaKBA
                                show={this.state.showKBAModal}
                                close={(): void =>
                                    this.setState({ showKBAModal: false })
                                }
                            />
                        )}

                        <Modal
                            show={this.state.showModal}
                            onHide={(): void => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Identity Verification</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <PersonalInfoModal
                                    handleClose={(): void => this.handleClose()}
                                    lightMode={true}
                                    address1={
                                        this.props.userInfo.address1
                                            ? this.props.userInfo.address1
                                            : ''
                                    }
                                    address2={
                                        this.props.userInfo.address2
                                            ? this.props.userInfo.address2
                                            : ''
                                    }
                                    city={
                                        this.props.userInfo.city
                                            ? this.props.userInfo.city
                                            : ''
                                    }
                                    state={
                                        this.props.userInfo.state
                                            ? this.props.userInfo.state
                                            : ''
                                    }
                                    zip={
                                        this.props.userInfo.zip
                                            ? this.props.userInfo.zip.toString()
                                            : ''
                                    }
                                />
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={this.state.showMicrodepositModal}
                            onHide={(): void => this.handleClose()}>
                            <Modal.Header
                                className="pi-bg-color-dark border-secondary text-white"
                                closeButton>
                                <Modal.Title>
                                    Link with Microdeposits
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pi-bg-color-dark">
                                <MicrodepositModal
                                    handleState={(state): void => this.handleMicrodepositModalState(state)}
                                    state = {this.state.microdepositState}
                                    handleClose={(): void => this.handleClose()}
                                    lightMode={false}
                                />
                            </Modal.Body>
                        </Modal>

                        <Modal
                            show={this.state.showTransferModal}
                            onHide={(): void => this.handleClose()}>
                            <Modal.Header
                                className="pi-bg-color-dark border-secondary text-white"
                                closeButton>
                                <Modal.Title>Transfer Funds</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pi-bg-color-dark">
                                <TransferModal
                                    handleClose={(): void => this.handleClose()}
                                    amount={0}
                                    type={'withdraw'}
                                    account={'account_mask'}
                                    cashBalance={this.state.dwolla_balance}
                                />
                            </Modal.Body>
                        </Modal>

                        <Modal 
                            show={this.state.showPlaidSuccessModal}
                            onHide={(): void => this.handleClose()}>
                            <Modal.Header
                                className="pi-bg-color-dark border-secondary text-white"
                                closeButton>
                                <Modal.Title>
                                    Link with Plaid
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pi-bg-color-dark">
                                <PlaidSelectAccountModal
                                    handleAccountSubmit = {this.handleSelectPlaidAccount}
                                    accounts = {this.state.accounts}
                                    modalState = {this.state.plaidState}
                                    handleState={(state): void => this.handlePlaidModalState(state)}
                                    handleClose={(): void => this.handleClose()}
                                />
                            </Modal.Body>
                        </Modal>

                        <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mt-3 border-bottom">
                                Use Plaid
                            </h1>
                            <p>Same day</p>
                            {this.state.verificationStatus !==
                                DwollaVerificationStatus.Not_Verified && (
                                <PlaidLink
                                    env={
                                        process.env.REACT_APP_PLAID_ENV
                                            ? (process.env
                                                  .REACT_APP_PLAID_ENV as
                                                  | 'sandbox'
                                                  | 'development'
                                                  | 'production')
                                            : 'sandbox'
                                    }
                                    onExit={this.handleOnExit}
                                    onSuccess={this.handleOnSuccess}
                                    token={this.state.linkToken}
                                    className="w-100 pi-primary-btn">
                                    Link Account
                                </PlaidLink>
                            )}

                            {this.state.verificationStatus ===
                                DwollaVerificationStatus.Not_Verified && (
                                <Button className="pi-primary-btn w-100">
                                    Link Account2
                                </Button>
                            )}
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mt-3 border-bottom">
                                Use Microdeposits
                            </h1>
                            <p>2-3 days</p>
                            <Button
                                className="pi-primary-btn w-100"
                                onClick={this.showMicrodepositModal}>
                                Link Account
                            </Button>
                            <Button
                                className="pi-primary-btn w-100 mt-2"
                                onClick={this.showVerifyMicrodepositModal}>
                                Verify
                            </Button>
                        </div>
                        </div>

                        {this.state.dwollaFundingSources &&
                            this.state.dwollaFundingSources.length > 0 && (
                                <div>
                                    <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mt-3 border-bottom">
                                        Linked Accounts
                                    </h1>
                                    {this.state.dwollaFundingSources.map(
                                        (label: string) => (
                                            <p
                                                className="mb-0"
                                                id={label}
                                                key={label}>
                                                <IconButton
                                                    classes="text-white pi-bg-color-dark rounded"
                                                    onClick={(): void =>
                                                        this.handleRemoveDwolla(
                                                            label,
                                                        )
                                                    }>
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </IconButton>
                                                {label}
                                            </p>
                                        ),
                                    )}
                                </div>
                            )}

                        <div>
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mt-3 border-bottom">
                                Cash Balance
                            </h1>
                        </div>
                        <p>
                            ${this.state.dwolla_balance}
                            <Button
                                className="pi-primary-btn w-100"
                                onClick={this.showTransferModal}>
                                Transfer Funds
                            </Button>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): { userInfo: UserInfo } => {
    return {
        userInfo: state.userInfo,
    };
};

export default connect(mapStateToProps)(Banking);
