import * as React from 'react';

import { Route, Switch, useRouteMatch } from "react-router-dom";
import Blog from "../Features/Home/Blog/Blog";
import BlogPost from "../Features/Home/Blog/BlogPost";

function BlogRoutes(): React.ReactElement {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Blog />
            </Route>

            <Route path={`${path}/:id`}>
                <BlogPost />
            </Route>
        </Switch>
    );
}

export default BlogRoutes;
