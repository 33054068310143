import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams, useHistory } from "react-router-dom";
import { Post } from "../../../Types/Data";
import { getBlogPost } from "../../../Services/Home/HomeServices";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import { Button } from "react-bootstrap";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./Styles/Blog.css";

function BlogPost(): React.ReactElement {
    const [post, setPost] = React.useState<Post>()
    const { id } = useParams();

    const history = useHistory();

    function handleBack(): void {
        history.push('/support/blog');
    }

    React.useEffect(() => {
        if (id && !post) {
            getBlogPost(parseInt(id)).then(res => {
                setPost(res.data);
            });
        }
    });

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="container">
                <div className="row justify-content-center pt-4 pt-lg-5">
                    <div className="col-12 col-lg-6">
                        <Button
                            onClick={handleBack}
                            className="px-0 pi-font-size-20 d-flex align-items-center"
                            variant="link"
                        >
                            <FontAwesomeIcon className="pi-font-size-16 mr-2" icon={faChevronLeft} />
                            <span>Back</span>
                        </Button>
                    </div>
                </div>
                <div className="row justify-content-center pb-5">
                    <div className="col-12 col-lg-6">
                        {post &&
                        <div className="pi-post-styles">
                            <img className="w-100 rounded" src={post.img} alt={'knowledge article hero'}/>
                            <h1 className="mt-3">{post.title}</h1>
                            <div className="text-justify" dangerouslySetInnerHTML={{__html: post.content}}></div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    )
}

export default BlogPost;
