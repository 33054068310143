import axios from "axios";

export function removeToken(): string {
    const token = axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization'];

    return token;
}

export function addToken(token: string): void {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `JWT ${token}`
    }
}