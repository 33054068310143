import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/NewsAndUpdates.css';
import { getPortfolio } from '../../../Services/Properties/Properties';
import { date } from '../../../Shared/Pipes/Date';
import ErrorContainer from '../../../Shared/BasicComponents/ErrorContainer';
import {
    NewsAndUpdatesProps,
    NewsAndUpdatesState,
} from '../../../Types/Features';
import { Update } from '../../../Types/Data';
import { StoreState } from '../../../Types/Store';
import { BREAKPOINTS } from '../../../Utils/Breakpoints';

import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

import { flatMap } from 'lodash';

import { connect } from 'react-redux';

class NewsAndUpdates extends React.Component<
    NewsAndUpdatesProps,
    NewsAndUpdatesState
> {
    constructor(props: NewsAndUpdatesProps) {
        super(props);

        this.state = {
            selectedUpdateIndex: undefined,
            error: false,
            loading: false,
        };

        this.closeUpdate = this.closeUpdate.bind(this);
    }

    componentDidMount(): void {
        if (
            this.props.llcs &&
            this.props.llcs.length > 0 &&
            this.props.updates &&
            this.props.updates.length === 0
        ) {
            this.callGetPortfolio();
        } else if (this.props.updates && this.props.updates.length > 0) {
            if (window.innerWidth > BREAKPOINTS.md) {
                this.setState({
                    selectedUpdateIndex: 0,
                });
            }
            this.setState({
                loading: false,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<NewsAndUpdatesProps>): void {
        if (
            this.props.llcs &&
            this.props.llcs.length !== prevProps.llcs.length
        ) {
            this.callGetPortfolio();
        }
    }

    callGetPortfolio(): void {
        if (this.props.isAuthenticated) {
            this.setState({ loading: true });

            getPortfolio(this.props.llcs)
                .then((res) => {
                    this.setState({ loading: false });
                    if (res.data.length > 0) {
                        this.props.setUpdates(
                            flatMap(res.data, (pos) => pos.updates),
                        );
                        if (window.innerWidth > BREAKPOINTS.md) {
                            this.setState({
                                selectedUpdateIndex: 0,
                            });
                        }
                    }
                })
                .catch(() => {
                    this.setState({
                        error: true,
                        loading: false,
                    });
                });
        }
    }

    setSelectedUpdateIndex(index: number): void {
        this.setState({
            selectedUpdateIndex: index,
        });
    }

    closeUpdate(): void {
        this.setState({
            selectedUpdateIndex: undefined,
        });
    }

    render(): ReactElement {
        if (this.state.error) {
            return <ErrorContainer />;
        }

        if (this.state.loading) {
            return (
                <div className="d-flex h-100 justify-content-center">
                    <Spinner
                        className="align-self-center"
                        animation="border"
                        variant="dark"
                    />
                </div>
            );
        }

        if (this.props.llcs && this.props.llcs.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <p>You have no open positions.</p>
                </div>
            );
        }

        return (
            <div className="container-fluid py-3 h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-12 h-100">
                        <div className="card h-100 pl-3 pr-3 shadow-sm">
                            <div className="row h-100">
                                <div
                                    className={
                                        'col-12 col-md-5 col-lg-4 col-xl-3 h-100 py-3 px-0 pi-responsive-border overflow-auto ' +
                                        (this.state.selectedUpdateIndex !==
                                        undefined
                                            ? 'd-none d-md-block'
                                            : 'd-block')
                                    }>
                                    <h1 className="pi-font-size-20 px-3">
                                        News & Updates
                                    </h1>
                                    <ListGroup variant="flush">
                                        {this.props.updates.map(
                                            (update: Update, index: number) => (
                                                <ListGroup.Item
                                                    action
                                                    onClick={(): void =>
                                                        this.setSelectedUpdateIndex(
                                                            index,
                                                        )
                                                    }
                                                    className="px-3"
                                                    key={update.id}>
                                                    <h2 className="pi-font-size-16">
                                                        {update.title}
                                                    </h2>
                                                    <p className="pi-font-size-14 pi-color-primary-gray">
                                                        {update.description.substring(
                                                            0,
                                                            100,
                                                        ) +
                                                            (update.description
                                                                .length > 100
                                                                ? '...'
                                                                : '')}
                                                    </p>
                                                    <p className="font-italic pi-font-size-14">
                                                        {date(
                                                            update[
                                                                'created_at'
                                                            ],
                                                            'mm/dd/yyyy',
                                                        )}
                                                    </p>
                                                </ListGroup.Item>
                                            ),
                                        )}
                                    </ListGroup>
                                </div>

                                {this.state.selectedUpdateIndex !==
                                    undefined && (
                                    <div
                                        className={
                                            'col-12 col-md-7 col-lg-8 col-xl-9 py-3 h-100 overflow-auto ' +
                                            (this.state.selectedUpdateIndex !==
                                            null
                                                ? 'd-block'
                                                : 'd-none d-md-block')
                                        }>
                                        <div>
                                            <Button
                                                className="d-md-none mb-3 border-0 bg-transparent text-left pi-color-primary-gray p-0"
                                                onClick={this.closeUpdate}>
                                                <FontAwesomeIcon
                                                    className="pi-font-size-14"
                                                    icon={faChevronLeft}
                                                />
                                                <h2 className="pi-font-size-14 d-inline ml-2">
                                                    BACK
                                                </h2>
                                            </Button>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props.updates[
                                                        this.state
                                                            .selectedUpdateIndex
                                                    ]?.content,
                                                }}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState): { updates: Update[] } => {
    return {
        updates: state.updates,
    };
};

const mapDispatchToProps = (dispatch: any): { setUpdates: Function } => {
    return {
        setUpdates: (updates: Update[]): any =>
            dispatch({ type: 'SET_UPDATES', newUpdates: updates }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsAndUpdates);
