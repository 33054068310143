import * as React from "react";

import { PropertyTimelineProps } from "../../../../Types/Features";
import { Update } from "../../../../Types/Data";
import "../Styles/PropertyTimeline.css";
import { date } from "../../../../Shared/Pipes/Date";

function PropertyTimeline(props: PropertyTimelineProps): React.ReactElement {
    const [sortedUpdates, setSortedUpdates] = React.useState<Update[]>([]);

    React.useEffect(() => {
         props.updates.sort((a, b) => {
             const aDate = new Date(a.created_at);
             const bDate = new Date(b.created_at);
             if (aDate < bDate) {
                 return 1;
             } else if (bDate < aDate) {
                 return -1;
             }
             return 0;
         });
         setSortedUpdates(props.updates);
    }, [props.updates]);

    return (
        <div className={"h-100 p-3 " + (props.hideCard ? '' : 'border rounded shadow-sm')}>
            {!props.hideCard &&
                <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-2 mb-lg-4">
                    Timeline
                </h2>
            }

            { sortedUpdates.length === 0 &&
                <p className="pi-color-primary-gray text-center">No updates available. Check back later.</p>
            }

            { sortedUpdates.map((update: Update, index) =>
                <div
                    className={"row align-items-stretch " + (!props.hideCard ? 'pi-update-click' : '')}
                    key={update.id}
                    onClick={() => props.handleSelect(update)}
                >
                    <div className="col-3 pr-1">
                        <p className="font-italic pi-font-size-12">
                            {date(update['created_at'], 'mm/dd/yyyy')}
                        </p>
                    </div>

                    <div className="col-1 px-1">
                        <div className="h-100">
                            <div className="pi-circle mx-auto"></div>
                            { index < (props.updates.length - 1) ? <div className="pi-line-height">
                                <div className="pi-line mx-auto h-100"></div>
                            </div> : null}
                        </div>
                    </div>

                    <div className="col-8 px-1">
                        <p className="mb-2">{update.title}</p>
                        <p className="pi-font-size-14 pi-color-primary-gray">
                            {update.description.substring(0,100) + (update.description.length > 100 ? '...' : '')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PropertyTimeline;
