import * as React from 'react';

import { PasswordResetFormProps } from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import { string, object, ref } from 'yup';

const PasswordResetSchema = object().shape({
    oldPassword: string()
        .required('*Required'),
    newPassword: string()
        .required('*Required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
        ),
    newPasswordConfirmation: string()
        .required('*Required')
        .oneOf([ref("newPassword"), null], "Passwords must match"),
});

const PasswordResetForm = (props: PasswordResetFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                oldPassword: '',
                newPassword: '',
                newPasswordConfirmation: ''
            }}
            validationSchema={PasswordResetSchema}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
            }}
            render={({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleSubmit,
                 isSubmitting,
             }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="passwordChangeForm1">
                        <Form.Label>
                            Old Password
                            {errors.oldPassword === '*Required' && touched.oldPassword ? (
                                <span className="text-danger ml-2">{errors.oldPassword}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="oldPassword"
                            type="password"
                            placeholder=""
                            onChange={handleChange}
                            value={values.oldPassword}
                        />
                        {errors.oldPassword !== '*Required' && touched.oldPassword ? (
                            <span className="text-danger">{errors.oldPassword}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="passwordChangeForm2">
                        <Form.Label>
                            New Password
                            {errors.newPassword === '*Required' && touched.newPassword ? (
                                <span className="text-danger ml-2">{errors.newPassword}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="newPassword"
                            type="password"
                            placeholder=""
                            onChange={handleChange}
                            value={values.newPassword}
                        />
                        {errors.newPassword !== '*Required' && touched.newPassword ? (
                            <span className="text-danger">{errors.newPassword}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="passwordChangeForm3">
                        <Form.Label>
                            Confirm New Password
                            {errors.newPasswordConfirmation === '*Required' && touched.newPasswordConfirmation ? (
                                <span className="text-danger ml-2">{errors.newPasswordConfirmation}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="newPasswordConfirmation"
                            type="password"
                            placeholder=""
                            onChange={handleChange}
                            value={values.newPasswordConfirmation}
                        />
                        {errors.newPasswordConfirmation !== '*Required' && touched.newPasswordConfirmation ? (
                            <span className="text-danger">{errors.newPasswordConfirmation}</span>
                        ) : null}
                    </Form.Group>

                    <Button className="w-100 pi-primary-btn" disabled={isSubmitting} type="submit">
                        Save
                    </Button>
                </Form>
            )}
        />
    )
};

export default PasswordResetForm;