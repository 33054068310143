import * as React from 'react';

import { SidebarMenuProps } from '../../Types/Shared';

import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useRouteMatch } from 'react-router-dom';
import {
    faChevronCircleLeft,
    faSeedling,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import IconButton from '../BasicComponents/IconButton';
import { Badge } from 'react-bootstrap';

function SidebarMenu(props: SidebarMenuProps): React.ReactElement {
    const [activeId, setActiveId] = useState<number>(0);
    const { url } = useRouteMatch();

    React.useEffect(() => {
        props.menuItems.forEach((group) => {
            group.forEach((item) => {
                if (window.location.pathname.includes(item.route)) {
                    setActiveId(item.id);
                }
            });
        });
    }, [props.menuItems]);

    return (
        <div className="d-flex flex-column justify-content-between h-100 flex-grow-1 border-right shadow-sm pi-bg-color-secondary">
            <div>
                <a
                    className="d-flex align-items-center justify-content-between pl-3 pt-2 mb-3"
                    href="/dashboard">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                            height={28}
                            className="pi-color-primary pi-font-size-22"
                            icon={faSeedling}
                        />
                        <div className="ml-2 pi-color-primary pi-font-size-24 pi-montserrat font-weight-bold">
                            PeerInvest
                        </div>
                    </div>

                    <div className="d-block d-md-none">
                        <IconButton
                            classes="pi-color-medium-gray mr-2 mr-md-3 fa-button"
                            onClick={props.onSidebarToggle}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                        </IconButton>
                    </div>
                </a>
                <ListGroup variant="flush">
                    {props.menuItems[0].map((item) => (
                        <Link
                            to={`${url}${item.route}`}
                            onClick={(): void => {
                                props.onSidebarToggle();
                                setActiveId(item.id);
                            }}
                            key={item.id}>
                            <ListGroup.Item
                                className={
                                    'border-0 d-flex align-items-center ' +
                                    (item.id === activeId
                                        ? 'pi-bg-color-secondary-tint text-white'
                                        : 'pi-bg-color-secondary pi-color-medium-gray')
                                }
                                key={item.text}
                                action>
                                <div style={{ flex: '0 0 20%' }}>
                                    <FontAwesomeIcon
                                        className="pi-font-size-18"
                                        icon={item.icon}
                                    />
                                </div>
                                <span className="pi-montserrat pi-font-size-14">
                                    {item.text}
                                </span>
                            </ListGroup.Item>
                        </Link>
                    ))}

                    {props.menuItems[1] && (
                        <ListGroup.Item
                            className="border-0 d-flex align-items-center pi-bg-color-secondary pi-color-medium-gray mt-4 pb-0"
                            action>
                            <span className="pi-montserrat pi-font-size-12 text-uppercase">
                                Marketplace
                            </span>
                        </ListGroup.Item>
                    )}

                    {props.menuItems[1] &&
                        props.menuItems[1].map((item) => (
                            <Link
                                to={`${url}${item.route}`}
                                onClick={(): void => {
                                    props.onSidebarToggle();
                                    setActiveId(item.id);
                                }}
                                key={item.id}>
                                <ListGroup.Item
                                    className={
                                        'border-0 d-flex align-items-center justify-content-between ' +
                                        (item.id === activeId
                                            ? 'pi-bg-color-secondary-tint text-white'
                                            : 'pi-bg-color-secondary pi-color-medium-gray')
                                    }
                                    key={item.text}
                                    action>
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ flex: '0 0 80%' }}>
                                        <div style={{ flex: '0 0 25%' }}>
                                            <FontAwesomeIcon
                                                className="pi-font-size-18"
                                                icon={item.icon}
                                            />
                                        </div>
                                        <span className="pi-montserrat pi-font-size-14">
                                            {item.text}
                                        </span>
                                    </div>

                                    <Badge
                                        pill
                                        variant="primary"
                                        style={{ flex: '0 0 15%' }}
                                        className="pi-bg-color-accent-primary shadow-sm pi-font-size-14 px-3 pi-montserrat">
                                        {props.propertyCount}
                                    </Badge>
                                </ListGroup.Item>
                            </Link>
                        ))}

                    {props.menuItems[2] && (
                        <ListGroup.Item
                            className="border-0 d-flex align-items-center pi-bg-color-secondary pi-color-medium-gray mt-4 pb-0"
                            action>
                            <span className="pi-montserrat pi-font-size-12 text-uppercase">
                                Account
                            </span>
                        </ListGroup.Item>
                    )}

                    {props.menuItems[2] &&
                        props.menuItems[2].map((item) => (
                            <Link
                                to={`${url}${item.route}`}
                                onClick={(): void => {
                                    props.onSidebarToggle();
                                    setActiveId(item.id);
                                }}
                                key={item.id}>
                                <ListGroup.Item
                                    className={
                                        'border-0 d-flex align-items-center ' +
                                        (item.id === activeId
                                            ? 'pi-bg-color-secondary-tint text-white'
                                            : 'pi-bg-color-secondary pi-color-medium-gray')
                                    }
                                    key={item.text}
                                    action>
                                    <div style={{ flex: '0 0 20%' }}>
                                        <FontAwesomeIcon
                                            className="pi-font-size-18"
                                            icon={item.icon}
                                        />
                                    </div>
                                    <span className="pi-montserrat pi-font-size-14">
                                        {item.text}
                                    </span>
                                </ListGroup.Item>
                            </Link>
                        ))}
                </ListGroup>
            </div>

            <div className="pi-border-top p-3">
                <h2 className="pi-color-medium-gray pi-font-size-16 font-weight-light pi-montserrat">
                    Contact Us
                </h2>

                <div className="mb-2">
                    <span className="font-weight-light pi-montserrat pi-font-size-14 pi-color-medium-gray mr-2">
                        Email:
                    </span>
                    <a
                        className="pi-montserrat pi-color-medium-gray pi-font-size-14"
                        href="mailto:support@peerinvest.io">
                        support@peerinvest.io
                    </a>
                </div>

                <div>
                    <span className="mt-4 font-weight-light pi-montserrat pi-font-size-14 pi-color-medium-gray mr-2">
                        Phone:
                    </span>
                    <a
                        className="pi-montserrat pi-color-medium-gray pi-font-size-14"
                        href="phone:2027431641">
                        (202) 743-1641
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SidebarMenu;
