import * as React from 'react';
import { ReactElement, useState } from 'react';

import HomeNav from '../../Shared/HomeNav/HomeNav';
import SignUpForm from '../../Shared/Forms/SignUpForm';
import { errorMap } from '../../Services/Errors/ErrorMap';
import {
    SignUpFormBody,
    SignUpFormValues,
    SignUpRedirects,
} from '../../Types/Features';
import { StoreState } from '../../Types/Store';
import { ActionTypes } from '../../Store/Actions';
import { checkNewUserToken, signUp } from '../../Services/Auth/Auth';
import './Styles/SignUp.css';

import Button from 'react-bootstrap/Button';

import { Redirect, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { useCookies } from 'react-cookie';

function SignUp(): ReactElement {
    const [redirectTo, setRedirectTo] = useState<SignUpRedirects>();
    const [values, setValues] = useState({
        email: '',
        isAccredited: false,
        firstName: '',
        lastName: '',
        password: '',
    });
    const [accountCreationErrorMessage, setAccountCreationErrorMessage] =
        useState<string>('');
    const [notAuthorized, setNotAuthorized] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cookies, setCookie] = useCookies(['authCookie']);
    const dispatch = useDispatch();
    const history = useHistory();
    parseReferralCode()

    function handleSubmit(formValues: SignUpFormValues): void {
        setAccountCreationErrorMessage('');
        if (formValues.isAccredited) {
            setValues(formValues);
            setAccountCreationErrorMessage('');
            createAccount(formValues);
        } else {
            setIsLoading(true);
            setValues(formValues);
            setAccountCreationErrorMessage('');
            createAccount(formValues);
        }
    }

    function parseReferralCode(): string{
        let url = window.location.href
        let code = ""
        if (url.includes("id?=")){
            code = url.substring(url.lastIndexOf('id?=') + 4);
        }
        return code
    }

    function createAccount(formValues: SignUpFormValues): void {
        const data: SignUpFormBody = {
            email: formValues.email,
            password: formValues.password,
            username: formValues.email,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            is_accredited: formValues.isAccredited,
            referral: parseReferralCode(),
        };

        signUp(data)
            .then((res) => {
                setCookie('authCookie', res.data.token, {
                    path: '/',
                    maxAge: 10000,
                });
                dispatch({
                    type: ActionTypes.SET_COOKIES,
                    newCookies: cookies,
                });
                dispatch({
                    type: ActionTypes.SET_USER_INFO,
                    newUserInfo: res.data.user,
                });
                dispatch({
                    type: ActionTypes.SET_IS_AUTHENTICATED,
                    newIsAuthenticated: true,
                });

                setAccountCreationErrorMessage('');
                history.push('/dashboard');
                setIsLoading(false);
            })
            .catch((err) => {
                if (err && err.response) {
                    const errorCode = err.response.status;
                    let errorMessage;

                    switch (errorCode) {
                        case 400:
                            if (
                                err.response.data.email?.includes(
                                    'user with this email already exists.',
                                )
                            ) {
                                errorMessage = errorMap.userAlreadyExists;
                            } else {
                                errorMessage = errorMap.default;
                            }
                            break;
                        case 500:
                            errorMessage = errorMap.internalServerError;
                            break;
                        default:
                            errorMessage = errorMap.default;
                    }

                    setAccountCreationErrorMessage(errorMessage);
                    setIsLoading(false);
                }
            });
    }

    function handleNavigate(route: SignUpRedirects): void {
        setRedirectTo(route);
    }

    function handleClose(): void {
        setShowModal(false);
    }

    function handleShow(): void {
        setShowModal(true);
    }

    if (redirectTo === SignUpRedirects.TO_LOG_IN) {
        return <Redirect to="/auth/login" />;
    }

    return (
        <div className="min-vh-100">
            <div className="container px-0">
                <HomeNav />

                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Accreditation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            By checking this box you represent that you qualify
                            as an &quot;accredited investor&quot; under
                            <a
                                className="ml-1"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3">
                                SEC Rule 501
                            </a>
                            . This means you either:
                        </div>
                        <ul>
                            <li>
                                have a net worth in excess of $1,000,000, not
                                including your primary residence
                            </li>
                            <li>
                                you (or you and your spouse) have previously
                                made, in each of the prior two years, and expect
                                to make this calendar year, more than $200,000
                                ($300,000 if with the spouse)
                            </li>
                            <li>
                                you are an entity meeting another test under the
                                rule.
                            </li>
                        </ul>
                    </Modal.Body>
                </Modal>

                <div className="container">
                    <div className="row justify-content-center mt-4 mt-lg-5">
                        <div className="col-12 col-md-5 col-lg-4 mt-0 mt-lg-5">
                            <h1 className="d-none d-lg-block display-4 pi-font-size-32 text-center mb-4">
                                Create a PeerInvest Account
                            </h1>
                            <h1 className="d-block d-lg-none display-4 pi-font-size-28 text-center mb-4">
                                Create a PeerInvest Account
                            </h1>

                            {accountCreationErrorMessage && (
                                <p
                                    className={
                                        'pi-montserrat text-center ' +
                                        (notAuthorized
                                            ? 'pi-color-primary-gray'
                                            : 'text-danger')
                                    }>
                                    {accountCreationErrorMessage}
                                </p>
                            )}

                            {isLoading && (
                                <div className="text-center mt-5">
                                    <Spinner
                                        className="pi-color-primary spinner-size"
                                        animation="border"
                                    />
                                </div>
                            )}

                            {!isLoading && (
                                <div>
                                    <SignUpForm
                                        handleSignUp={handleSubmit}
                                        handleIconClick={handleShow}
                                        values={{
                                            email: values.email,
                                            password: values.password,
                                            firstName: values.firstName,
                                            lastName: values.lastName,
                                            isAccredited: values.isAccredited,
                                        }}
                                    />

                                    <div className="text-center mt-2 mb-4 mb-lg-3">
                                        <Button
                                            variant="link"
                                            onClick={(): void =>
                                                handleNavigate(
                                                    SignUpRedirects.TO_LOG_IN,
                                                )
                                            }
                                            className="pi-font-size-16 pi-montserrat">
                                            Already have an account? Log in.
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
