import * as React from 'react';

import { TransferModalProps } from '../../Types/Shared';
import TransferBalanceForm from '../Forms/TransferBalanceForm';

import {
    getDwollaFundingSource,
    transactMoney,
} from '../../Services/Banking/BankingServices';
import { checkDwollaVerificationStatus } from '../../Services/Auth/Auth';

function TransferModal(props: TransferModalProps): React.ReactElement {
    const [accounts, setAccounts] = React.useState<string[]>([]);
    const [isLoadingAccounts, setIsLoadingAccounts] =
        React.useState<boolean>(false);
    const [transferMessage, setTransferMessage] = React.useState<string>('');

    function submitTransfer(values: any): void {
        if (values.action === 'withdraw') {
            transactMoney(values.account, 'WD', values.amount);
        } else if (values.action === 'deposit') {
            if (values.account === 'Balance') {
                console.log('Can not use Balance as a deposit source.');
                setTransferMessage('Can not use Balance as a deposit source.');
                return;
            }
            transactMoney(values.account, 'DP', values.amount)
                .then((res) => {
                    setTransferMessage('Transfer initiated');
                })
                .catch((err) => {
                    setTransferMessage('Transfer failed');
                });
        } else {
            console.log('Invalid action: ' + values.action);
        }
    }

    function handleNewAccounts(values: any): void {
        setAccounts(accounts);
    }

    function checkVerificationStatus(): void {
        checkDwollaVerificationStatus().catch(() => {
            // Let's add some error handling here...
        });
    }

    React.useEffect(() => {
        setIsLoadingAccounts(true);
        checkVerificationStatus();
        getDwollaFundingSource('True')
            .then((res) => {
                console.log(res.data);
                if (res.data && res.data.length) {
                    let newAccounts: string[] = [];
                    res.data.forEach((acc) => {
                        if (acc !== 'Balance') {
                            newAccounts.push(acc);
                        }
                    });
                    setAccounts(newAccounts);
                }
                setIsLoadingAccounts(false);
            })
            .catch(() => {
                setIsLoadingAccounts(false);
            });
    }, []);

    return (
        <div>
            <TransferBalanceForm
                handleSubmit={submitTransfer}
                handleNewAccounts={handleNewAccounts}
                accounts={accounts}
                cashBalance={props.cashBalance}
            />
            <p className="text-white pi-font-size-14">{transferMessage}</p>
        </div>
    );
}

export default TransferModal;
