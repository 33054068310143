import * as React from 'react';

import { CustomToggleProps } from '../../Types/Shared';

const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
        <button
            className={
                'border-0 bg-transparent ' +
                (props.classes || 'text-white fa-button')
            }
            onClick={props.onClick}
            ref={ref}>
            {props.children}
        </button>
    ),
);

export default CustomToggle;
