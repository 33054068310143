import * as React from 'react';

// import {ReferProps, ReferState } from '../../../Types/Features';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from '../../../Shared/BasicComponents/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

import { getReferralCode } from '../../../Services/Users/UserServices'
import { ReferState } from '../../../Types/Features';

class Refer extends React.Component < {}, ReferState > {
    constructor(props: any) {
        super(props);
        this.state = {
            referralCode: "https://www.peerinvest.io/auth/signup/"
        };
        this.getReferralCode = this.getReferralCode.bind(this);
    }

    componentDidMount(): void {
        this.getReferralCode()
    }

    getReferralCode(): void {
        getReferralCode()
            .then((res) => {
                console.log(res)
                let url = "https://portal.peerinvest.io/auth/signup/id?=" + res.data
                this.setState({
                    referralCode: url,
                });
            })
            .catch(() => {
                this.setState({
                    referralCode: "Not Found",
                });
            });
    }

    render(): React.ReactElement {
        return (
            <div className="container-fluid py-3">
                <div className="row h-100">
                    <div className="col-12 h-100">
                        <div className="card h-100 pl-3 pr-3">
                            <div className="row h-100">
                                <div className="col-12 h-100 pt-3 pb-3 px-0">
                                    <h1 className="pi-font-size-20 px-3">
                                        Refer a friend
                                    </h1>
                                    <p className="px-3">You and a friend will both earn a free
                                    share after they make their first trade.</p>
                                    <p className="px-3" onClick={() => {navigator.clipboard.writeText(this.state.referralCode)}}>
                                        <b>Share Your Referral Link: </b> 
                                        <a>{this.state.referralCode}</a>
                                        <span className="px-1">
                                        <FontAwesomeIcon icon={faClone}/>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            	</div>
            </div>
        );
    }
}

export default Refer;