import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/AccountOverview.css';
import { currency } from '../../../Shared/Pipes/Currency';
import { date } from '../../../Shared/Pipes/Date';
import Pie from '../../../Shared/Charts/Pie';
import ErrorContainer from '../../../Shared/BasicComponents/ErrorContainer';
import { getPortfolio } from '../../../Services/Properties/Properties';
import {
    AccountOverviewProps,
    AccountOverviewState,
} from '../../../Types/Features';
import {
    Position,
    Property,
    Transaction,
    TransactionType,
    Update,
} from '../../../Types/Data';
import { NewUpdates, StoreState } from '../../../Types/Store';
import {
    consolidateTransactions,
    dividendTransactions,
} from '../../../Utils/Transactions';

import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';

import { flatMap, sumBy } from 'lodash';

import { connect } from 'react-redux';

import { DefaultRawDatum } from '@nivo/pie';
import { getAllDividends, getTotalGainLoss } from '../../../Utils/Calculations';
import { percent } from '../../../Shared/Pipes/Percent';
import PortfolioBalanceChart from '../Portfolio/PortfolioBalanceChart';
import Bar from '../../../Shared/Charts/Bar';
import { barDataManipulator } from '../../../Utils/ChartData';

class AccountOverview extends React.Component<
    AccountOverviewProps,
    AccountOverviewState
> {
    constructor(props: AccountOverviewProps) {
        super(props);
        this.state = {
            toNewsAndUpdates: false,
            error: false,
            isLoading: true,
        };
    }

    componentDidMount(): void {
        if (
            this.props.llcs &&
            this.props.llcs.length > 0 &&
            this.props.updates &&
            this.props.updates.length === 0
        ) {
            this.callGetPortfolio();
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<AccountOverviewProps>): void {
        if (
            this.props.llcs &&
            this.props.llcs.length !== prevProps.llcs.length
        ) {
            this.callGetPortfolio();
        }
    }

    callGetPortfolio(): void {
        getPortfolio(this.props.llcs)
            .then((res) => {
                if (res.data.length > 0) {
                    let updates = flatMap(res.data, (pos) => pos.updates);
                    updates.sort((a, b) => {
                        const aDate = new Date(a.created_at);
                        const bDate = new Date(b.created_at);
                        if (aDate < bDate) {
                            return 1;
                        } else if (bDate < aDate) {
                            return -1;
                        }
                        return 0;
                    });
                    this.props.setUpdates(updates);
                }

                this.setState({
                    isLoading: false,
                });
            })
            .catch(() => {
                this.setState({
                    error: true,
                    isLoading: false,
                });
            });
    }

    sortTransactions(transactions: Transaction[]): Transaction[] {
        const temp = transactions.sort((a, b) => {
            const aDate = new Date(a.transact_date);
            const bDate = new Date(b.transact_date);
            if (aDate < bDate) {
                return 1;
            } else if (bDate < aDate) {
                return -1;
            }
            return 0;
        });
        return temp;
    }

    pieChartDataFormatter(pos: Position): DefaultRawDatum {
        return {
            id: pos.asset.llc,
            value: pos.asset['market_price'] * pos['num_shares'],
        };
    }

    renderEquitySection(
        llcs: number[],
        pieData: DefaultRawDatum[],
    ): ReactElement {
        if (llcs && llcs.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <p>You have no open positions.</p>
                </div>
            );
        } else {
            return <Pie data={pieData} />;
        }
    }

    getStyledPercent(percentage: number): ReactElement {
        let classes = 'pi-color-primary-gray';
        let sign = '';
        if (percentage > 0) {
            classes = 'text-success';
            sign = '+';
        } else if (percentage < 0) {
            classes = 'text-danger';
            sign = '-';
        }
        return (
            <span className={classes}>
                {sign + percent(Math.abs(percentage), 2)}
            </span>
        );
    }

    render(): ReactElement {
        if (this.state.isLoading) {
            return (
                <div className="d-flex h-100 justify-content-center">
                    <Spinner
                        className="align-self-center"
                        animation="border"
                        variant="dark"
                    />
                </div>
            );
        }

        const totalEquity = sumBy(
            this.props.investmentProfile.positions,
            (pos) => pos['num_shares'] * pos.asset['market_price'],
        );
        const transactions = dividendTransactions(
            consolidateTransactions(this.props.investmentProfile.positions),
        );

        const limitedUpdates =
            this.props.updates && this.props.updates.length > 3
                ? this.props.updates.slice(0, 3)
                : this.props.updates;

        if (this.state.error) {
            return <ErrorContainer />;
        }

        return (
            <div className="container-fluid mt-3">
                <div className="row mb-4 pb-3">
                    <div className="col-12">
                        <h2 className="pi-montserrat pi-font-size-22">
                            Overview
                        </h2>
                    </div>
                </div>

                <div className="row mb-4 pb-3">
                    <div className="col-12 col-lg-8 mb-4 mb-lg-0">
                        <div className="d-flex w-100 mb-2 mb-lg-4 pb-3 overflow-auto">
                            <div className=" flex-fill border rounded shadow px-4 py-3 mr-4">
                                <h3 className="pi-montserrat pi-font-size-14 pi-color-primary-gray font-weight-light">
                                    Portfolio Value
                                </h3>

                                <div>
                                    <span className="pi-font-size-24 pi-montserrat mr-3">
                                        {currency(totalEquity, 0)}
                                    </span>
                                    <span>
                                        {this.getStyledPercent(
                                            getTotalGainLoss(
                                                this.props.investmentProfile
                                                    .positions,
                                            ) / totalEquity,
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="flex-fill border rounded shadow px-4 py-3 mr-4">
                                <h3 className="pi-montserrat pi-font-size-14 pi-color-primary-gray font-weight-light">
                                    Total Dividends
                                </h3>

                                <div>
                                    <span className="pi-font-size-24 pi-montserrat mr-3">
                                        {currency(
                                            getAllDividends(
                                                this.props.investmentProfile
                                                    .positions,
                                            ),
                                            2,
                                        )}
                                    </span>
                                    <span className="text-success">
                                        {percent(
                                            getAllDividends(
                                                this.props.investmentProfile
                                                    .positions,
                                            ) / totalEquity,
                                            2,
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="flex-fill border rounded shadow px-4 py-3">
                                <h3 className="pi-montserrat pi-font-size-14 pi-color-primary-gray font-weight-light">
                                    Properties Owned
                                </h3>

                                <div>
                                    <span className="pi-font-size-24 pi-montserrat mr-3">
                                        {
                                            this.props.investmentProfile.positions.filter(
                                                (x) =>
                                                    x.asset.symbol !== 'cash',
                                            ).length
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex-fill border rounded shadow px-4 py-3">
                            <PortfolioBalanceChart />
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 flex-fill">
                        <div className="flex-fill border rounded shadow pt-3 h-100 d-flex flex-column justify-content-between">
                            <div>
                                <h1 className="pi-font-size-20 pi-montserrat px-4">
                                    Recent Transactions
                                </h1>

                                <ListGroup variant="flush">
                                    {this.sortTransactions(transactions)
                                        .filter(
                                            (t) =>
                                                t.status.toLowerCase() !== 'cn',
                                        )
                                        .slice(0, 5)
                                        .map((transaction: Transaction) => (
                                            <ListGroup.Item
                                                className="px-4"
                                                key={transaction.id}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: 50,
                                                                height: 50,
                                                            }}
                                                            className="pi-bg-color-secondary-gray d-flex align-items-center justify-content-center mr-3 rounded-circle">
                                                            {
                                                                this.props.investmentProfile.positions.find(
                                                                    (x) =>
                                                                        x.transactions.find(
                                                                            (
                                                                                y,
                                                                            ) =>
                                                                                y.id ===
                                                                                transaction.id,
                                                                        ) !=
                                                                        undefined,
                                                                )?.asset.symbol
                                                            }
                                                        </div>
                                                        <div>
                                                            <h3 className="pi-font-size-14 pi-montserrat">
                                                                {
                                                                    transaction.transaction_type
                                                                }
                                                            </h3>
                                                            <p className="font-italic pi-font-size-12 mb-0">
                                                                {date(
                                                                    transaction.transact_date,
                                                                    'mm/dd/yyyy',
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {currency(
                                                            transaction.num_shares *
                                                                transaction.cost,
                                                            2,
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                </ListGroup>
                            </div>

                            <div className="d-flex align-items-stretch border-top pi-bg-color-primary rounded-bottom">
                                <a
                                    href="/dashboard/transactions"
                                    className="pi-montserrat pi-color-light-gray flex-grow-1 py-3 px-4 pi-font-size-18 text-center">
                                    See All
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4 pb-3">
                    <div className="col-12 col-lg-4">
                        <div className="border rounded shadow pt-3">
                            <h1 className="pi-font-size-20 pi-montserrat px-4">
                                Recent Updates
                            </h1>
                            <ListGroup variant="flush">
                                {limitedUpdates.map((update: Update) => (
                                    <ListGroup.Item
                                        className="px-4 "
                                        key={update.id}>
                                        <h3 className="pi-font-size-14 pi-montserrat">
                                            {update.title}
                                        </h3>
                                        <p className="pi-font-size-12 pi-color-primary-gray">
                                            {update.description.substring(
                                                0,
                                                100,
                                            ) +
                                                (update.description.length > 100
                                                    ? '...'
                                                    : '')}
                                        </p>
                                        <p className="font-italic pi-font-size-12 mb-0">
                                            {date(
                                                update['created_at'],
                                                'mm/dd/yyyy',
                                            )}
                                        </p>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <div className="d-flex align-items-stretch border-top pi-bg-color-primary rounded-bottom">
                                <a
                                    href="/dashboard/news-and-updates"
                                    className="pi-montserrat pi-color-light-gray flex-grow-1 py-3 px-4 pi-font-size-18 text-center">
                                    See All
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-8 flex-fill mt-4 mt-lg-0">
                        <div className="flex-fill border rounded shadow py-3 h-100 d-flex flex-column">
                            <h1 className="pi-font-size-20 pi-montserrat px-4">
                                Dividends
                            </h1>

                            <div style={{ width: '100%', height: '20rem' }}>
                                <Bar data={barDataManipulator(transactions)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: StoreState,
): { updates: Update[]; properties: Property[] } => {
    return {
        updates: state.updates,
        properties: state.properties,
    };
};

const mapDispatchToProps = (dispatch: any): { setUpdates: Function } => {
    return {
        setUpdates: (updates: NewUpdates): any =>
            dispatch({ type: 'SET_UPDATES', newUpdates: updates }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOverview);
