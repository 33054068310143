import axios, { AxiosResponse } from 'axios';

export function resendVerificationEmail(): Promise<AxiosResponse<string>> {
    return axios.get(process.env.REACT_APP_API_URL + 'support/resend_verification_email')
}

export function favoriteProperty(llc: number): Promise<AxiosResponse<string>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/favorite_property/',
        {
            llc: llc
        });
}

export function deleteFavoriteProperty(llc: number): Promise<AxiosResponse<string>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/delete_favorite_property/',
        {
            llc: llc
        });
}

export function getReferralCode(): Promise<AxiosResponse<string>> {
    return axios.get(`${process.env.REACT_APP_API_URL}referrals/referral_code/`);
}
