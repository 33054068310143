import * as React from 'react';

import '../Styles/PropertyView.css';
import '../Styles/PropertyDetails.css'
import { PropertyOverviewProps } from "../../../../Types/Features";
import PropertyMap from "./PropertyMap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
    faMapMarkedAlt,
    faStar as fasStar,
    faStarHalfAlt,
    faSchool,
    faAngleDown,
    faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

import { Button } from 'react-bootstrap';

function PropertyOverview(props: PropertyOverviewProps): React.ReactElement {
    const [occupiedCount, setOccupiedCount] = React.useState<number>();
    const [showMore, setShowMore] = React.useState<boolean>(false);

    function numberOfOccupiedUnits(): void {
        let count = 0;
        props.property.units.forEach(unit => {
            if (unit.rent > 0) {
                count++
            }
        });
        setOccupiedCount(count)
    }

    React.useEffect(() => {
        numberOfOccupiedUnits();
    });

    function propertyType(): string {
        switch (props.property.units.length) {
            case 0:
                return 'N/A';
            case 1:
                return 'Single Family';
            case 2:
                return 'Duplex';
            case 3:
                return 'Triplex';
            case 4:
                return 'Quadplex';
            default:
                return 'Commercial';
        }
    }

    function starIcon(index: number): IconDefinition {
        const comparator = props.property.schools_rating - index - 1;
        if (comparator > 0) {
            return fasStar;
        } else if (comparator === -0.5) {
            return faStarHalfAlt;
        } else {
            return farStar;
        }
    }

    return (
        <div className="rounded border pi-top-border shadow-sm p-3">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-4">
                Overview
            </h2>

            <div className="row">
                <div className="col-12 col-lg-6 border-right">
                    <div className="row">
                        <div className="col-12 mb-2">
                            <h3 className="pi-font-size-16">Description</h3>
                            <p className="pi-color-primary-gray text-justify mb-0">
                                {showMore ?  props.property.description : props.property.description.substring(0, 200)}
                            </p>
                            <Button
                                variant="link"
                                className="p-0 d-flex align-items-center pi-no-underline"
                                onClick={(): void => setShowMore(!showMore)}
                            >
                                {showMore ? 'Show Less' : 'Show More'}
                                <FontAwesomeIcon className="ml-2" icon={showMore ? faAngleUp : faAngleDown}/>
                            </Button>
                        </div>

                        <div className="col-6">
                            <h3 className="pi-font-size-16">Occupancy</h3>
                            <p className="pi-color-primary-gray">
                                {occupiedCount ? (`Occupied (${occupiedCount}/${props.property.units.length})`) : 'Vacant'}
                            </p>

                            <h3 className="pi-font-size-16">Property Type</h3>
                            <p className="pi-color-primary-gray">
                                {propertyType()}
                            </p>
                        </div>

                        <div className="col-6">
                            <h3 className="pi-font-size-16">Lot Size</h3>
                            <p className="pi-color-primary-gray">
                                {props.property.lot_size} sq-ft
                            </p>

                            <h3 className="pi-font-size-16">Year Built</h3>
                            <p className="pi-color-primary-gray">
                                {props.property.year_built}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="pi-google-map-container mb-3">
                        <PropertyMap lat={props.property.latitude} lng={props.property.longitude} symbol={props.symbol} />
                    </div>

                    <div className="d-flex mb-2">
                        <div className="d-flex rounded align-items-center border py-2 px-3">
                            <FontAwesomeIcon className="pi-color-primary-gray pi-font-size-32" icon={faMapMarkedAlt} />
                            <div className="ml-3 mr-5">
                                <h4 className="pi-font-size-16 font-weight-lighter pi-color-primary-gray mb-1">
                                    Neighborhood
                                </h4>
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={fasStar} />
                                <FontAwesomeIcon
                                    className="pi-color-primary pi-font-size-20"
                                    icon={props.property.property_class <= 'D' ? fasStar : farStar}/>
                                <FontAwesomeIcon
                                    className="pi-color-primary pi-font-size-20"
                                    icon={props.property.property_class <= 'C' ? fasStar : farStar}/>
                                <FontAwesomeIcon
                                    className="pi-color-primary pi-font-size-20"
                                    icon={props.property.property_class <= 'B' ? fasStar : farStar}/>
                                <FontAwesomeIcon
                                    className="pi-color-primary pi-font-size-20"
                                    icon={props.property.property_class <= 'A' ? fasStar : farStar}/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="d-flex rounded align-items-center border py-2 px-3">
                            <FontAwesomeIcon className="pi-color-primary-gray pi-font-size-32" icon={faSchool} />
                            <div className="ml-3 mr-5">
                                <h4 className="pi-font-size-16 font-weight-lighter pi-color-primary-gray mb-1">
                                    Schools
                                </h4>
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={starIcon(0)} />
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={starIcon(1)} />
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={starIcon(2)} />
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={starIcon(3)} />
                                <FontAwesomeIcon className="pi-color-primary pi-font-size-20" icon={starIcon(4)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyOverview;