import * as React from 'react';
import { ReactElement } from 'react';

import { HomeNavProps, HomeNavState } from '../../Types/Shared';
import './Styles/HomeNav.css';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { Nav } from 'react-bootstrap';

class HomeNav extends React.Component<HomeNavProps, HomeNavState> {
    constructor(props: HomeNavProps) {
        super(props);
        this.state = {
            toLogIn: false,
            toSignUp: false,
        };

        this.handleNavigate = this.handleNavigate.bind(this);
    }

    handleNavigate(route: string): void {
        this.setState({
            [route]: true,
        });
    }

    render(): ReactElement {
        const url = window.location.href;

        if (this.state.toLogIn && !url.includes('/login')) {
            return <Redirect to="/auth/login" />;
        }

        if (this.state.toSignUp && !url.includes('/signup')) {
            return <Redirect to="/auth/signup" />;
        }

        return (
            <div className="px-3 px-lg-0">
                <Navbar
                    collapseOnSelect
                    expand="lg"
                    variant="light"
                    className={
                        'pt-3 px-0 ' + (this.props.root ? 'bg-transparent' : '')
                    }>
                    <Navbar.Brand
                        href="https://peerinvest.io"
                        className="d-flex align-items-center"
                        style={{ flex: '0 0 17%' }}>
                        <FontAwesomeIcon
                            height={28}
                            className="pi-color-secondary pi-font-size-28"
                            icon={faSeedling}
                        />
                        <div className="ml-2 pi-color-secondary pi-font-size-32 pi-montserrat font-weight-bold">
                            PeerInvest
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="w-100 d-block d-lg-flex justify-content-between align-items-center">
                            <div className="d-block d-lg-flex flex-grow-1 justify-content-center">
                                <Nav.Link
                                    href="https://peerinvest.io/support/blog"
                                    className="mx-2 pi-color-secondary pi-weight-500 pi-montserrat">
                                    Blog
                                </Nav.Link>
                                <Nav.Link
                                    href="https://peerinvest.io/support/faq"
                                    className="mx-2 pi-color-secondary pi-weight-500 pi-montserrat">
                                    FAQ
                                </Nav.Link>
                                <Nav.Link
                                    href="https://peerinvest.io/support/request"
                                    className="mx-2 pi-color-secondary pi-weight-500 pi-montserrat">
                                    Contact
                                </Nav.Link>
                            </div>

                            <div>
                                <Button
                                    variant="outline-primary"
                                    onClick={(): void =>
                                        this.handleNavigate('toLogIn')
                                    }
                                    className="ml-0 ml-lg-4 pi-primary-outline-btn pi-montserrat py-2 py-lg-3 col-12 col-lg-auto mt-2 mt-lg-0 px-4 pi-weight-500">
                                    Log in
                                </Button>

                                <div className="d-block d-lg-inline">
                                    <Button
                                        className="ml-0 ml-lg-3 mt-3 mt-lg-0 pi-primary-btn pi-montserrat py-2 py-lg-3 col-12 col-lg-auto px-4 pi-weight-500"
                                        onClick={(): void =>
                                            this.handleNavigate('toSignUp')
                                        }>
                                        Get started
                                    </Button>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default HomeNav;
