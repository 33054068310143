import React, { Component, ReactElement } from 'react';
import SidebarMenu from '../../Shared/SidebarMenu/SidebarMenu';
import { AccountProps, AccountState } from '../../Types/Features';
import AccountRoutes from '../../Routes/AccountRoutes';
import { menuItems } from '../../Shared/DashboardNav/AccountMenuItems';
import DashboardNav from '../../Shared/DashboardNav/DashboardNav';
import { BREAKPOINTS } from '../../Utils/Breakpoints';

import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { StoreState } from '../../Types/Store';
import { UserInfo } from '../../Types/Data';
import Cookies from 'universal-cookie';

class Account extends Component<AccountProps, AccountState> {
    constructor(props: AccountProps) {
        super(props);
        this.state = {
            toLogin: false,
            sidebarActive: true,
            activeMenuItem: 'Personal Information',
        };

        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
        this.handleMobileSidebarToggle =
            this.handleMobileSidebarToggle.bind(this);
    }

    componentDidMount(): void {
        if (window.innerWidth < BREAKPOINTS.md) {
            this.setState({
                sidebarActive: false,
            });
        }
    }

    handleSidebarToggle(): void {
        this.setState({
            sidebarActive: !this.state.sidebarActive,
        });
    }

    handleMobileSidebarToggle(): void {
        if (window.innerWidth < BREAKPOINTS.md) {
            this.setState({
                sidebarActive: !this.state.sidebarActive,
            });
        }
    }

    render(): ReactElement {
        if (this.state.toLogin) {
            return <Redirect to={'/auth/login'} />;
        }

        const settings = {
            address1: this.props.userInfo.address1
                ? this.props.userInfo.address1
                : '',
            address2: this.props.userInfo.address2
                ? this.props.userInfo.address2
                : '',
            city: this.props.userInfo.city ? this.props.userInfo.city : '',
            state: this.props.userInfo.state ? this.props.userInfo.state : '',
            zip: this.props.userInfo.zip
                ? this.props.userInfo.zip.toString()
                : '',
        };

        return (
            <div className="container-fluid d-flex flex-column px-0 pi-custom-container">
                <div className="row mx-0">
                    <div className="col-12 px-0">
                        <DashboardNav
                            onSidebarToggle={this.handleSidebarToggle}
                            hasMenu={true}
                        />
                    </div>
                </div>
                <div className="row flex-grow-1 mx-0">
                    {this.state.sidebarActive && (
                        <div className="col-12 col-md-3 col-lg-2 px-0">
                            <SidebarMenu
                                menuItems={menuItems}
                                onSidebarToggle={this.handleMobileSidebarToggle}
                                propertyCount={0}
                            />
                        </div>
                    )}
                    <div
                        className={
                            'px-0 ' +
                            (this.state.sidebarActive
                                ? 'd-none d-md-block col-md-9 col-lg-10'
                                : 'col-12')
                        }>
                        <div className="overflow-auto pi-scroll h-100">
                            <AccountRoutes
                                settings={settings}
                                email={this.props.userInfo.email}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: StoreState,
): { userInfo: UserInfo; cookies: Cookies } => {
    return {
        userInfo: state.userInfo,
        cookies: state.cookies,
    };
};

export default connect(mapStateToProps)(Account);
