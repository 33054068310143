import * as React from 'react';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import {ResetPwdFormProps} from "../../Types/Shared";


const ResetPwdForm = (props: ResetPwdFormProps): React.ReactElement => {
	return (
        <Formik
            initialValues={{
                email: ''
            }}
            onSubmit={(values, actions): void => {
                props.handlePwdReset(values);
                actions.setSubmitting(false);
            }}
            render={({
                 values,
                 handleSubmit,
                 handleChange,
                 isSubmitting
             }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            name="email"
                            type="text"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={values.email}
                        />
                    </Form.Group>

                    <Button className="w-100 pi-primary-btn" disabled={isSubmitting} type="submit">
                        Send Email
                    </Button>
                </Form>
            )}
        />
    )
};

export default ResetPwdForm;
