import { DwollaVerificationStatus, LogIn, UserInfo } from "../../Types/Data";
import { SignUpFormBody } from "../../Types/Features";
import { addToken, removeToken } from "../Utils/AuthUtils";

import axios, { AxiosResponse } from 'axios';

export function checkAuth(): Promise<AxiosResponse<UserInfo>> {
    return axios.get(process.env.REACT_APP_API_URL + 'users/current_user/');
}

export function logIn(email: string, password: string): Promise<AxiosResponse<LogIn>> {
    const token = removeToken();

    // We use email as username
    const request = axios.post(process.env.REACT_APP_API_URL + 'users/token_auth', {
        username: email,
        password: password
    });

    addToken(token);

    return request;
}

export function checkNewUserToken(first_name: string, last_name: string, email: string): Promise<AxiosResponse<string>> {
    const jToken = removeToken();

    const request =  axios.post(process.env.REACT_APP_API_URL + 'users/validate_user/', {
        first_name: first_name,
        last_name: last_name,
        email: email
    });

    addToken(jToken);

    return request;
}

export function signUp(signUpFormBody: SignUpFormBody): Promise<AxiosResponse<LogIn>> {
    const token = removeToken();

    const request = axios.post(process.env.REACT_APP_API_URL + 'users/users/', signUpFormBody);

    addToken(token);

    return request;
}

export function resetPwd(email: string): Promise<AxiosResponse<void>> {
    const token = removeToken();

    const request = axios.post(process.env.REACT_APP_API_URL + 'password_reset/', {
        email: email,
    });

    addToken(token);

    return request;
}

export function resetPwdSubmission(key: string, password: string): Promise<AxiosResponse<void>> {
    const token = removeToken();

    const request = axios.post(process.env.REACT_APP_API_URL + 'password_reset/confirm/', {
        token: key,
        password: password
    });

    addToken(token);

    return request;
}

export function checkDwollaVerificationStatus(): Promise<AxiosResponse<DwollaVerificationStatus>> {
    const request = axios.get(process.env.REACT_APP_API_URL + 'users/dwolla_verification_status');

    return request;
}

export function createDwollaAccount(values: {
    ssn: string;
    dob: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}): Promise<AxiosResponse<string>> {
    const request = axios.post(process.env.REACT_APP_API_URL + 'users/create_dwolla_account/', {
        ...values
    });

    return request;
}