import * as React from 'react';

import { PositionDetailProps } from '../../../Types/Features';
import { StoreState } from '../../../Types/Store';
import './Styles/PortfolioDetails.css';

import { useSelector } from 'react-redux';
import IconButton from '../../../Shared/BasicComponents/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import PropertyTimeline from '../PropertyDetails/PropertyView/PropertyTimeline';
import { currency } from '../../../Shared/Pipes/Currency';
import { lineDataManipulator } from '../../../Utils/ChartData';
import AccountBalance from '../../../Shared/Charts/AccountBalance';
import { Link } from 'react-router-dom';

function PositionDetail(props: PositionDetailProps): React.ReactElement {
    const property = useSelector((store: StoreState) =>
        store.properties.find(
            (property) => property.llc === props.position.asset.llc,
        ),
    );

    function getImageLocation(): string {
        if (property) {
            const image = property.images.find(
                (image) => image.classifier === 'hero',
            );
            return image ? image.location : '';
        }
        return '';
    }

    return (
        <div className="row">
            <div className="col-4 border-right">
                <div className="d-flex justify-content-between mb-3">
                    <h2 className="pi-font-size-16">Outstanding Shares</h2>
                    <p className="mb-0 pi-color-primary-gray">
                        {props.position.asset.total_shares -
                            props.position.asset.unsold_shares}
                    </p>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <h2 className="pi-font-size-16">Debt</h2>
                    <p className="mb-0 pi-color-primary-gray">
                        {property ? currency(property.debt, 0) : '--'}
                    </p>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <h2 className="pi-font-size-16">Equity</h2>
                    <p className="mb-0 pi-color-primary-gray">
                        {property ? currency(property.equity, 0) : '--'}
                    </p>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <h2 className="pi-font-size-16">Cash Reserves</h2>
                    <p className="mb-0 pi-color-primary-gray">
                        {property ? currency(property.cash, 0) : '--'}
                    </p>
                </div>

                <h2 className="pi-font-size-14 pi-color-primary-gray">
                    {props.position.asset.symbol} Share Price
                </h2>
                <div className="pi-line-container">
                    <AccountBalance
                        data={lineDataManipulator(
                            props.position.asset.asset_price_points,
                        )}
                        mouseOverHandler={(): void => {
                            return;
                        }}
                        min={0}
                        increment={1}
                    />
                </div>
            </div>

            <div className="col-4 border-right">
                <div className="mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <h1 className="pi-font-size-16 mb-1">
                            {property ? property.address1 : ''}
                        </h1>
                        {property && property.address2 ? (
                            <div className="pi-font-size-14">
                                {property.address2}
                            </div>
                        ) : null}
                        {property && (
                            <div className="pi-color-primary-gray pi-font-size-14">
                                {`${property.city}, ${property.state} ${property.zip}`}
                            </div>
                        )}
                    </div>

                    <Link
                        to={'/dashboard/property/' + props.position.asset.llc}>
                        <IconButton classes="bg-transparent p-0">
                            <div className="d-flex align-items-center">
                                <span className="pi-color-accent-primary mr-1 pi-montserrat">
                                    View
                                </span>
                                <FontAwesomeIcon
                                    className="pi-color-accent-primary"
                                    icon={faAngleRight}
                                />
                            </div>
                        </IconButton>
                    </Link>
                </div>
                <img
                    className="w-100 pi-image"
                    src={getImageLocation()}
                    alt="property of the position"
                />
            </div>

            <div className="col-4 pi-timeline-container">
                {property && (
                    <PropertyTimeline
                        updates={property.updates}
                        handleSelect={(): void => {
                            return;
                        }}
                        hideCard={true}
                    />
                )}
            </div>
        </div>
    );
}

export default PositionDetail;
