import * as React from 'react';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import {ResetPwdFormProps} from "../../Types/Shared";
import {object, ref, string} from "yup";

const ResetPwdForm2Schema = object().shape({
    password: string()
        .required('*Required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
        ),
    passwordConfirmation: string()
        .required('*Required')
        .oneOf([ref("password"), null], "Passwords must match"),
});

const ResetPwdForm2 = (props: ResetPwdFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirmation: ''
            }}
            validationSchema={ResetPwdForm2Schema}
            onSubmit={(values, actions): void => {
                props.handlePwdReset(values);
                actions.setSubmitting(false);
            }}
            render={({
                 values,
                 errors,
                 touched,
                 handleSubmit,
                 handleChange,
                 isSubmitting
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="resetPwdForm">
                        <Form.Label>
                            Password
                            {errors.password === '*Required' && touched.password ? (
                                <span className="text-danger ml-2">{errors.password}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Enter New Password"
                            onChange={handleChange}
                            value={values.password}
                        />
                        {errors.password !== '*Required' && touched.password ? (
                            <span className="text-danger">{errors.password}</span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="resetPwdForm">
                        <Form.Label>
                            Confirm Password
                            {errors.passwordConfirmation === '*Required' && touched.passwordConfirmation ? (
                                <span className="text-danger ml-2">{errors.passwordConfirmation}</span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            value={values.passwordConfirmation}
                        />
                        {errors.passwordConfirmation !== '*Required' && touched.passwordConfirmation ? (
                            <span className="text-danger">{errors.passwordConfirmation}</span>
                        ) : null}
                    </Form.Group>

                    <Button className="w-100 pi-primary-btn" disabled={isSubmitting} type="submit">
                        Submit
                    </Button>
                </Form>
            )}
        />
    )
};

export default ResetPwdForm2;