import * as React from "react";

import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";
import { PropertyMapProps } from "../../../../Types/Features";

function PropertyMap(props: PropertyMapProps): React.ReactElement {
    if (props.lng && props.lat) {
        return (
            <GoogleMapReact
                bootstrapURLKeys={{key: 'AIzaSyDxr3PlI36OjncfDFn0Mm4uXfSIDxL_McM'}}
                defaultCenter={{lat: props.lat, lng: props.lng}}
                defaultZoom={10}
            >
                <MapMarker />
            </GoogleMapReact>
        );
    }

    return <div></div>;
}

export default PropertyMap;