import * as React from "react";

import { PlaidSelectAccountModalProps } from "../../Types/Shared";
import { PlaidModalState } from "../../Types/Data";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Formik } from "formik";

import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function PlaidSelectAccountModal(props: PlaidSelectAccountModalProps): React.ReactElement {
        //const [accounts, setAccounts] = React.useState<string[]>([]);
        //const [isLoadingAccounts, setIsLoadingAccounts] = React.useState<boolean>(false);
	if (props.modalState == PlaidModalState.select){
	    return (
	    	<Formik
			initialValues={{
	            accountPicker: "",
	        }}
	        onSubmit={(values): void => {
	        	let array = values.accountPicker.split(",", 2);
	        	let account_id = array[0]
	        	let item_id = array[1]
	            props.handleAccountSubmit(account_id, item_id);
	        }}
	        render={({
	          		values,
	              	errors,
	          		handleSubmit,
	          		handleChange,
	              	isSubmitting
	          	}): React.ReactElement => ( 
	          		<Form onSubmit={handleSubmit}>
	          			<div className="d-flex justify-content-between align-items-center">
		          			<Form.Group className="pi-font-size-14 pi-border-color-primary bg-transparent text-white w-50" controlId="accountSelect">
		          				<Form.Label>Confirm an account to link</Form.Label>
		          				{props.accounts.map((institution: PlaidAccount[]) => (
		          					<div className="d-flex justify-content-between align-items-center py-2"
	                                    key={institution[0].account_id.substring(0,5,)}>
	                                    <div>
	                                    	{institution.map(
	                                    		(account: PlaidAccount,) => (
	                                    			<div key={[account.label, account.item_id]} className="mb-3">
												      <Form.Check 
												      	name="accountPicker"
												        type={'radio'}
												        id={account.label}
												        value={[account.label, account.item_id]}
												        label={account.label}
												        onChange={handleChange}
												      />
												    </div>
	                                    		)
	                                    	)}
	                                    </div>
	                                </div>)                            
	                            )}
		          			</Form.Group>
		          		</div>	         
		          		<Button className="pi-bg-color-primary border-0 w-100 py-3" type="submit">
			                Link Account
			            </Button> 		
	          		</Form>
	          	)}
		/>)
	} else if (props.modalState == PlaidModalState.error){
		return(
			<div className="text-center">
				<FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faExclamationTriangle} />
				<p className="text-white pi-font-size-14">
					An error occured while attempting to link your account.
					Please try again or contact support@peerinvest.io.
				</p>
			</div>

			)
	} else if (props.modalState == PlaidModalState.success){
		return(
			<div className="text-center">
				<FontAwesomeIcon className="pi-color-primary pi-font-size-24 mb-2" icon={faCheckCircle} />
				<p className="text-white pi-font-size-14">
					Your account has been successfully linked.
					You may now use it to transfer funds. 
				</p>
			</div>
			)
	}

}

export default PlaidSelectAccountModal;