import * as React from 'react';
import './Styles/Documents.css';
import { getDocument } from '../../../Services/Documents/Documents';
import ErrorContainer from '../../../Shared/BasicComponents/ErrorContainer';
import { DocumentsProps, DocumentState } from '../../../Types/Features';

import ListGroup from 'react-bootstrap/ListGroup';

import IconButton from '../../../Shared/BasicComponents/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class Documents extends React.Component<DocumentsProps, DocumentState> {
    constructor(props: DocumentsProps) {
        super(props);
        this.state = {
            numPages: undefined,
            pageNumber: 1,
            document: null,
            error: false,
            loading: false,
        };
    }

    getDocument(location: string, callback: Function): void {
        this.setState({
            loading: true,
        });

        getDocument(location)
            .then((res) => {
                this.setState({
                    document: res,
                    loading: false,
                });
                callback(res.data);
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    openDocument(data: any): void {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
    }

    downloadDocument(data: any): void {
        // @TODO: Implement this later when redoing UI
    }

    render(): React.ReactElement {
        if (this.state.error) {
            return <ErrorContainer />;
        }

        return (
            <div className="container-fluid py-3 h-100">
                <div className="row h-100">
                    <div className="col-12 h-100">
                        <div className="card h-100 pl-3 pr-3">
                            <div className="row h-100">
                                <div className="col-12 h-100 pt-3 pb-3 px-0">
                                    <h1 className="pi-font-size-20 px-3">
                                        Documents
                                    </h1>
                                    <ListGroup variant="flush">
                                        {this.props.documents &&
                                            this.props.documents.map(
                                                (document) => (
                                                    <ListGroup.Item
                                                        className="px-3"
                                                        key={document.id}>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div>
                                                                <h2 className="font-weight-bold pi-font-size-16 pi-news-title mb-0">
                                                                    {
                                                                        document.name
                                                                    }
                                                                </h2>
                                                                <p className="font-italic pi-news-date mb-0">
                                                                    {
                                                                        document.description
                                                                    }
                                                                </p>
                                                            </div>

                                                            <IconButton
                                                                classes="bg-transparent p-0"
                                                                onClick={(): void =>
                                                                    this.getDocument(
                                                                        document.location,
                                                                        this
                                                                            .openDocument,
                                                                    )
                                                                }>
                                                                <FontAwesomeIcon
                                                                    className="pi-color-accent-primary"
                                                                    icon={
                                                                        faExternalLinkAlt
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </ListGroup.Item>
                                                ),
                                            )}
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Documents;
