import * as React from 'react';

import { FinancialOverviewProps } from "../../../../Types/Features";

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { currency } from "../../../../Shared/Pipes/Currency";

function FinancialOverview(props: FinancialOverviewProps): React.ReactElement {
    const [index, setIndex] = React.useState<number>(0)

    return (
        <div className="rounded border pi-top-border shadow-sm p-3 h-100">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-4">
                Financials
            </h2>

            <div className="d-flex border rounded mb-3">
                <div className="col-4 px-0">
                    <Button
                        variant={index === 0 ? "primary" : "light"}
                        onClick={(): void => setIndex(0)}
                        className={"p-2 w-100 border-0 rounded-0 rounded-left " + (index === 0 ? "pi-bg-color-primary text-white" : "bg-transparent text-black")}>
                        Year 1</Button>
                </div>

                <div className="col-4 px-0">
                    <Button
                        variant={index === 1 ? "primary" : "light"}
                        onClick={(): void => setIndex(1)}
                        className={"p-2 w-100 border-0 rounded-0 " + (index === 1 ? "pi-bg-color-primary text-white" : "bg-transparent text-black")}>
                        Year 3</Button>
                </div>

                <div className="col-4 px-0">
                    <Button
                        variant={index === 2 ? "primary" : "light"}
                        onClick={(): void => setIndex(2)}
                        className={"p-2 w-100 border-0 rounded-0 " + (index === 2 ? "pi-bg-color-primary text-white" : "bg-transparent text-black")}>
                        Year 5</Button>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Expected Rent</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The expected monthly rent amount being collected from tenants during the given year.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                                            {currency(props.calculations.expected_rents[index], 0)}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Expenses</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-expenses">
                                Total estimated expenses in the given year including fees charged by PeerInvest.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                                            {currency(props.calculations.expenses[index], 0)}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Property Taxes</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-taxes">
                                Estimated amount paid in property taxes each year.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                                            {currency(props.calculations.property_taxes[index], 0)}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3 pb-2 border-bottom">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Loan Payments</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-loan-payments">
                                One year worth of loan payments (monthly payment times 12).
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                                            {currency(props.calculations.mortgage_payment * 12, 0)}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Net Cash Flow</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-cash-flow">
                                Expected cash flow available to be returned to shareholders each year.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                                            {currency(props.calculations.cash_flows[index], 0)}</span>
            </div>
        </div>
    )
}

export default FinancialOverview;