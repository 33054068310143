import * as React from "react";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";

class TermsOfService extends React.Component<{}, {}> {
    render(): React.ReactElement {
        return (
            <div>
                <div className="container">
                    <MasterHeader/>
                </div>

                <div className="pi-bg-color-secondary">
                    <div className="container">
                        <div className="row justify-content-center py-5">
                            <div className="col-12">
                                <h1 className="text-white text-center">Terms of Service</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="p-3 px-md-5">
                        <div className="mx-0 mx-md-5 p-0 p-md-5 bg-white rounded">
                            <p>Please read these Terms of Service (“Terms”, “Terms of Service”) carefully
                                before using this application (the “Service”) operated by PeerInvest (“us”, “we”, or
                                “our”). </p>
                            <p>Your access to and use of the Service is conditioned on your acceptance of and
                                compliance with these Terms. These Terms of Service apply to all visitors, users and others
                                who
                                access or use the Service. By accessing or using the Service you agree to be bound by these
                                Terms.
                                If you disagree with any part of the terms then you may not access the Service.</p>
                            <h1 className="pi-font-size-24">Your Acceptance of These Terms</h1>
                            <p>By using this App, you signify your acceptance of this policy and
                                https://peerinvest.io/terms-of-service. If you do not agree to this policy, please do not
                                use our App. Your
                                continued use of the App following the posting of changes to this policy will be deemed your
                                acceptance of those changes.</p>
                            <h1 className="pi-font-size-24">Minors</h1>
                            <p>Our content is available only to, and may only be used by, individuals who are
                                18 years or older and who can form legally binding contracts under applicable law.
                                Individuals under
                                the age of 18 can use the App, content, and all related services only in conjunction with
                                and under
                                the supervision of a parent or legal guardian. In this case, the adult is the user and is
                                responsible for any and all activities, subscribers and purchasers.</p>
                            <h1 className="pi-font-size-24">No Assignment or Transfer Rights</h1>
                            <p>You may not assign or transfer your responsibility under these Terms of Use in
                                whole or in part to any third party. Your account is personal and cannot be transferred or
                                assigned,
                                directly or indirectly, to another person, whether by operation of law or otherwise. Any
                                attempted
                                assignment or transfer is null and void. These Terms of Use shall bind and inure to the
                                benefit of
                                the parties (you and PeerInvest) and their respective successors, permitted transferees, and
                                permitted assigns.</p>
                            <h1 className="pi-font-size-24">Scope of Services</h1>
                            <p>PeerInvest maintains this app as a benefit to our users, subject to these
                                Terms of Use. Providing that you comply at all times with these Terms of Use, PeerInvest
                                will
                                provide you with access to the Content. You are responsible for obtaining any equipment and
                                Internet
                                service necessary to access the App and for paying any fees for the equipment and services
                                you
                                select. We may alter, suspend, or discontinue this app or the content in whole or in part,
                                at any
                                time and for any reason, without notice. The app may also periodically become unavailable
                                due to
                                maintenance or malfunction of computer equipment or for other reasons.</p>
                            <p>We may impose fees, charges or other conditions for use of the App or parts
                                thereof (with reasonable notice). We may offer you different tiers of access to content and
                                related
                                services and features on the App. You have the option to sign up for free content and
                                related
                                services and features or you may sign up for paid Content and related services or features.
                                We may
                                offer different benefits to free Content subscribers or to subscribers who pay for Content,
                                and we
                                reserve the right to change our content offerings and pricing from time to time. The fee for
                                paid
                                Content may vary based on the nature and frequency of the Content that we provide to you, or
                                type of
                                subscriber (e.g., individual, organization, student). We may offer certain Content at no
                                fee,
                                provided that you understand that we may choose not to continue to do so at any time.
                                Nothing in
                                these Terms of Use shall be taken to require PeerInvest to continue to provide services that
                                are of
                                like nature to the Content.</p>
                            <h1 className="pi-font-size-24">No Class Actions</h1>
                            <p>YOU AGREE THAT ANY CLAIMS YOU ASSERT AGAINST PEERINVEST SHALL BE BROUGHT
                                SOLELY BY YOU AND NOT AS PART OF A CLASS OF CLAIMANTS.</p>
                            <p>EACH PARTY HEREBY WAIVES ANY RIGHT TO SEEK REMEDY AGAINST THE OTHER PARTY, IN
                                CONNECTION WITH THESE TERMS OF USE, THE APP, AND THE CONTENT, THROUGH A CLASS ACTION.</p>
                            <h1 className="pi-font-size-24">Important Securities Disclaimer</h1>
                            <p>You understand that no content published on the app constitutes a
                                recommendation that any particular security, portfolio of securities, transaction or
                                investment
                                strategy is suitable for any specific person. You further understand that none of the
                                bloggers,
                                information providers, App providers, or their affiliates are advising you personally
                                concerning the
                                nature, potential, value or suitability of any particular security, portfolio of securities,
                                transaction, investment strategy or other matter. To the extent that any of the content
                                published on
                                the app may be deemed to be investment advice or recommendations in connection with a
                                particular
                                security, such information is impersonal and not tailored to the investment needs of any
                                specific
                                person. You understand that an investment in any security is subject to a number of risks,
                                and that
                                discussions of any security published on the app will not contain a list or description of
                                relevant
                                risk factors.</p>
                            <p>You understand that the app may contain contradicting opinions with regard to
                                securities. You understand and agree that, although we require all employees to disclose
                                every security
                                in which they, their immediate family, or any entity under their control, have a personal
                                interest,
                                if such security is mentioned in a blog, post, or content which they publish, non-employees,
                                including
                                outside content contributors or their affiliates may write about securities in which they or
                                their
                                firms have a position, and that they may trade for their own account, and that they may or
                                may not
                                be subject to a disclosure policy. In cases where PeerInvest becomes aware that one of its
                                employees has violated his or her disclosure obligation, PeerInvest will take appropriate
                                action.
                                In addition, outside bloggers or content contributors may be subject to certain restrictions
                                on
                                trading for their own account. However, you understand and agree that at the time of any
                                transaction
                                that you make, one or more content contributors or their affiliates may have a position in
                                the
                                securities written about.</p>
                            <p>You understand that performance data is supplied by sources believed to be
                                reliable, that the calculations herein are made using such data, and that such calculations
                                are not
                                guaranteed by these sources, the information providers, or any other person or entity, and
                                may not
                                be complete.</p>
                            <p>From time to time, reference may be made on our app to prior posting, blog,
                                article, opinion or other content we have published. These references may be selective, may
                                reference only a portion of a posting, blog, article or opinion, and are likely not to be
                                current.
                                As markets change continuously, previously published information and data may not be current
                                and
                                should not be relied upon.</p>
                            <p>All content on the app is presented only as of the date published or
                                indicated, and may be superseded by subsequent market events or for other reasons. In
                                addition, you
                                are responsible for setting the cache settings on your browser to ensure you are receiving
                                the most
                                recent data.</p>
                            <h1 className="pi-font-size-24">App Content</h1>
                            <p>You understand that all postings, messages, text, images, video or other
                                materials (“Content”) posted or published on, uploaded to, transmitted through, or linked
                                from, the
                                Service (hereinafter, “post”, “posted” or “published”), whether by PeerInvest, users of the
                                Service
                                (“Users”) or Creators (as defined below), are the sole responsibility of the person or
                                entity from
                                whom such Content originated.</p>
                            <p>You agree that by using the Service, you may be exposed to Content that is
                                offensive, indecent, inaccurate, misleading, or otherwise objectionable. PeerInvest, its
                                affiliates
                                and partners are not responsible for the conduct, whether online or offline, of any User or
                                Creator.
                                You agree that you bear all risks associated with, the use or posting of any Content, that
                                you may
                                not rely on said Content, and that under no circumstances will PeerInvest, its affiliates,
                                or
                                partners be liable in any way for any Content or for any loss or damage of any kind incurred
                                as a
                                result of the use of any Content posted or otherwise made available via the Service. You may
                                not
                                republish, post, transmit or distribute the Content to online bulletin and message boards,
                                blogs,
                                chat rooms, intranets or anywhere else without our consent.</p>
                            <h1 className="pi-font-size-24">User Content Posted on the Service</h1>
                            <p>You are solely responsible for the Content that you post on or through the
                                Service, or otherwise transmit to or share with other Users (collectively, the User
                                “Content”). You
                                shall not (and shall not permit any third party to) create, upload, download, post, submit
                                or
                                otherwise distribute or facilitate distribution of any User Content on or through the
                                Service,
                                that:</p>
                            <p>You did not create or have permission to post;</p>
                            <p>Infringes any patent, trademark, trade secret, copyright, moral right, right
                                of publicity, right of privacy, or other right of any other person or entity or violates
                                these Terms
                                or any law or contractual duty (see our Notice of Infringement - DMCA policy below); you
                                know is
                                false, misleading, untruthful or inaccurate;</p>
                            <p>Defames, libels, ridicules, mocks, disparages, threatens, harass, intimidates
                                or abuses anyone;</p>
                            <p>Attempts to impersonate any other party;</p>
                            <p>Uses tools, which anonymize your internet protocol address (e.g. anonymous
                                proxy) to access the Service;</p>
                            <p>Constitutes unauthorized or unsolicited advertising, junk or bulk email;
                                or</p>
                            <p>Includes anyone’s identification documents or sensitive financial
                                information.</p>
                            <p>You acknowledge that PeerInvest does not pre-screen, endorse or approve User
                                Content, but that PeerInvest shall have the right (but not the obligation) in its sole
                                discretion
                                to refuse, delete or remove any User Content that is available via the Service, including in
                                connection with violations of the letter or spirit of the Terms or for any other reason. You
                                are
                                solely responsible at your sole cost and expense for creating backup copies and replacing
                                any User
                                Content you post or store on the Service or otherwise provide to the Company.</p>
                            <p>When you post User Content to the Service, you authorize and direct us to make
                                such copies thereof as we deem necessary in order to facilitate the posting and storage of
                                the User
                                Content on the Service. By posting User Content to any part of the Service, you
                                automatically grant,
                                and you represent and warrant that you have the right to grant, to PeerInvest an
                                irrevocable,
                                perpetual, non-exclusive, transferable, fully paid, worldwide license (with the right to
                                sublicense)
                                to use, copy, publicly perform, publicly display, reformat, translate, excerpt (in whole or
                                in part)
                                and distribute such User Content, and your name, voice, and/or likeness as contained in your
                                Content, for any purpose, commercial, advertising, or otherwise, on or in connection with
                                the
                                Service or the promotion thereof, to prepare derivative works of, or incorporate into other
                                works,
                                such User Content, and to grant and authorize sublicenses of the foregoing. PeerInvest does
                                not
                                assert any ownership over your User Content; rather, as between you, subject to the rights
                                granted
                                to us in these Terms and Conditions, and us you retain full ownership of all of your User
                                Content
                                and any intellectual property rights or other proprietary rights associated with your User
                                Content.
                                You also hereby grant each user of the Service a non-exclusive license to access your User
                                Content
                                through the Service, and to use, reproduce, distribute, display and perform such User
                                Content as
                                permitted through the functionality of the Service and under these Terms.</p>
                            <p>WE ALSO RESERVE THE RIGHT, AT ALL TIMES AND IN OUR SOLE DISCRETION, TO
                                DISCLOSE ANY USER CONTENT AND OTHER RELATED INFORMATION FOR ANY REASON, including without
                                limitation
                                (a) to satisfy any applicable law, regulation, legal process or governmental request; (b) to
                                enforce
                                the terms of the Agreements; (c) to protect PeerInvest’s legal rights and remedies; (d) if
                                we, in
                                our reasonable discretion, believe that someone’s health or safety may be threatened; or (e)
                                to
                                report a crime or other offensive behavior.</p>
                            <h1 className="pi-font-size-24">Content Submitted by Users</h1>
                            <p>Liability. We are not responsible or liable for the conduct of users or for
                                any views, opinions and statements expressed in content submitted by users for public
                                display or
                                otherwise through our app. We do not prescreen information or content submitted by you or
                                other
                                users. We only act as a passive conduit for distribution and are not responsible for content
                                submitted by you or other users. Any opinions, advice, statements, services, offers, or
                                other
                                information in content expressed or made available by users of PeerInvest are those of the
                                respective author(s) or distributor(s) and not of PeerInvest. We neither endorse nor
                                guarantee the
                                accuracy, completeness, or usefulness of any such content. You are responsible for ensuring
                                that
                                your own content submitted to this app is not provided in violation of any copyright, trade
                                secret
                                or other intellectual property rights of another person or entity. You shall be solely
                                liable for
                                any damages resulting from any infringement of copyrights, trade secret, or other
                                intellectual
                                property rights, or any other harm resulting from your uploading, posting or submission of
                                content
                                to this app.</p>
                            <p>Monitoring. We have the right, but not the obligation, to monitor content
                                submitted to the app to determine compliance with these Terms of Use. We have the right in
                                our sole
                                discretion to edit or remove any content submitted to, posted to, or provided through the
                                app,
                                including without limitation any content that is forbidden under the section “Prohibited
                                Conduct”.
                                Without limiting the foregoing, we have the right to remove any material that PeerInvest, in
                                its
                                sole discretion, finds to be in violation of these Terms of Use or otherwise objectionable,
                                and you
                                are solely responsible for the content that you post to this app.</p>
                            <p>Moderation. In order to maintain a level of discourse appropriate to our user
                                base, we may, at our discretion, delete comments, articles, posts, or other user
                                communications and
                                block/delete accounts of users we believe lower the level of discourse and courtesy to which
                                we
                                aspire.</p>
                            <p>Moderating decisions are subjective, and we strive to make them carefully and
                                consistently. Due to the volume of content, we cannot review moderation decisions with users
                                and
                                cannot reverse decisions.</p>
                            <p>Our blogs, forums, features and content are intended as a platform for
                                thoughtful users to discuss and make their own investment decisions, with or without the
                                help of a
                                broker. We strongly encourage all participants to disclose any positions they have in
                                securities being
                                discussed.</p>
                            <p>Without derogating from the above, PeerInvest, at its sole discretion, may
                                refrain from posting or removing content submitted by users that violate these standards or
                                which
                                are otherwise inappropriate. If a user has a business relationship with a company named in
                                an
                                article, blog, post, comment, or other content that he or she has authored, that
                                relationship must
                                be fully and accurately disclosed.</p>
                            <p>If you have any comments on our policies, or complaints or concerns of any
                                kind about any posts, please contact us at info@peerinvest.io. We will review all of the
                                information
                                that you communicate to us, but we may not be able to take action or respond directly to
                                each
                                email.</p>
                            <h1 className="pi-font-size-24">Prohibited Conduct</h1>
                            <p>By accessing our app or content, you agree to abide by the following standards
                                of conduct. You agree that you will not, and will not authorize or facilitate any attempt by
                                another
                                person to use our app, without limitation, to:</p>
                            <p>a. Transmit any content that is unlawful, harmful, threatening, abusive,
                                harassing, defamatory, vulgar, offensive, obscene, pornographic, lewd, lascivious, or
                                otherwise
                                objectionable, as determined by PeerInvest.</p>
                            <p>Use a name or language that PeerInvest, in its sole discretion, deems
                                offensive.</p>
                            <p>Post defamatory statements.</p>
                            <p>Post hateful or offensive content or content that disparages any ethnic,
                                racial, sexual, gender, religious, or other group.</p>
                            <p>Post content that depicts or advocates the use of illegal drugs.</p>
                            <p>Post content that characterizes violence as acceptable, glamorous or
                                desirable.</p>
                            <p>Post content which infringes another&apos;s copyright, trademark or trade
                                secret.</p>
                            <p>Post unsolicited advertising or unlawfully promote products or services.</p>
                            <p>Harass, threaten, stalk or intentionally embarrass or cause distress to
                                another person or entity.</p>
                            <p>Impersonate another person.</p>
                            <p>Promote, solicit, or participate in any multi-level marketing or pyramid
                                schemes.</p>
                            <p>Exploit children under 18 years of age.</p>
                            <p>Engage in disruptive activity such as sending multiple messages in an effort
                                to monopolize the forum.</p>
                            <p>Introduce viruses, worms, Trojan horses and/or harmful code to the app.</p>
                            <p>Obtain unauthorized access to any computer system through the app.</p>
                            <p>Invade the privacy of any person, including without limitation posting
                                personally identifying or otherwise private information about a person without their consent
                                (or
                                their parent’s consent in the case of a child under 13 years of age).</p>
                            <p>Solicit personal information from children under 13 years of age.</p>
                            <p>Violate any federal, state, local, or international law or regulation.</p>
                            <p>Encourage conduct that would constitute a criminal or civil offense.</p>
                            <p>In addition, you may not use automated scripts to collect information or
                                otherwise interact with our content or the app.</p>
                            <p>PeerInvest reserves the right to consider other conduct to be prohibited;
                                this section is intended to be illustrative.</p>
                            <h1 className="pi-font-size-24">Purchases</h1>
                            <p>If you wish to purchase any product or service made available through the
                                Service (“Purchase”) you may be asked to supply certain information relevant to your
                                Purchase
                                including: name, email address.</p>
                            <p>You represent and warrant that: The information you supply to us is true,
                                correct and complete. You expressly agree that PeerInvest is not responsible for any loss or
                                damage
                                arising from the submission of false or inaccurate information.</p>
                            <p>We reserve the right to refuse or cancel your order at any time for certain
                                reasons including but not limited to: product or service availability, errors in the product
                                or
                                service description or price, error in your order or other reasons. You expressly agree that
                                Peer
                                Invest cannot accept any liability for loss or damage arising out of such cancellation. We
                                reserve
                                the right to refuse or cancel your order if we suspect fraud or an unauthorized or illegal
                                transaction.</p>
                            <h1 className="pi-font-size-24">Payment Processing</h1>
                            <p>In order to use the payment functionality of PeerInvest&apos;s application, you must open a
                                &quot;Dwolla Platform&quot; account provided by Dwolla, Inc. and you must accept the Dwolla <a
                                    className="ml-1" href="https://www.dwolla.com/legal/tos/">Terms of
                                    Service</a> and <a className="ml-1" href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>. Any
                                funds held in the Dwolla account are held by Dwolla&apos;s
                                financial institution partners as set out in the Dwolla Terms of Service. You authorize
                                PeerInvest to collect and share with Dwolla your personal information including full name,
                                date of birth, social security number, physical address email address and financial
                                information, and you are responsible for the accuracy and completeness of that data. You
                                understand that you will access and manage your Dwolla account through PeerInvest&apos;s
                                application, and Dwolla account notifications will be sent by PeerInvest, not Dwolla.
                                PeerInvest will provide customer support for your Dwolla account activity, and can be
                                reached at <a href="https://peerinvest.io">https://peerinvest.io</a>, or
                                support@peerinvest.io.</p>
                            <h1 className="pi-font-size-24">Availability, Errors and Inaccuracies</h1>
                            <p>We are constantly updating our offerings of products and services on the
                                Service. The products or services available on our Service may be mispriced, described
                                inaccurately,
                                or unavailable, and we may experience delays in updating information on the Service. You
                                expressly
                                agree that any such offer of a product or service does not constitute a legal offer capable
                                of
                                attracting legal consequences.</p>
                            <p>We cannot and do not guarantee the accuracy or completeness of any
                                information, including prices, product images, specifications, availability, and services.
                                We
                                reserve the right to change or update information and to correct errors, inaccuracies, or
                                omissions
                                at any time without prior notice.</p>
                            <h1 className="pi-font-size-24">Accounts</h1>
                            <p>When you create an account with us, you must provide us information that is
                                accurate, complete, and current at all times. Failure to do so constitutes a breach of the
                                Terms of
                                Service, which may result in immediate termination of your account on our Service.</p>
                            <p>You are responsible for safeguarding the password that you use to access the
                                Service and for any activities or actions under your password, whether your password is with
                                our
                                Service or a third-party service.</p>
                            <p>You agree not to reveal your password to any third party. You must notify us
                                immediately upon becoming aware of any breach of security or unauthorized use of your
                                account.</p>
                            <h1 className="pi-font-size-24">Intellectual Property</h1>
                            <p>The Service and its original content, features and functionality are and will
                                remain the exclusive property of PeerInvest and its licensors. The Service is protected by
                                copyright, trademark, and other laws of both the United States and foreign countries. Our
                                trademarks
                                may not be used in connection with any product or service without the prior written consent
                                of Peer
                                Invest.</p>
                            <h1 className="pi-font-size-24">Links to Other Web Sites</h1>
                            <p>Our Service may contain links to third-party web sites or services that are
                                not owned or controlled by PeerInvest.We have no control over any website we are linked to
                                so we
                                cannot be responsible for safeguarding any information which you provide such sites and this
                                privacy
                                statement does not govern such sites. Caution should be exercised when visiting linked
                                websites and
                                the privacy statement of the website in question should be examined.</p>
                            <p>PeerInvest has no control over, and assumes no responsibility for, the
                                content, privacy policies, or practices of any third party web sites or services. You
                                further
                                acknowledge and agree that PeerInvest shall not be responsible or liable, directly or
                                indirectly,
                                for any damage or loss caused or alleged to be caused by or in connection with use of or
                                reliance on
                                any such content, goods or services available on or through any such web sites or services.
                                We
                                strongly advise you to read the terms of service and privacy policies of any third-party web
                                sites
                                or services that you visit.</p>
                            <h1 className="pi-font-size-24">Termination</h1>
                            <p>We may terminate or suspend your account immediately, without prior notice or
                                liability, for any reason whatsoever, including without limitation if you breach the
                                Terms.</p>
                            <p>Upon termination, your right to use the Service will immediately cease. If you
                                wish to terminate your account, you may simply discontinue using the Service.</p>
                            <p>All provisions of the Terms, which by their nature should survive termination,
                                shall survive termination, including, without limitation, ownership provisions, warranty
                                disclaimers, indemnity and limitations of liability.</p>
                            <h1 className="pi-font-size-24">Limitation of Liability</h1>
                            <p>In no event shall PeerInvest, nor its directors, employees, partners, agents,
                                suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or
                                punitive
                                damages, including without limitation, loss of profits, data, use, goodwill, or other
                                intangible
                                losses, resulting from your access to or use of or inability to access or use the Service,
                                any
                                conduct or content of any third party on the Service, any content obtained from the Service,
                                unauthorized access, use or alteration of your transmissions or content, whether based on
                                warranty,
                                contract, tort (including negligence) or any other legal theory, whether or not we have been
                                informed of the possibility of such damage, and even if a remedy set forth herein is found
                                to have
                                failed of its essential purpose.</p>
                            <h1 className="pi-font-size-24">Disclaimer</h1>
                            <p>Your use of the Service is at your sole risk. The Service is provided on an
                                “as is” and “as available” basis. The Service is provided without warranties of any kind,
                                whether
                                express or implied, including, but not limited to, implied warranties of merchantability,
                                fitness
                                for a particular purpose, non-infringement or course of performance.</p>
                            <p>PeerInvest, its subsidiaries, affiliates, and its licensors do not warrant
                                that: the results of using the Service will meet your requirements.</p>
                            <h1 className="pi-font-size-24">Exclusions</h1>
                            <p>Without limiting the generality of the foregoing and notwithstanding any other
                                provision of these terms, under no circumstances will PeerInvest ever be liable to you or
                                any other
                                person for any indirect, incidental, consequential, special, punitive or exemplary loss or
                                damage
                                arising from, connected with, or relating to your use of the Service, these Terms, the
                                subject
                                matter of these Terms, the termination of these Terms or otherwise, including but not
                                limited to
                                personal injury, loss of data, business, markets, savings, income, profits, use, production,
                                reputation or goodwill, anticipated or otherwise, or economic loss, under any theory of
                                liability
                                regardless of any negligence or other fault or wrongdoing (including without limitation
                                gross
                                negligence and fundamental breach) by PeerInvest or any person for whom PeerInvest is
                                responsible,
                                and even if PeerInvest has been advised of the possibility of such loss or damage being
                                incurred.</p>
                            <h1 className="pi-font-size-24">Governing Law</h1>
                            <p>These Terms shall be governed and construed in accordance with the laws of
                                United States without regard to its conflict of law provisions. Our failure to enforce any
                                right or
                                provision of these Terms will not be considered a waiver of those rights. If any provision
                                of these
                                Terms is held to be invalid or unenforceable by a court, the remaining provisions of these
                                Terms
                                will remain in effect. These Terms constitute the entire agreement between us regarding our
                                Service,
                                and supersede and replace any prior agreements we might have between us regarding the
                                Service.</p>
                            <h1 className="pi-font-size-24">Changes</h1>
                            <p>We reserve the right, at our sole discretion, to modify or replace these Terms
                                of Service at any time. When we do such updates we or notify users via email. By continuing
                                to
                                access or use our Service after those revisions become effective, you agree to be bound by
                                the
                                revised terms. If you do not agree to the new terms, you must stop using the service.</p>
                            <h1 className="pi-font-size-24">Intellectual Property Rights</h1>
                            <p>Other than content you own, which you may have chosen to include on this
                                Application, PeerInvest and/or its licensors own all rights to the intellectual property and
                                material contained in this Application. You are granted a limited license only, subject to
                                the
                                restrictions provided in these Terms, for purposes of viewing the material contained on
                                https://peerinvest.io/.</p>
                            <h1 className="pi-font-size-24">Restrictions</h1>
                            <p>You are expressly and emphatically restricted from all of the following:
                                Publishing any Application material in any media, Selling, sublicensing and/or
                                commercializing in
                                any way, any Application material, Publicly performing or displaying any Application
                                material, Using
                                this Application in a way that is damaging, or that could be damaging to this Application,
                                Using
                                this Application contrary to applicable laws and regulations, or in a manner that results
                                in, or
                                that could result in harm to the Application, or to any person or business entity, Engaging
                                in any
                                data mining, data harvesting, data extracting or any other similar type activity related to
                                this
                                Application, or while using this Application Using this Application to engage in any type of
                                advertising or marketing activity.</p>
                            <p>Certain areas of this Application are restricted from access by you and Peer
                                Invest may further restrict access to other areas of this Application by you, at any time,
                                at its
                                sole discretion.</p>
                            <h1 className="pi-font-size-24">No Warranties</h1>
                            <p>PeerInvest makes no express or implied warranties or representations of any
                                kind with regards to this Application or the materials contained on this Application. In
                                addition no
                                content contained on this Application shall be considered as providing advice to you.</p>
                            <h1 className="pi-font-size-24">Limitation of Liability</h1>
                            <p>Under no circumstances shall PeerInvest, or any of its officers, directors
                                and employees, be liable to you for anything resulting from or connected to your use of this
                                Application. PeerInvest, including its officers, directors and employees shall not be liable
                                for
                                any indirect, consequential or special liability resulting from or in any way related to
                                your use of
                                this Application.</p>
                            <h1 className="pi-font-size-24">Severability</h1>
                            <p>Should any provision of these Terms be found to be unenforceable or invalid
                                under any applicable law, the unenforceability or invalidity shall not render these Terms
                                unenforceable or invalid as a whole. Any such provisions shall be deleted without affecting
                                the
                                remaining provisions contained herein.</p>
                            <h1 className="pi-font-size-24">Variation of Terms</h1>
                            <p>These Terms may be revised at any time PeerInvest sees fit, and you are
                                expected to review such terms on a regular basis to ensure your understanding of all terms
                                and
                                conditions governing use of this Application.or notify users via email. By using this
                                Application
                                you are acknowledging your responsibility to do so.</p>
                            <h1 className="pi-font-size-24">Entire Agreement</h1>
                            <p>These Terms, including any legal notices and disclaimers contained on this
                                Application, constitute the entire agreement between PeerInvest and you with regards to your
                                use of
                                this Application, and replace all prior agreements and understandings with respect to the
                                same.</p>
                            <h1 className="pi-font-size-24">Governing Law and Jurisdiction</h1>
                            <p>These Terms will be governed by and construed in accordance with the laws of
                                United States of America, and you submit to the nonexclusive jurisdiction of the courts
                                located in
                                United States of America for the resolution of any disputes.</p>
                            <p>PeerInvest wants to ensure that your privacy is protected and this privacy
                                policy relates how uses and protects any information provided to PeerInvest when you use
                                this
                                application. Any information you provide will only be used in accordance with this privacy
                                statement.</p>
                            <p>This Privacy Policy governs the manner in which PeerInvest collects, uses,
                                maintains and discloses information collected from users (each, a &quot;User&quot;) of the
                                PeerInvest
                                Application (&quot;App&quot;) and https://peerinvest.io/ (&quot;Site&quot;). This privacy
                                policy applies to the App and
                                Site and all products and services offered by PeerInvest.</p>
                            <h1 className="pi-font-size-24">Personal Identification Information</h1>
                            <p>We may collect personal identification information from Users in a variety of
                                ways, including, but not limited to, when Users visit our app, register on the app fill out
                                a form
                                respond to a survey subscribe to the newsletter and in connection with other activities,
                                services,
                                features or resources we make available on our App. Users may be asked for, as
                                appropriate:</p>
                            <p>name</p>
                            <p>contact information including email address</p>
                            <h1 className="pi-font-size-24">Security</h1>
                            <p>To stop any unauthorized access or disclosure of your information, appropriate
                                electronic, physical and managerial procedures have been implemented to protect the
                                information we
                                gather online and ensure its security.</p>
                            <p>We will collect personal identification information from Users only if they
                                voluntarily submit such information to us. Users can always refuse to supply personally
                                identification information, except that it may prevent them from engaging in certain App
                                related
                                activities.</p>
                            <h1 className="pi-font-size-24">Non-personal Identification Information</h1>
                            <p>We may collect non-personal identification information about Users whenever
                                they interact with our App. Non-personal identification information may include the browser
                                name,
                                the type of computer and technical information about Users means of connection to our App,
                                such as
                                the operating system and the Internet service providers utilized and other similar
                                information.</p>
                            <h1 className="pi-font-size-24">How We Use Collected Information</h1>
                            <p>PeerInvest collects and uses Users personal information for the following
                                purposes:</p>
                            <p>To improve customer service. Your information helps us to more effectively
                                respond to your customer service requests and support needs.</p>
                            <p>To personalize user experience. We may use information in the aggregate to
                                understand how our Users as a group use the services and resources provided on our App.</p>
                            <p>To improve our App. We continually strive to improve our application offerings
                                based on the information and feedback we receive from you.</p>
                            <h1 className="pi-font-size-24">To Send Periodic Emails</h1>
                            <p>The email address Users provide for order processing, will only be used to
                                send them information and updates pertaining to their order. It may also be used to respond
                                to their
                                inquiries, and/or other requests or questions. If User decides to opt-in to our mailing
                                list, they
                                will receive emails that may include company news, updates, related product or service
                                information,
                                etc. If at any time the User would like to unsubscribe from receiving future emails, we
                                include
                                detailed unsubscribe instructions at the bottom of each email or User may contact us via our
                                App</p>
                            <h1 className="pi-font-size-24">How We Protect Your Information</h1>
                            <p>We adopt appropriate data collection, storage and processing practices and
                                security measures to protect against unauthorized access, alteration, disclosure or
                                destruction of
                                your personal information, username, password, transaction information and data stored on
                                our App.
                                Sensitive and private data exchange between the App and its Users happens over a SSL secured
                                communication channel</p>
                            <h1 className="pi-font-size-24">Sharing Your Personal Information</h1>
                            <p>We do not sell, trade, or rent Users personal identification information to
                                others. We may share generic aggregated demographic information not linked to any personal
                                identification information regarding visitors and users with our business partners, trusted
                                affiliates and advertisers for the purposes outlined above. In addition, we share trade and
                                portfolio information including, but not limited to, trade data, portfolio positions,
                                performance
                                data, etc.</p>
                            <h1 className="pi-font-size-24">Compliance with Children&apos;s Online Privacy Protection
                                Act</h1>
                            <p>Protecting the privacy of the very young is especially important. For that
                                reason, we never collect or maintain information at our App from those we actually know are
                                under
                                13, and no part of our website is structured to attract anyone under 13.</p>
                            <h1 className="pi-font-size-24">Changes to This Privacy Policy</h1>
                            <p>PeerInvest has the discretion to update this privacy policy at any time. When
                                we do, or notify users via email. You acknowledge and agree that it is your responsibility
                                to review
                                this privacy policy periodically and become aware of modifications.</p>
                            <h1 className="pi-font-size-24">Your Acceptance of These Terms</h1>
                            <p>By using this App, you signify your acceptance of this policy and
                                https://peerinvest.io/terms. If you do not agree to this policy, please do not use our App.
                                Your
                                continued use of the App following the posting of changes to this policy will be deemed your
                                acceptance of those changes.</p>
                            <h1 className="pi-font-size-24">Contact Us</h1>
                            <p>If you have any questions about these Terms, please contact us.</p>
                            <p>PeerInvest, LLC</p>
                            <p>https://peerinvest.io/</p>
                            <p>support@peerinvest.io</p>
                            <p>These Application Standard Terms and Conditions (these “Terms” or these
                                “Application Standard Terms and Conditions”) contained here on this webpage, shall govern
                                your
                                use of this Application.</p>
                            <p>These Terms apply in full force and effect to your use of this Application
                                and by using the Application you accept all terms and conditions in full. You must not use
                                the
                                PeerInvest app and https://peerinvest.io/, if you have any objection to any of these
                                Application Standard Terms and Conditions.</p>
                        </div>
                    </div>
                </div>

                <HomeFooter/>
            </div>
        );
    }
}

export default TermsOfService;
