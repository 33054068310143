import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import {
    createDwollaFunding,
    getAccounts,
    getDwollaBalanceAmount,
    getDwollaFundingSource,
    plaidLinkToken,
    plaidPublicToken,
    removeAccount,
} from '../../Services/Banking/BankingServices';
import { TransferFormProps } from '../../Types/Shared';
import { number, object, string } from 'yup';
import { usePlaidLink } from 'react-plaid-link';
import IconButton from '../BasicComponents/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PlaidAccount } from '../../Types/Data';

const TransferSchema = object().shape({
    amount: number()
        .min(0.01, 'Please enter a valid number of shares.')
        .required('Please enter a valid number of shares.')
        .positive('Please enter a valid number of shares.'),
    account: string().required('Please select an account.'),
});

function TransferForm(props: TransferFormProps): React.ReactElement {
    const [accounts, setAccounts] = React.useState<string[]>([]);
    const [linkAccounts, setLinkAccounts] = React.useState<PlaidAccount[][]>(
        [],
    );
    const [linkToken, setLinkToken] = React.useState<string>('');
    const [showLink, setShowLink] = React.useState<boolean>(false);

    React.useEffect(() => {
        fetchDwollaFundingSources();

        plaidLinkToken().then((res) => {
            setLinkToken(res.data);
        });
    }, []);

    function handleRemoveAccount(account: string): void {
        removeAccount(account).then((res) => {
            setAccounts(res.data);
        });
    }

    function handleCreateDwollaFunding(account: string, item: string): void {
        createDwollaFunding(account, item).then((res) => {
            fetchDwollaFundingSources();
        });
    }

    function fetchDwollaFundingSources(): void {
        getDwollaFundingSource('True')
            .then((res) => {
                if (res.data && res.data.length) {
                    const newAccounts: string[] = [];
                    res.data.forEach((acc) => {
                        newAccounts.push(acc);
                    });
                    setAccounts(newAccounts);
                    setShowLink(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onSuccess = React.useCallback(
        (token) => {
            plaidPublicToken(token).then(() => {
                setShowLink(true);
                getAccounts().then((res) => {
                    setLinkAccounts(res.data);
                });
            });
        },
        [props],
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open } = usePlaidLink(config);

    if (showLink) {
        return (
            <div>
                <p>
                    Select up to two accounts from the list below to link to
                    PeerInvest.
                </p>
                {linkAccounts.map((institution: PlaidAccount[]) => (
                    <div
                        className="d-flex justify-content-between align-items-center py-2"
                        key={institution[0].account_id.substring(0, 5)}>
                        <div>
                            {institution.map((account: PlaidAccount) => (
                                <p className="mb-1" key={account.account_id}>
                                    <IconButton
                                        classes="text-white pi-bg-color-dark mb-0 mr-2 rounded"
                                        onClick={(): void =>
                                            handleCreateDwollaFunding(
                                                account.label,
                                                account.item_id,
                                            )
                                        }>
                                        <FontAwesomeIcon icon={faLink} />
                                    </IconButton>
                                    {account.label}
                                </p>
                            ))}
                        </div>
                        <IconButton
                            classes="text-white pi-bg-color-dark rounded"
                            onClick={(): void =>
                                handleRemoveAccount(institution[0].label)
                            }>
                            <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                amount: undefined,
                account: '',
            }}
            validationSchema={TransferSchema}
            onSubmit={(values): void => {
                props.handleSubmit(values);
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                errors,
                touched,
                setFieldValue,
            }): React.ReactElement => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="accountSelect" className="mb-3">
                        <Form.Control
                            as="select"
                            name="account"
                            className="pi-font-size-14 bg-transparen"
                            onChange={(e: any): void => {
                                if (e.target.value === 'add') {
                                    open();
                                }
                                setFieldValue('account', e.target.value);
                            }}
                            value={values.account}>
                            <option value={''}>Select an Account</option>
                            {accounts
                                .filter((x) => x.toLowerCase() !== 'balance')
                                .map((account) => (
                                    <option value={account} key={account}>
                                        {account}
                                    </option>
                                ))}
                            <option value={'add'}>Add New Account</option>
                        </Form.Control>
                        {errors && errors.account && (
                            <p className="pi-font-size-14 text-danger">
                                {errors.account}
                            </p>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter an amount"
                            className="bg-transparent pi-font-size-14"
                            onChange={handleChange}
                            value={values.amount}
                        />
                        {errors && errors.amount && touched.amount && (
                            <p className="pi-font-size-14 text-danger">
                                {errors.amount}
                            </p>
                        )}
                    </Form.Group>

                    <Button
                        className="pi-primary-btn w-100 pi-montserrat"
                        type="submit">
                        Review
                    </Button>
                </Form>
            )}
        />
    );
}

export default TransferForm;
