import * as React from 'react';
import { ReactElement } from 'react';

import './App.css';
import Routes from './Routes/Routes';
import reducer from './Store/Reducer';

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux';

import { createStore } from 'redux';

import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const store = createStore(reducer);

class App extends React.Component<{}, {}> {
    render(): ReactElement {
        return (
            <div className="App">
                <Provider store={store}>
                    <CookiesProvider>
                        <Routes />
                    </CookiesProvider>
                </Provider>
            </div>
        );
    }
}

export default App;
