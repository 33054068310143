import * as React from 'react';

import './Styles/ContentCard.css';
import { CardBodyProps } from "../../Types/Shared";

function CardBody(props: CardBodyProps): React.ReactElement {
    return (
        <div className={(props.classes || '')}>
            {props.children}
        </div>
    )
}

export default CardBody;