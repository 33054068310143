import * as React from 'react';

import { BarProps } from '../../Types/Shared';

import { ResponsiveBar } from '@nivo/bar';
import { currency } from '../Pipes/Currency';
import { BREAKPOINTS } from '../../Utils/Breakpoints';

const Bar = (props: BarProps): React.ReactElement => (
    <ResponsiveBar
        data={props.data}
        keys={['dividends']}
        indexBy="date"
        margin={{
            top: 25,
            right: window.innerWidth > BREAKPOINTS.lg ? -20 : 0,
            bottom: window.innerWidth > BREAKPOINTS.lg ? 25 : 50,
            left: 70,
        }}
        padding={0.8}
        groupMode="grouped"
        colors={['#0190E8']}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        borderRadius={10}
        axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: window.innerWidth > BREAKPOINTS.lg ? 0 : -45,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
            format: (value) => currency(value, 2, true),
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        enableGridY={false}
        enableLabel={false}
        legends={[]}
        animate={true}
        tooltip={(data) => (
            <span className="pi-montserrat">{currency(data.value, 2)}</span>
        )}
    />
);

export default Bar;
