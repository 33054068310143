import { Serie, Datum } from '@nivo/line';
import {
    AccountBalancePoint,
    AssetPricePoint,
    Transaction,
} from '../Types/Data';
import { BarDatum } from '@nivo/bar';

export function lineDataManipulator(
    points: AccountBalancePoint[] | AssetPricePoint[],
): Serie[] {
    const datum: Datum[] = [];
    points.forEach((point) => {
        datum.push({
            x: new Date(point.created_at),
            y: point.value,
        });
    });
    return [
        {
            id: 'Account Balance',
            data: datum,
        },
    ];
}

export function barDataManipulator(transactions: Transaction[]): BarDatum[] {
    const datum: Datum[] = [];

    const currentMonth: number = new Date().getMonth();
    const currentYear: number = new Date().getFullYear();

    for (let i = currentMonth - 11; i <= currentMonth; i++) {
        const month: number = i >= 0 ? i : 12 + i;
        const year: number = i >= 0 ? currentYear : currentYear - 1;

        const monthlyTransactions = transactions.filter(
            (x) =>
                new Date(x.transact_date).getMonth() === month &&
                new Date(x.transact_date).getFullYear() === year &&
                x.transaction_type.toLowerCase() === 'dv' &&
                x.status.toLowerCase() !== 'cn',
        );
        let sum = 0;
        monthlyTransactions.forEach((transaction) => {
            sum += transaction.cost * transaction.num_shares;
        });
        datum.push({
            date:
                new Date(year, month, 1).toLocaleString('default', {
                    month: 'short',
                }) +
                ' ' +
                year.toString().substring(2, 4),
            dividends: sum,
        });
    }

    return datum;
}
