import * as React from 'react';

import "../Styles/PropertyDiscussion.css";
import DiscussionForm from "../../../../Shared/Forms/DiscussionForm";
import { PropertyDiscussionProps } from "../../../../Types/Features";
import IconButton from "../../../../Shared/BasicComponents/IconButton";
import { TimeAgo } from "../../../../Shared/Pipes/TimeAgo";
import DiscussionReplyForm from "../../../../Shared/Forms/DiscussionReplyForm";
import { submitPost, submitReply } from "../../../../Services/Properties/Properties";
import { Post } from "../../../../Types/Data";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';

function PropertyDiscussion(props: PropertyDiscussionProps): React.ReactElement {
    const [replyFormId, setReplyFormId] = React.useState<number>();
    const [postReply, setPostReply] = React.useState<boolean>();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [posts, setPosts] = React.useState<Post[]>([]);

    React.useEffect(() => {
        setPosts(props.posts);
    }, [props.posts])

    function handleSubmit(post: string): void {
        setIsSubmitting(true);
        submitPost(post, props.propertyId).then(res =>{
            setPostReply(undefined);
            setReplyFormId(undefined);
            setIsSubmitting(false);
            setPosts(res.data);
        }).catch(() => {
            // Add error handling
            setPostReply(undefined);
            setReplyFormId(undefined);
            setIsSubmitting(false);
        });
    }

    function handleReplyToPostSubmit(reply: string, id: number, propertyId: number): void {
        console.log(reply);
        setIsSubmitting(true);
        submitReply(reply, id, propertyId).then(res =>{
            setPostReply(undefined);
            setReplyFormId(undefined);
            setIsSubmitting(false);
            setPosts(res.data);
        }).catch(() => {
            // Add error handling
            setPostReply(undefined);
            setReplyFormId(undefined);
            setIsSubmitting(false);
        });
    }

    function openReply(id: number, post: boolean): void {
        setReplyFormId(id);
        setPostReply(post);
    }

    return (
        <div>
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-4 mt-3">
                Discussion
            </h2>

            <div className="row justify-content-center mb-3">
                <div className="col-12 col-lg-6">
                    <DiscussionForm
                        handleSubmit={(values: {post: string}) => handleSubmit(values.post)}
                        isSubmitting={isSubmitting}
                    />
                </div>
            </div>

            <div className="row justify-content-center mb-4">
                <div className="col-12 col-lg-6">
                    {posts.map(post =>
                        <div key={post.id} className={"border rounded shadow-sm mt-3"}>
                            <div className="p-3">
                                <h4 className="pi-font-size-16">{post.full_name}</h4>
                                <p className="pi-color-primary-gray pi-font-size-14">{TimeAgo(post.created_at)}</p>
                                <p>{post.post}</p>

                                <IconButton classes="bg-transparent p-0" onClick={(): void => openReply(post.id, true)}>
                                    <span>
                                        <FontAwesomeIcon className="pi-color-primary-gray mr-2 pi-font-size-14" icon={faReply} />
                                        <span className="pi-color-primary-gray pi-font-size-14">Reply</span>
                                    </span>
                                </IconButton>

                                {post.is_staff &&
                                    <span className="pi-color-tertiary pi-font-size-14 ml-3">PeerInvest Admin</span>
                                }
                            </div>

                            {(replyFormId === post.id && postReply) &&
                                <div className="p-3 border-top">
                                    <DiscussionReplyForm
                                        handleSubmit={(values: {reply: string}): void => handleReplyToPostSubmit(values.reply, post.id, props.propertyId)}
                                        isSubmitting={isSubmitting}
                                    />
                                </div>
                            }

                            {post.replies.map(reply =>
                                <div key={reply.id} className={"border-top pi-bg-color-light-gray " + (reply.is_staff ? "pi-admin-border": "")}>
                                    <div className="p-3 pl-4">
                                        <div className="mb-3">
                                            <span className="font-weight-bold pi-color-secondary mr-2">{reply.full_name}</span>
                                            <span>{reply.reply}</span>
                                        </div>

                                        <IconButton classes="bg-transparent p-0" onClick={(): void => openReply(reply.id, false)}>
                                            <span>
                                                <FontAwesomeIcon className="pi-color-primary-gray mr-2 pi-font-size-14" icon={faReply} />
                                                <span className="pi-color-primary-gray pi-font-size-14">Reply</span>
                                            </span>
                                        </IconButton>

                                        {reply.is_staff &&
                                            <span className="pi-color-tertiary pi-font-size-14 ml-3">PeerInvest Admin</span>
                                        }

                                        <span className="pi-color-primary-gray pi-font-size-14 ml-3">{TimeAgo(reply.created_at)}</span>
                                    </div>

                                    {(replyFormId === post.id && !postReply) &&
                                        <div className="p-3 border-top">
                                            <DiscussionReplyForm
                                                handleSubmit={(values: {reply: string}): void => handleReplyToPostSubmit(values.reply, reply.id, props.propertyId)}
                                                isSubmitting={isSubmitting}
                                            />
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PropertyDiscussion;