import * as React from 'react';

import { FinancialDetailsOverviewProps } from "../../../../Types/Features";
import { percent } from "../../../../Shared/Pipes/Percent";

import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function FinancialDetailsOverview(props: FinancialDetailsOverviewProps): React.ReactElement {
    return (
        <div className="d-block d-lg-flex">
            <div className="row flex-wrap flex-lg-nowrap align-items-stretch align-items-lg-start pi-bg-color-light-gray p-2 mt-3 mx-0">
                <div className="col-6 col-lg border-right p-3">
                    <h2 className="pi-font-size-14 pi-color-primary-gray d-flex align-items-center">
                        <span className="mr-1">Gross Yield</span>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-rent">
                                    Simply, 12 months of rent payments divided by the property price.
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="bg-transparent border-0 p-0">
                                <FontAwesomeIcon className="pi-font-size-14 pi-color-primary-gray" icon={faQuestionCircle}/>
                            </Button>
                        </OverlayTrigger>
                    </h2>
                    <p className="pi-font-size-20 mb-0">
                        {percent(props.calculations.gross_yield, 1)}
                    </p>
                </div>

                <div className="col-6 col-lg border-right p-3">
                    <h2 className="pi-font-size-14 pi-color-primary-gray d-flex align-items-center">
                        <span className="mr-1">Cap Rate</span>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-rent">
                                    The cap rate is used in the world of real estate to indicate the rate of return that is expected to be generated on a real estate investment property.
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="bg-transparent border-0 p-0">
                                <FontAwesomeIcon className="pi-font-size-14 pi-color-primary-gray" icon={faQuestionCircle}/>
                            </Button>
                        </OverlayTrigger>
                    </h2>
                    <p className="pi-font-size-20 mb-0">
                        {percent(props.calculations.cap_rate, 1)}
                    </p>
                </div>

                <div className="col-6 col-lg border-right p-3">
                    <h2 className="pi-font-size-14 pi-color-primary-gray d-flex align-items-center">
                        <span className="mr-1">Cash on Cash</span>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-rent">
                                    A cash-on-cash return is a rate of return often used in real estate transactions that calculates the cash income earned on the cash invested in a property.
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="bg-transparent border-0 p-0">
                                <FontAwesomeIcon className="pi-font-size-14 pi-color-primary-gray" icon={faQuestionCircle}/>
                            </Button>
                        </OverlayTrigger>
                    </h2>
                    <p className="pi-font-size-20 mb-0">
                        {percent(props.calculations.cash_on_cash, 1)}
                    </p>
                </div>

                <div className="col-6 col-lg p-3">
                    <h2 className="pi-font-size-14 pi-color-primary-gray d-flex align-items-center">
                        <span className="mr-1">Annualized Return</span>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-rent">
                                    An annualized total return is the average amount of money earned by an investment each year over a given time period.
                                    Also referred to as the internal rate of return (IRR).
                                </Tooltip>
                            }
                        >
                            <Button variant="primary" className="bg-transparent border-0 p-0">
                                <FontAwesomeIcon className="pi-font-size-14 pi-color-primary-gray" icon={faQuestionCircle}/>
                            </Button>
                        </OverlayTrigger>
                    </h2>
                    <p className="pi-font-size-20 mb-0">
                        {percent(props.calculations.annualized_return, 1)}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FinancialDetailsOverview;