import * as React from 'react';

import { useParams } from 'react-router-dom';
import { getProperty } from "../../../../Services/Properties/Properties";
import { getAsset } from "../../../../Services/Users/Assets";
import { Asset, Property } from "../../../../Types/Data";
import ImageCarousel from "./ImageCarousel";
import blankOfferingImage from "../../../../Assets/new_offering_page_image.png";
import ReturnOverview from "./ReturnOverview";
import SaleDetails from "./SaleDetails";
import { StoreState } from "../../../../Types/Store";
import '../Styles/PropertyView.css';

import { Spinner, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import PropertyTabs from "./PropertyTabs";
import { deleteFavoriteProperty, favoriteProperty } from "../../../../Services/Users/UserServices";

function PropertyView(): React.ReactElement {
    const [property, setProperty] = React.useState<Property>();
    const [asset, setAsset] = React.useState<Asset>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [perShare, setPerShare] = React.useState<boolean>(false);
    const [marketRent, setMarketRent] = React.useState<boolean>(false);
    const [isFavorite, setIsFavorite] = React.useState<boolean>(false);
    const signatureDocuments = useSelector((state: StoreState) => state.userInfo.signature_documents);
    const positions = useSelector((state: StoreState) => state.userInfo.positions);
    const favorites = useSelector((state: StoreState) => state.userInfo.favorites);

    const { id } = useParams();

    React.useEffect(() => {
        if (id) {
            Promise.all([getProperty(id), getAsset(id)]).then(res => {
                setProperty(res[0].data);
                setAsset(res[1].data);
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }
        if (favorites && id) {
            favorites.forEach(favorite => {
                if (favorite.llc.toString() === id) {
                    setIsFavorite(true);
                }
            });
        }
    }, [id, favorites]);

    function handleFavoriteClick(): void {
        if (property) {
            if (isFavorite) {
                deleteFavoriteProperty(property.llc).then(() => {
                   setIsFavorite(false);
                }).catch(err =>{
                    console.log(err);
                });
            } else {
                favoriteProperty(property.llc).then(() => {
                    setIsFavorite(true);
                }).catch(err =>{
                    console.log(err);
                });
            }
        }
    }

    if (isLoading) {
        return (
            <div className="d-flex h-100 justify-content-center">
                <Spinner className="align-self-center" animation="border" variant="dark" />
            </div>
        );
    }

    if (asset && property) {
        return (
            <div className="container py-3">
                <div className="d-flex mb-3 justify-content-between align-items-center">
                    <div className="">
                        <h1 className="pi-font-size-18 mb-1">{property ? property.address1 : 'TBD'}</h1>
                        { property && property.address2 ?
                            <div>{property.address2}</div> : null
                        }
                        { property &&
                            <div className="pi-color-primary-gray pi-font-size-16">
                                {`${property.city}, ${property.state} ${property.zip}`}
                            </div>
                        }
                    </div>

                    <div>
                        <Button
                            variant="link"
                            className={"pi-no-underline " + (isFavorite ? "pi-link-primary" : "pi-link-secondary")}
                            onClick={handleFavoriteClick}
                        >
                            <span>
                                Favorite
                                <FontAwesomeIcon icon={faHeart} className="ml-2" />
                            </span>
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <ImageCarousel
                            images={property ? property.images : [{location: blankOfferingImage, classifier: 'hero'}]} />
                    </div>

                    <div className="col-12 col-lg-6">
                        <div className="mb-3">
                            <SaleDetails
                                asset={asset}
                                signatureDocuments={signatureDocuments}
                                positions={positions}
                                perShareToggled={(): void => setPerShare(!perShare)}
                                marketRentToggle={(): void => setMarketRent(!marketRent)}
                                investmentType={property.investment_type}
                            />
                        </div>

                        <ReturnOverview
                            marketRent={marketRent}
                            property={property}
                            perShare={perShare}
                            totalShares={asset.total_shares}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <PropertyTabs
                            property={property}
                            perShare={perShare}
                            asset={asset}
                            marketRent={marketRent}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <p className="pi-color-primary-gray pi-font-size-12">
                            Certain information set forth herein contains “forward-looking information”, including “future-oriented
                            financial information” and “financial outlook”, under applicable securities laws (collectively referred
                            to herein as forward-looking statements). Except for statements of historical fact, the information
                            contained herein constitutes forward-looking statements. Forward-looking statements are provided to
                            allow potential investors the opportunity to understand management’s beliefs and opinions in respect
                            of the future so that they may use such beliefs and opinions as one factor in evaluating an investment.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return <div>Sorry there was an issue fetching data, please try again.</div>;
}

export default PropertyView;
