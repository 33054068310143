import * as React from 'react';

import { FinancialProjectionsGraphProps } from '../../../../Types/Features';
import '../Styles/FinancialProjectionsGraph.css';

import { ResponsiveBar } from '@nivo/bar';
import { currency } from '../../../../Shared/Pipes/Currency';

function FinancialProjectionsGraph(
    props: FinancialProjectionsGraphProps,
): React.ReactElement {
    function axisFormatter(value: any): string {
        if (props.perShare) {
            return currency(value, 0);
        }
        return `${currency(parseFloat(value) / 1000, 0)}k`;
    }

    return (
        <div className="w-100 pi-chart-container">
            <ResponsiveBar
                data={props.data}
                keys={props.keys}
                indexBy="year"
                margin={{ top: 10, right: 0, bottom: 100, left: 40 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#FFA600', '#05204A', '#6c757d']}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisLeft={{
                    format: (value: any): string => axisFormatter(value),
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 45,
                    legend: 'year',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 75,
                        itemsSpacing: 1,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                tooltip={(data) => (
                    <span>
                        {data.id} - {data.indexValue}:
                        <strong>{' ' + currency(data.value, 0)}</strong>
                    </span>
                )}
                animate={true}
            />
        </div>
    );
}

export default FinancialProjectionsGraph;
