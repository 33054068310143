import React from 'react';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {VerifyMicrodepositFormProps} from "../../Types/Features";
import { Formik } from "formik";
import { number, object, string } from "yup";

const MicrodepositSchema = object().shape({
    amount1: number()
        .min(0, 'Please enter a valid amount.')
        .required('Please enter a valid amount.')
        .positive('Please enter a postive amount.'),
    amount2: number()
        .min(0, 'Please enter a valid amount.')
        .required('Please enter a valid amount.')
        .positive('Please enter a postive amount.'),
    account: string()
        .required('Please select an account.')
});

const VerifyMicrodepositForm = (props: VerifyMicrodepositFormProps): React.ReactElement => {
	return(
		<Formik
			initialValues={{
              account: "",
              amount1: "0.00",
              amount2: "0.00",
          	}}
          	validationSchema={MicrodepositSchema}
          	onSubmit={(values): void => {
                props.handleSubmit(values);
          	}}
          	render={({
          		values,
              errors,
              touched,
              isValid, 
              dirty,
          		handleSubmit,
          		handleChange,
          		setFieldValue,
              isSubmitting
          	}): React.ReactElement => ( 
          		<Form onSubmit={handleSubmit}>
          			<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Account</p>
	          			<Form.Group className="w-50" controlId="accountSelect">
                    {errors.account && touched.account ? (
                      <span className="text-danger ml-2">{errors.account}</span>) : null}
	          				<Form.Control
			                        as="select"
			                        name="account"
			                        className="pi-font-size-14 pi-border-color-primary bg-transparent text-white"
			                        onChange={(e: any): void => {
			                           setFieldValue('account', e.target.value)
			                        }}
			                        value={values.account}>
			                    <option value={''}>Select an Account</option>
			                    { props.accounts.map(account =>
                            		<option value={account} key={account}>{account}</option>
                      			)}
			                 </Form.Control>
	          			</Form.Group>
	          		</div>
	          		<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Amount 1</p>
	                    <Form.Group className="w-50" controlId="amount1">
                        {errors.amount1 && touched.amount1 ? (
                          <span className="text-danger ml-2">{errors.amount1}</span>) : null}
	                    	<Form.Control
                              type="number"
                              name="amount1"
                              placeholder=""
                              className="text-right pi-border-color-primary bg-transparent text-white"
                              onChange={handleChange}
                              value={values.amount1}
                             />
	                    </Form.Group>
	          		</div>
	          		<div className="d-flex justify-content-between align-items-center">
	          			<p className="text-white pi-font-size-14">Amount 2</p>
	                    <Form.Group className="w-50" controlId="amount2">
                        {errors.amount2 && touched.amount2 ? (
                          <span className="text-danger ml-2">{errors.amount2}</span>) : null}
	                    	<Form.Control
                              type="number"
                              name="amount2"
                              placeholder=""
                              className="text-right pi-border-color-primary bg-transparent text-white"
                              onChange={handleChange}
                              value={values.amount2}
                            />
	                    </Form.Group>
	          		</div>
	          		<Button
		                className="pi-bg-color-primary border-0 w-100 py-3"
		                type="submit"
                    disabled={!(isValid && dirty) || isSubmitting}
		            >
		            	Verify Deposits
		            </Button>
          		</Form>
          	)}
		/>
	);
}

export default VerifyMicrodepositForm;