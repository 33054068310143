import { FaqList } from "../../../Types/Features";

export const aboutList: FaqList[] = [
    {
        id: 1,
        title: "What is PeerInvest?",
        body: `PeerInvest is an online real estate investment platform. Our goal is to increase access 
        to real estate investing. However, we see three main barriers to entry, high initial capital requirements, 
        substantial sweat equity, and experience. We strive to charge low and transparent fees, lower minimums, 
        and provide an experienced team to manage assets.`
    },
    {
        id: 2,
        title: "What am I investing in?",
        body: `The PeerInvest platform provides a catalog of private real estate projects, managed and 
        operated by our experienced team. You will be able to choose one or many projects that best fit
        your financial goals.`
    },
    {
        id: 3,
        title: "How do I contact PeerInvest?",
        body: `Feel free to head over to our contact form or send us an email directly at support@peerinvest.io!`
    },
    {
        id: 4,
        title: "Why PeerInvest?",
        body: `Private real estate investments have historically shown to outperform public markets with less 
        volatility. Many individual investors have been restricted from participating in private real estate 
        markets and this is where we come in. We look to provide individual investors with access to attractive 
        private real estate investments allowing them to build a diversified portfolio of individual projects 
        at a low cost.`
    }
]

export const gettingStartedList: FaqList[] = [
    {
        id: 1,
        title: "What is the minimum initial investment?",
        body: `Get started for as low as $500. We plan to lower our minimums over time as we scale our platform.`
    },
    {
        id: 2,
        title: "Can I invest in a specific project?",
        body: `Absolutely, that is what we are all about. You can invest in one or many projects and build a custom 
        portfolio that best fits your financial goals.`
    },
    {
        id: 3,
        title: "Who can invest?",
        body: `We strive to allow anyone to invest. Anyone can sign up to use our platform, however, we are required 
        to follow all applicable regulations and rules which may restrict some users from investing. We are constantly 
        working to open access to private real estate and will provide any updates we have along the way.`
    },
    {
        id: 4,
        title: "Do I need to be an accredited investor?",
        body: `Yes, investors must be accredited. We seek to open private real estate markets to everyone and are working 
        diligently to bring our product to unaccredited investors.`
        // body: `Absolutely not. We seek to open private real estate markets to everyone. Unaccredited investors must
        // qualify as sophisticated—that is, they must have sufficient knowledge and experience in financial and business
        // matters to make them capable of evaluating the merits and risks of the prospective investment`
    },
    {
        id: 5,
        title: "What are PeerInvest's fees?",
        body: `We charge a 1% acquisition fee, 2% of gross monthly rental revenue, and a 1% sale fee. Most real estate 
        investors take a portion of the profits, but we leave that all to you.`
    },
    {
        id: 6,
        title: "How can I earn returns on my investment?",
        body: `PeerInvest projects are long term, illiquid investments. There are three forms of returns when investing 
        in our projects, cash flow, mortgage pay down, appreciation. Cash flow is the amount of money generated from 
        rents after all expenses have been paid which is paid out to investors quarterly (every three months). Mortgage 
        pay down comes from the portion of loan principal paid in each monthly mortgage payment. Appreciation is the 
        change in the overall value of the property. Mortgage pay down and appreciation increase NAV and is paid out if 
        the property is sold.`
    },
    {
        id: 7,
        title: "What is NAV?",
        body: `NAV = Net Asset Value. This is the value of the property after all liabilities are subtracted out. NAV 
        = Assets - Liabilities.`
    },
    {
        id: 8,
        title: "How often is NAV updated?",
        body: `NAV (Net Asset Value) is updated quarterly (every three months) based on the amount of loan principal paid down over that 
        time. The property may be reappraised periodically which may also cause a change in NAV.`
    },
    {
        id: 9,
        title: "How often are dividends paid out?",
        body: `Quarterly (every three months).`
    },
    {
        id: 10,
        title: "Can international investors invest?",
        body: `Unfortunately, at the moment, only US based investors can invest on our platform. We are working to open our
        platform to everyone, so stay tuned.`
    }
]

export const ourInvestmentsList: FaqList[] = [
    {
        id: 1,
        title: "What kinds of investments can I choose from?",
        body: `We look to provide a variety of buy and hold long term investment opportunities. Sign up and browse the 
        array of projects we offer!`
    },
    {
        id: 2,
        title: "Am I liable for the debt?",
        body: `No! Investors can not lose more than their initial investment in any given property.`
    }
]

export const taxesList: FaqList[] = [
    {
        id: 1,
        title: "How do taxes work on our platform?",
        body: `Similar to if you owned the property outright, many of the tax benefits flow through directly to you. 
        Each investor is provided a Schedule K-1 at the beginning of the year. The schedule K-1 is a tax document 
        used to report a member's share of the profits, losses, and dividends of the LLC.`
    }
]
