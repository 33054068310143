import * as React from 'react';
import { PortfolioTableProps } from '../../../Types/Features';
import { currency } from '../../../Shared/Pipes/Currency';
import { getGainLoss, getTotalDividends } from '../../../Utils/Calculations';
import { percent } from '../../../Shared/Pipes/Percent';
import { Position } from '../../../Types/Data';
import './Styles/PortfolioTable.css';
import PositionDetail from './PositionDetail';

function PortfolioTable(props: PortfolioTableProps): React.ReactElement {
    const [positionDetailIds, setPositionDetailIds] = React.useState<number[]>(
        [],
    );

    function handlePositionClick(id: number): void {
        if (positionDetailIds.includes(id)) {
            setPositionDetailIds(
                positionDetailIds.filter((newId) => newId !== id),
            );
        } else {
            setPositionDetailIds(positionDetailIds.concat(id));
        }
    }

    return (
        <div className="overflow-auto">
            <div className="row responsive-row py-2 mx-0">
                <div className="col-2">
                    <span className="font-weight-bold">Symbol</span>
                </div>

                <div className="col-2">
                    <span className="font-weight-bold">Shares</span>
                </div>

                <div className="col-2">
                    <span className="font-weight-bold">Purchase Price</span>
                </div>

                <div className="col-2">
                    <span className="font-weight-bold">Estimated Value</span>
                </div>

                <div className="col-2">
                    <span className="font-weight-bold">Dividends Paid</span>
                </div>

                <div className="col-2">
                    <span className="font-weight-bold">Estimated G/L</span>
                </div>
            </div>

            {props.positions.map((pos: Position, index: number) => (
                <div
                    className={
                        'row py-3 mx-0 responsive-row table-row-hover ' +
                        (index % 2 === 0 ? 'pi-bg-color-light-gray' : '')
                    }
                    key={pos.id}
                    onClick={() =>
                        pos.asset.symbol === 'cash'
                            ? null
                            : handlePositionClick(pos.id)
                    }>
                    <div className="col-2">
                        {pos.asset.symbol === 'cash'
                            ? 'Cash'
                            : pos.asset.symbol}
                    </div>
                    <div className="col-2">{pos.num_shares}</div>
                    <div className="col-2">{currency(pos.cost)}</div>
                    <div className="col-2">
                        {currency(pos.asset.market_price)}
                    </div>
                    <div className="col-2">
                        {currency(getTotalDividends(pos) / pos.num_shares)}
                    </div>
                    <div className="col-2">
                        {pos.asset.symbol === 'cash'
                            ? '--'
                            : percent(getGainLoss(pos), 2)}
                    </div>

                    {positionDetailIds.includes(pos.id) && (
                        <div className="col-12 mt-3 border-top pt-3">
                            <PositionDetail position={pos} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default PortfolioTable;
