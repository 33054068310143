import { Asset } from "../../Types/Data";

import axios, { AxiosResponse } from "axios";

export function getAsset(id: string): Promise<AxiosResponse<Asset>> {
    return axios.get(`${process.env.REACT_APP_API_URL}market/asset?id=${id}`);
}

export function getAssets(): Promise<AxiosResponse<Asset[]>> {
    return axios.get(`${process.env.REACT_APP_API_URL}market/assets`);
}
