import { Position, Transaction, TransactionType } from "../Types/Data";
import { flatMap, filter, orderBy, map, groupBy, sumBy } from "lodash";
import { Serie } from "@nivo/line";

export function consolidateTransactions(positions: Position[]): Transaction[] {
    return flatMap(positions, (pos: Position) => pos.transactions);
}

export function dividendTransactions(transactions: Transaction[]): Transaction[] {
    return filter(transactions, {transaction_type: TransactionType.Dividend});
}

export function nextDividend(dividendTransactions: Transaction[]): Transaction {
    const futureDividendTransactions = filter(dividendTransactions, trx => new Date(trx['transact_date']) >= new Date());
    return orderBy(futureDividendTransactions, ['transact_date'], ['asc'])[0];
}

export function formatDividendChartData(dividendTransactions: Transaction[]): Serie[] {
    function dataFormatter(div: Transaction): {'x': Date; 'y': number} {
        return {'x': new Date(div.transact_date), 'y': div.num_shares * div.cost}
    }

    function dataGrouper(value: any[], key: Date): {'x': Date; 'y': number} {
        return {
            'x': key,
            'y': sumBy(value, 'y')
        };
    }

    const data = map(dividendTransactions, dataFormatter);
    const groupedData = groupBy(data, 'x');
    const formattedData: any[] = map(groupedData, dataGrouper);

    return [{
        id: 'dividends',
        color: "hsl(36.1, 100%, 67.5%)",
        data: formattedData
    }];
}