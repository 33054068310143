import { addToken, removeToken } from "../Utils/AuthUtils";
import { Post, Property } from "../../Types/Data";

import axios, { AxiosResponse } from "axios";
import { sumBy } from "lodash";

export function getProperties(): Promise<AxiosResponse<Property[]>> {
    return axios.get(`${process.env.REACT_APP_API_URL}assets/property/`);
}

export function getProperty(llc: string): Promise<AxiosResponse<Property>> {
    return axios.get(`${process.env.REACT_APP_API_URL}assets/property/get/?llc=${llc}`);
}

export function getPortfolio(llcs: number[]): Promise<AxiosResponse<Property[]>> {
    return axios.get(`${process.env.REACT_APP_API_URL}assets/property/portfolio/?ids=${llcs}`);
}

export function getLatestProperties(): Promise<AxiosResponse<Property[]>> {
    const token = removeToken();

    const request: Promise<AxiosResponse<Property[]>> =
        axios.get(`${process.env.REACT_APP_API_URL}assets/property/latest/`);

    addToken(token);

    return request;
}

export function totalSquareFeet(property: Property): number {
    return sumBy(property.units, (unit) => { return unit.sq_foot });
}

export function submitPost(post: string, property_id: number): Promise<AxiosResponse<Post[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}assets/property/post/`,
            {
                property_id: property_id,
                post: post
            }
        )
}

export function submitReply(reply: string, post_id: number, property_id: number): Promise<AxiosResponse<Post[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}assets/property/reply/`,
        {
            property_id: property_id,
            post_id: post_id,
            reply: reply
        }
    )
}
