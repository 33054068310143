import * as React from "react";

import "../Styles/MapMarker.css";

function MapMarker(): React.ReactElement {
    return (
        <div className="pi-map-marker pi-bg-color-primary"></div>
    )
}

export default MapMarker;