import axios, { AxiosResponse } from "axios";
import { KBAQuestions, PlaidAccount } from "../../Types/Data";
import { DwollaKBABody } from "../../Types/Services";

export function getAccounts(): Promise<AxiosResponse<PlaidAccount[][]>> {
    const request = axios.post(`${process.env.REACT_APP_API_URL}users/auth/plaid_account_info`);
    return request;
}

export function removeAccount(account: string): Promise<AxiosResponse<string[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/auth/remove_plaid_account`, {
        account: account
    });
}

export function removeDwollaAccount(account: string): Promise<AxiosResponse<string[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/delete_dwolla_funding`, {
        account_mask: account
    });
}

export function transactMoney(account: string, transaction: string, amount: number): Promise<AxiosResponse<string[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}market/transact_money/`, {
        account_id: account,
        transaction: transaction,
        cash_amount: amount
    });
}

export function postVerifyMicrodeposit(source_name: string,
                                       amount1: number,
                                       amount2: number): Promise<AxiosResponse<string>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/verify_microdeposit`, {
        source_name: source_name,
        amount1: amount1,
        amount2: amount2,
    });
}

export function createMicrodeposit(routing_number: number,
                                       account_number: number,
                                       account_type: string,
                                       bank_name: string): Promise<AxiosResponse<string>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/create_with_microdeposit`, {
        routing_number: routing_number,
        account_number: account_number,
        account_type: account_type,
        bank_name: bank_name
    });
}

export function getDwollaFundingSource(verified: string): Promise<AxiosResponse<string[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/dwolla_funding_sources`, {
        verified: verified
    });
}

export function getDwollaBalanceAmount(): Promise<AxiosResponse<number>>{
    return axios.get(`${process.env.REACT_APP_API_URL}users/dwolla_balance_amount`);
}

export function createDwollaFunding(account: string, item: string): Promise<AxiosResponse<string[]>> {
    return axios.post(`${process.env.REACT_APP_API_URL}users/create_dwolla_funding/`, {
        account_mask: account,
        item_id: item
    });
}

export function plaidPublicToken(public_token: string): Promise<AxiosResponse<string>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/auth/public_token',{
        public_token: public_token
    });
}

export function plaidLinkToken(): Promise<AxiosResponse<string>> {
    return axios.get(process.env.REACT_APP_API_URL + 'users/plaid_link_token');
}

export function getDwollaKBA(): Promise<AxiosResponse<KBAQuestions>> {
    return axios.get(process.env.REACT_APP_API_URL + 'users/fetch_kba_questions')
}

export function submitDwollaKBA(body: DwollaKBABody): Promise<AxiosResponse<string>> {
    return axios.post(process.env.REACT_APP_API_URL + 'users/submit_kba_questions', body)
}
