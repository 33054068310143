import {
    faInfoCircle,
    faLandmark,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '../../Types/Shared';

export const menuItems: MenuItem[][] = [
    [
        {
            id: 0,
            text: 'Personal Information',
            icon: faInfoCircle,
            route: '/information',
        },
        {
            id: 1,
            text: 'Banking',
            icon: faLandmark,
            route: '/banking',
        },
        {
            id: 2,
            text: 'Security',
            icon: faLock,
            route: '/security',
        },
    ],
    // {
    //     id: 3,
    //     text: "Preferences",
    //     icon: faHeart,
    //     route: "/preferences"
    // }
];
