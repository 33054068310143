import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/Dashboard.css';
import SidebarMenu from '../../Shared/SidebarMenu/SidebarMenu';
import DashboardRoutes from '../../Routes/DashboardRoutes';
import { menuItems } from '../../Shared/DashboardNav/DashboardMenuItems';
import {
    DashboardProps,
    DashboardState,
    InvestmentProfile,
} from '../../Types/Features';
import { NewProperties, NewUpdates, StoreState } from '../../Types/Store';
import DashboardNav from '../../Shared/DashboardNav/DashboardNav';
import HomeFooter from '../../Shared/HomeFooter/HomeFooter';
import { BREAKPOINTS } from '../../Utils/Breakpoints';

import { Redirect } from 'react-router-dom';

import { flatMap } from 'lodash';

import { connect } from 'react-redux';
import { Property, UserInfo } from '../../Types/Data';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { getProperties } from '../../Services/Properties/Properties';

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);
        this.state = {
            toLogin: false,
            sidebarActive: true,
        };

        this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
        this.handleMobileSidebarToggle =
            this.handleMobileSidebarToggle.bind(this);
    }

    componentDidMount(): void {
        if (window.innerWidth < BREAKPOINTS.lg) {
            this.setState({
                sidebarActive: false,
            });
        }

        if (this.props.properties && !this.props.properties.length) {
            getProperties().then((res) => {
                this.props.setProperties(res.data);
            });
        }
    }

    componentDidUpdate() {
        if (this.props.properties && !this.props.properties.length) {
            getProperties().then((res) => {
                this.props.setProperties(res.data);
            });
        }
    }

    handleSidebarToggle(): void {
        this.setState({
            sidebarActive: !this.state.sidebarActive,
        });
    }

    handleMobileSidebarToggle(): void {
        if (window.innerWidth < BREAKPOINTS.lg) {
            this.setState({
                sidebarActive: !this.state.sidebarActive,
            });
        }
    }

    render(): ReactElement {
        if (this.state.toLogin) {
            return <Redirect to={'/auth/login'} />;
        }

        const investmentProfile: InvestmentProfile = {
            positions: this.props.userInfo['positions'],
            newsUpdates: [],
            documents: this.props.userInfo['documents'],
            llcs: flatMap(
                this.props.userInfo['positions'],
                (pos) => pos.asset.llc,
            ),
        };

        return (
            <div>
                <div className="container-fluid d-flex flex-column px-0 pi-custom-container">
                    <div className="row flex-grow-1 mx-0">
                        {this.state.sidebarActive && (
                            <div className="pi-sidebar-flex px-0">
                                <SidebarMenu
                                    onSidebarToggle={
                                        this.handleMobileSidebarToggle
                                    }
                                    menuItems={menuItems}
                                    propertyCount={this.props.properties.length}
                                />
                            </div>
                        )}
                        <div
                            className={
                                'px-0 ' +
                                (this.state.sidebarActive
                                    ? 'pi-container-flex '
                                    : 'col-12 ') +
                                ((!this.state.sidebarActive &&
                                    window.innerWidth < BREAKPOINTS.lg) ||
                                window.innerWidth > BREAKPOINTS.lg
                                    ? 'd-block'
                                    : 'd-none')
                            }>
                            <DashboardNav
                                onSidebarToggle={this.handleSidebarToggle}
                                hasMenu={true}
                            />
                            <div className="overflow-auto pi-scroll h-100">
                                {this.props.hasCheckedAuth ? (
                                    <DashboardRoutes
                                        investmentProfile={investmentProfile}
                                        llcs={investmentProfile.llcs}
                                        isAuthenticated={
                                            !!this.props.userInfo.first_name
                                        }
                                    />
                                ) : (
                                    <div className="d-flex h-100 justify-content-center">
                                        <Spinner
                                            className="align-self-center"
                                            animation="border"
                                            variant="dark"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: StoreState,
): {
    userInfo: UserInfo;
    cookies: Cookies;
    hasCheckedAuth: boolean;
    properties: Property[];
} => {
    return {
        userInfo: state.userInfo,
        cookies: state.cookies,
        hasCheckedAuth: state.hasCheckedAuth,
        properties: state.properties,
    };
};

const mapDispatchToProps = (dispatch: any): { setProperties: any } => {
    return {
        setProperties: (properties: NewProperties): any =>
            dispatch({ type: 'SET_PROPERTIES', newProperties: properties }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
