import * as React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RefinanceAnalysisProps } from "../../../../Types/Features";
import { currency } from "../../../../Shared/Pipes/Currency";
import { percent } from "../../../../Shared/Pipes/Percent";

function RefinanceAnalysis(props: RefinanceAnalysisProps): React.ReactElement {
    const [index, setIndex] = React.useState<number>(0);

    function cashOut(): number {
        const loanToValue: number = props.property.property_type === 'MF' ? 0.25 : 0.2;
        const after_repair_value: number = index === 0 ? props.property.estimated_after_repair_value : props.property.actual_after_repair_value;
        const rehab_amount: number = index === 0 ? props.property.rehab_budget : props.property.actual_rehab_cost;
        const closing_costs: number = index === 0 ? props.property.estimated_refinance_closing_costs : props.property.actual_refinance_closing_costs;
        return after_repair_value * loanToValue - rehab_amount - closing_costs;
    }

    return (
        <div className="border rounded p-3 shadow-sm h-100">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-3">
                Refinance Analysis
            </h2>

            <div className="d-flex border rounded mb-3">
                <div className="col-6 px-0">
                    <Button
                        variant={index === 0 ? "primary" : "light"}
                        onClick={(): void => setIndex(0)}
                        className={"p-2 w-100 border-0 rounded-0 rounded-left " + (index === 0 ? "pi-bg-color-primary text-white" : "bg-transparent text-black")}>
                        Estimated</Button>
                </div>

                <div className="col-6 px-0">
                    <Button
                        variant={index === 1 ? "primary" : "light"}
                        onClick={(): void => setIndex(1)}
                        disabled={!props.property.actual_after_repair_value}
                        className={"p-2 w-100 border-0 rounded-0 " + (index === 1 ? "pi-bg-color-primary text-white" : "bg-transparent text-black")}>
                        Actual</Button>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">After Rehab Value (ARV)</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The estimated value of the property after all rehab work has been completed.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                    {currency(index === 0 ? props.property.estimated_after_repair_value : props.property.actual_after_repair_value, 2)}
                </span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Loan to Value</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The percentage of the after rehab value that will be financed.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                    {percent(props.property.property_type === 'MF' ? 0.75 : 0.80, 0)}
                </span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Rehab Estimate</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The expected cost of rehab work to be done in order to realize the estimated ARV.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                    {currency(index === 0 ? props.property.rehab_budget : props.property.actual_rehab_cost, 2)}
                </span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3 pb-2 border-bottom">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Closing Costs</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The expected closing costs due during the refinancing of the loan.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">
                    {currency(index === 0 ? props.property.estimated_refinance_closing_costs : props.property.actual_refinance_closing_costs, 2)}
                </span>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                    <h2 className="pi-font-size-16 mb-0 mr-2">Net Proceeds</h2>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-rent">
                                The expected cash out from the refinance after completed rehabs and unit occupancy.
                            </Tooltip>
                        }
                    >
                        <Button variant="primary" className="bg-transparent border-0 p-0">
                            <FontAwesomeIcon className="pi-font-size-16 pi-color-primary-gray" icon={faQuestionCircle}/>
                        </Button>
                    </OverlayTrigger>
                </div>
                <span className="pi-font-size-18 pi-color-primary-gray">{currency(cashOut(), 2)}</span>
            </div>
        </div>
    )
}

export default RefinanceAnalysis;
