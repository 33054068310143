import * as React from 'react';

import { ContactUsFormProps } from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import { object, string } from "yup";

const ContactUsSchema = object().shape({
    email: string()
        .email('Invalid email')
        .required('*Required'),
    name: string()
        .required('*Required'),
    message: string()
        .required('*Required')
});

function buttonText(asyncIsSubmitting: boolean, success: boolean): string {
    if (asyncIsSubmitting) {
        return 'Sending...';
    } else if (success) {
        return 'Message Received!';
    } else {
        return 'Submit';
    }
}

const ContactUsForm = (props: ContactUsFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                message: ''
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
            }}
            validationSchema={ContactUsSchema}
            render={({
                 errors,
                 touched,
                 values,
                 handleSubmit,
                 handleChange
            }): React.ReactElement => (
                <div>
                    <Form className={props.classes} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label className="pi-font-size-20">
                                Name
                                {errors.name && touched.name ? (
                                    <span className="text-danger ml-3">{errors.name}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="pi-font-size-20">
                                Email
                                {errors.email && touched.email ? (
                                    <span className="text-danger ml-3">{errors.email}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label className="pi-font-size-20">
                                Message
                                {errors.message && touched.message ? (
                                    <span className="text-danger ml-3">{errors.message}</span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="message"
                                type="text"
                                as="textarea"
                                rows={7}
                                value={values.message}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button type="submit" className="w-100" variant="secondary" disabled={props.asyncIsSubmitting}>
                            {buttonText(props.asyncIsSubmitting, props.success)}
                        </Button>
                    </Form>
                </div>
            )}
        />
    )
};

export default ContactUsForm;
