import * as React from 'react';

import { states } from '../../Assets/states';
import { MailingAddressFormProps } from '../../Types/Shared';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Formik } from 'formik';
import { string, number, object } from 'yup';

import Col from 'react-bootstrap/Col';

const MailingAddressSchema = object().shape({
    address1: string().required('*Required'),
    address2: string(),
    city: string().required('*Required'),
    state: string().required('*Required'),
    zip: number()
        .positive('Zip codes can only be positive numbers!')
        .integer('Zip codes cannot contain decimals!')
        .required('*Required'),
});

const MailingAddressForm = (
    props: MailingAddressFormProps,
): React.ReactElement => {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.settings}
            onSubmit={(values, actions): void => {
                props.handleSave(values);
                actions.setSubmitting(false);
            }}
            validationSchema={MailingAddressSchema}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="mailingAddressForm1">
                        <Form.Label>
                            Address
                            {errors.address1 === '*Required' &&
                            touched.address1 ? (
                                <span className="text-danger ml-2">
                                    {errors.address1}
                                </span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="address1"
                            type="text"
                            placeholder="Street address"
                            onChange={handleChange}
                            value={values.address1}
                        />
                        {errors.address1 !== '*Required' && touched.address1 ? (
                            <span className="text-danger">
                                {errors.address1}
                            </span>
                        ) : null}
                    </Form.Group>

                    <Form.Group controlId="mailingAddressForm2">
                        <Form.Label>
                            Address 2
                            {errors.address2 === '*Required' &&
                            touched.address2 ? (
                                <span className="text-danger ml-2">
                                    {errors.address2}
                                </span>
                            ) : null}
                        </Form.Label>
                        <Form.Control
                            name="address2"
                            type="text"
                            placeholder="Apartment, studio, or floor"
                            onChange={handleChange}
                            value={values.address2}
                        />
                        {errors.address2 !== '*Required' && touched.address2 ? (
                            <span className="text-danger">
                                {errors.address2}
                            </span>
                        ) : null}
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="mailingAddressForm3">
                            <Form.Label>
                                City
                                {errors.city === '*Required' && touched.city ? (
                                    <span className="text-danger ml-2">
                                        {errors.city}
                                    </span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="city"
                                type="text"
                                onChange={handleChange}
                                value={values.city}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="mailingAddressForm4">
                            <Form.Label>
                                State
                                {errors.state === '*Required' &&
                                touched.state ? (
                                    <span className="text-danger ml-2">
                                        {errors.state}
                                    </span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="state"
                                as="select"
                                onChange={handleChange}
                                value={values.state}>
                                {states.map((state) => (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="mailingAddressForm5">
                            <Form.Label>
                                Zip Code
                                {errors.zip === '*Required' && touched.zip ? (
                                    <span className="text-danger ml-2">
                                        {errors.zip}
                                    </span>
                                ) : null}
                            </Form.Label>
                            <Form.Control
                                name="zip"
                                type="number"
                                onChange={handleChange}
                                value={values.zip}
                            />
                        </Form.Group>
                    </Form.Row>

                    {errors.city !== '*Required' && touched.city ? (
                        <div className="text-danger">{errors.city}</div>
                    ) : null}

                    {errors.state !== '*Required' && touched.state ? (
                        <div className="text-danger">{errors.state}</div>
                    ) : null}

                    {errors.zip !== '*Required' && touched.zip ? (
                        <div className="text-danger">{errors.zip}</div>
                    ) : null}

                    <Button
                        className="w-100 pi-primary-btn"
                        disabled={isSubmitting}
                        type="submit">
                        Save Mailing Address
                    </Button>
                </Form>
            )}
        />
    );
};

export default MailingAddressForm;
