import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/Transactions.css';
import { currency } from '../../../Shared/Pipes/Currency';
import { date } from '../../../Shared/Pipes/Date';
import IconButton from '../../../Shared/BasicComponents/IconButton';
import TransactionsFilterForm from '../../../Shared/Forms/TransactionsFilterForm';
import { TransactionsProps, TransactionState } from '../../../Types/Features';
import { Transaction } from '../../../Types/Data';

import ListGroup from 'react-bootstrap/ListGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { orderBy } from 'lodash';
import { filter } from 'lodash';

class Transactions extends React.Component<
    TransactionsProps,
    TransactionState
> {
    constructor(props: TransactionsProps) {
        super(props);
        this.state = {
            filteredTransactions: orderBy(
                props.transactions,
                [(trx): Date => new Date(trx['transact_date'])],
                ['asc'],
            ),
            ascendingDate: true,
        };

        this.handleDateOrderClick = this.handleDateOrderClick.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<TransactionsProps>): void {
        if (this.props.transactions.length !== prevProps.transactions.length) {
            this.setState({
                filteredTransactions: orderBy(
                    this.props.transactions,
                    [(trx): Date => new Date(trx['transact_date'])],
                    ['asc'],
                ),
            });
        }
    }

    handleDateOrderClick(): void {
        this.setState({
            filteredTransactions: this.sortByOrder(
                !this.state.ascendingDate,
                this.state.filteredTransactions,
            ),
            ascendingDate: !this.state.ascendingDate,
        });
    }

    handleFilterSubmit(values: { timeframe: string; type: string }): void {
        const timeFilteredTransactions = this.timeFilter(
            new Date(values.timeframe),
        );
        const typeFilteredTransactions = this.typeFilter(
            values.type,
            timeFilteredTransactions,
        );
        const sortedFilteredTransactions = this.sortByOrder(
            this.state.ascendingDate,
            typeFilteredTransactions,
        );

        this.setState({
            filteredTransactions: sortedFilteredTransactions,
        });
    }

    timeFilter(time: Date): Transaction[] {
        return filter(
            this.props.transactions,
            (trx) => new Date(trx['transact_date']) > new Date(time),
        );
    }

    typeFilter(type: string, transactions: Transaction[]): Transaction[] {
        if (type !== 'ALL') {
            return transactions.filter(
                (transaction) => transaction.transaction_type === type,
            );
        }
        return transactions;
    }

    sortByOrder(
        ascending: boolean,
        transactions: Transaction[],
    ): Transaction[] {
        if (ascending) {
            return orderBy(
                transactions,
                [(trx): Date => new Date(trx['transact_date'])],
                ['asc'],
            );
        } else {
            return orderBy(
                transactions,
                [(trx): Date => new Date(trx['transact_date'])],
                ['desc'],
            );
        }
    }

    getStatus(type: string): string {
        switch (type) {
            case 'pn':
                return 'Pending';
            case 'fl':
                return 'Filled';
            case 'st':
                return 'Settled';
            case 'cn':
                return 'Canceled';
            case 'fn':
                return 'Finalized';
            case 'rl':
                return 'Rollback';
            default:
                return '';
        }
    }

    render(): ReactElement {
        return (
            <div className="container-fluid py-3 h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-12 h-100">
                        <div className="card pt-3 pb-3 mb-3 shadow-sm">
                            <h1 className="pi-font-size-20 pl-3 pr-3">
                                Transactions
                            </h1>

                            <div className="p-3 border-top">
                                <TransactionsFilterForm
                                    classes=""
                                    handleFilterSubmit={this.handleFilterSubmit}
                                />
                            </div>

                            <ListGroup
                                className="pi-responsive-list"
                                variant="flush">
                                <ListGroup.Item className="pi-responsive-list-item">
                                    <div className="row">
                                        <div className="col d-flex align-items-center">
                                            <h2 className="pi-font-size-16 mb-0">
                                                Date & Time
                                            </h2>
                                            <IconButton
                                                classes="text-black"
                                                onClick={
                                                    this.handleDateOrderClick
                                                }>
                                                <FontAwesomeIcon
                                                    icon={
                                                        this.state.ascendingDate
                                                            ? faCaretUp
                                                            : faCaretDown
                                                    }
                                                />
                                            </IconButton>
                                        </div>

                                        <div className="col">
                                            <h2 className="pi-font-size-16 mb-0">
                                                Type
                                            </h2>
                                        </div>

                                        <div className="col">
                                            <h2 className="pi-font-size-16 mb-0">
                                                Status
                                            </h2>
                                        </div>
                                        <div className="col">
                                            <h2 className="pi-font-size-16 mb-0">
                                                Shares / Amount
                                            </h2>
                                        </div>

                                        <div className="col">
                                            <h2 className="pi-font-size-16 mb-0">
                                                Total
                                            </h2>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                {this.state.filteredTransactions.map((trx) => (
                                    <ListGroup.Item
                                        className="pi-responsive-list-item"
                                        key={trx.id}>
                                        <div className="row">
                                            <div className="col">
                                                {date(
                                                    trx['transact_date'],
                                                    'mm/dd/yyyy',
                                                )}
                                            </div>

                                            <div className="col">
                                                {trx.transaction_type}
                                            </div>

                                            <div className="col">
                                                {this.getStatus(
                                                    trx.status.toLowerCase(),
                                                )}
                                            </div>

                                            <div className="col">
                                                {trx['num_shares'] +
                                                    ' / ' +
                                                    currency(trx.cost)}
                                            </div>

                                            <div className="col">
                                                {currency(
                                                    trx['num_shares'] *
                                                        trx.cost,
                                                    2,
                                                )}
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Transactions;
