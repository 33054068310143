import * as React from 'react';

import './Styles/IconButton.css';
import { IconButtonProps } from "../../Types/Shared";

function IconButton(props: IconButtonProps): React.ReactElement {
    return (
        <button
            type={props.type ? props.type : 'button'}
            className={
                'icon-button-base ' +
                (props.classes || ' text-white fa-button') +
                (props.disable ? ' pi-bg-color-secondary-gray border-0' : '')
            }
            onClick={props.onClick}
            disabled={props.disable}
        >
            {props.children}
        </button>
    )
}

export default IconButton;