import * as React from 'react';

import { SignUpFormProps } from '../../Types/Shared';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Formik } from 'formik';
import { string, object, boolean } from 'yup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '../BasicComponents/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const SignUpSchema = object().shape({
    firstName: string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('*Required'),
    lastName: string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('*Required'),
    email: string().email('Invalid email').required('*Required'),
    password: string()
        .required('*Required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(^.{12,30}$)/,
            'Password does not meet the requirements',
        ),
    agree: boolean().oneOf(
        [true],
        'You must agree to the terms of service and privacy policy.',
    ),
});

const SignUpForm = (props: SignUpFormProps): React.ReactElement => {
    function handleIconClick(): void {
        props.handleIconClick();
    }

    return (
        <Formik
            initialValues={{
                firstName: props.values.firstName,
                lastName: props.values.lastName,
                email: props.values.email,
                isAccredited: props.values.isAccredited,
                password: props.values.password,
                agree: false,
            }}
            validationSchema={SignUpSchema}
            onSubmit={(values, actions): void => {
                props.handleSignUp(values);
                actions.setSubmitting(false);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group controlId="createAccountForm1">
                                {errors.firstName && touched.firstName ? (
                                    <span className="text-danger ml-2">
                                        {errors.firstName}
                                    </span>
                                ) : null}
                                <Form.Control
                                    className="h-auto"
                                    name="firstName"
                                    type="text"
                                    placeholder="Legal first name"
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="createAccountForm2">
                                {errors.lastName && touched.lastName ? (
                                    <span className="text-danger ml-2">
                                        {errors.lastName}
                                    </span>
                                ) : null}
                                <Form.Control
                                    className="h-auto"
                                    name="lastName"
                                    type="text"
                                    placeholder="Legal last name"
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="createAccountForm3">
                        {errors.email && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                        ) : null}
                        <Form.Control
                            className="h-auto"
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            value={values.email}
                        />
                    </Form.Group>

                    <Form.Group controlId="createAccountForm4">
                        {errors.password === '*Required' && touched.password ? (
                            <span className="text-danger ml-2">
                                {errors.password}
                            </span>
                        ) : null}
                        <Form.Control
                            className="h-auto"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={values.password}
                        />
                        {errors.password !== '*Required' && touched.password ? (
                            <span className="text-danger">
                                {errors.password}
                            </span>
                        ) : null}
                        <p className="pi-font-size-14 pi-color-primary-gray mt-2">
                            Must be between 12 and 30 characters and contain one
                            uppercase, one lowercase, one number and one special
                            case character
                        </p>
                    </Form.Group>

                    <Form.Group controlId="createAccountForm5">
                        <Form.Check
                            className="d-inline"
                            type="checkbox"
                            label="I qualify as an accredited investor"
                            name="isAccredited"
                            onChange={handleChange}
                            checked={values.isAccredited}
                        />
                        <IconButton
                            classes="pi-color-primary-gray"
                            onClick={handleIconClick}>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </IconButton>
                    </Form.Group>

                    <Form.Group controlId="createAccountForm6">
                        <div className="border-bottom pb-4">
                            <div className="d-flex align-items-start">
                                <Form.Check
                                    className="d-inline"
                                    type="checkbox"
                                    name="agree"
                                    onChange={handleChange}
                                    checked={values.agree}
                                />

                                <div className="mb-0">
                                    By signing up I agree to PeerInvest&apos;s
                                    <a
                                        className="ml-1"
                                        href="/company/terms-of-service"
                                        target="_blank">
                                        Terms of Service
                                    </a>{' '}
                                    and
                                    <a
                                        className="ml-1"
                                        href="/company/privacy-policy"
                                        target="_blank">
                                        Privacy Policy
                                    </a>
                                    , as well as our partner
                                    <a
                                        className="ml-1"
                                        rel="noopener noreferrer"
                                        href="https://www.dwolla.com/legal/tos/"
                                        target="_blank">
                                        Dwolla&apos;s Terms of Service
                                    </a>{' '}
                                    and
                                    <a
                                        className="ml-1"
                                        rel="noopener noreferrer"
                                        href="https://www.dwolla.com/legal/privacy/"
                                        target="_blank">
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>

                            {errors.agree && touched.agree ? (
                                <p className="text-danger mb-0">
                                    {errors.agree}
                                </p>
                            ) : null}
                        </div>
                    </Form.Group>

                    <div className="row">
                        <div className="col-12">
                            <Button
                                className="w-100 pi-primary-btn py-2 pi-font-size-20 pi-montserrat"
                                disabled={isSubmitting}
                                type="submit">
                                Agree & Sign up
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        />
    );
};

export default SignUpForm;
