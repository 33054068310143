import * as React from 'react';

import { DwollaKBAProps } from "../../Types/Shared";
import KBAForm from "../Forms/KBAForm";
import { KBAQuestions } from "../../Types/Data";
import { getDwollaKBA, submitDwollaKBA } from "../../Services/Banking/BankingServices";
import { DwollaKBABody } from "../../Types/Services";

import { Modal, Spinner } from 'react-bootstrap';

function DwollaKBA(props: DwollaKBAProps): React.ReactElement {
    const [questions, setQuestions] = React.useState<KBAQuestions>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string>('')

    React.useEffect(() => {
        getDwollaKBA().then(res => {
            console.log(res.data);
            setQuestions(res.data);
            setIsLoading(false);
        }).catch(err => {
            setErrorMessage('There was an issue retrieving your questions.' +
                ' Please try again and if the issue persist, reach out to us at support@peerinvest.io.')
            console.log(err);
            setIsLoading(false);
        })
    }, [])

    function handleClose(): void {
        props.close();
    }

    function handleSubmit(values: any): void {
        setIsLoading(true);
        const body: DwollaKBABody = {
            kba_url: questions?.kba_url ? questions?.kba_url : '',
            answers: []
        }
        for (const key in values) {
           const splitAnswer = values[key].split('-');
           body.answers.push({
               questionId: splitAnswer[0],
               answerId: splitAnswer[1]
           });
        }
        submitDwollaKBA(body).then(() => {
            props.close();
            setIsLoading(false);
        }).catch(err => {
            setErrorMessage('There was an issue submitting your answers.' +
                ' Please try again and if the issue persist, reach out to us at support@peerinvest.io.')
            console.log(err);
            setIsLoading(false);
        })
    }

    function getModalBody(): React.ReactElement {
        if (isLoading) {
            return (
                <div className="text-center my-5">
                    <Spinner className="pi-color-primary spinner-size" animation="border" />
                </div>
            );
        } else if (errorMessage) {
            return (
                <p className="pi-color-primary-gray">
                    {errorMessage}
                </p>
            )
        } else {
            return (
                <div>
                    <p className="pi-font-size-16 pi-color-primary-gray">
                        In an effort to verify your identity for our third party payment processor,
                        please answer the following questions as accurately as possible.
                    </p>
                    {questions &&
                        <KBAForm
                            questions={questions.kba_questions.questions}
                            handleSubmit={(values: any): void => handleSubmit(values)}
                            classes=""
                            isLoading={isLoading}
                        />
                    }
                </div>
            );
        }
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Knowledge-based Authentication</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {getModalBody()}
            </Modal.Body>
        </Modal>
    )
}

export default DwollaKBA;