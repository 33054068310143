import * as React from "react";

import { TransactionFilterFormProps } from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";

const TransactionsFilterForm = (props: TransactionFilterFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                timeframe: new Date(0).toDateString(),
                type: 'ALL'
            }}
            onSubmit={(values, actions): void => {
                props.handleFilterSubmit(values);
                actions.setSubmitting(false);
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                isSubmitting
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <Form.Group controlId="formTimeframe">
                                <Form.Label>Timeframe</Form.Label>
                                <Form.Control
                                    name="timeframe"
                                    as="select"
                                    type="date"
                                    onChange={handleChange}
                                    value={values.timeframe}
                                >
                                    <option value={new Date(0).toDateString()}>
                                        All
                                    </option>
                                    <option value={new Date().setFullYear((new Date().getFullYear()) - 1)}>
                                        1 Year
                                    </option>
                                    <option value={new Date(new Date().getFullYear(), 0, 1).toDateString()}>
                                        YTD
                                    </option>
                                    <option value={new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate()).toDateString()}>
                                        6 Months
                                    </option>
                                    <option value={new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).toDateString()}>
                                        3 Month
                                    </option>
                                    <option value={new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()).toDateString()}>
                                        1 Month
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className="col">
                            <Form.Group controlId="formType">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    name="type"
                                    as="select"
                                    onChange={handleChange}
                                    value={values.type}
                                >
                                    <option value="ALL">All</option>
                                    <option value="BY">Buy</option>
                                    <option value="SL">Sell</option>
                                    <option value="DV">Dividend</option>
                                    <option value="RF">Refinance Payout</option>
                                    <option value="LQ">Asset Sale Payout</option>
                                    <option value="DP">Cash Deposit</option>
                                    <option value="WD">Cash Withdraw</option>
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className="col align-self-end">
                            <Button className="pi-primary-btn mb-3" disabled={isSubmitting} type="submit">
                                Filter
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        />
    )
}

export default TransactionsFilterForm;