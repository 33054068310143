import React from 'react';

import { verifyEmail } from "../../../Services/Home/HomeServices";
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";

import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

function VerifyEmail(): React.ReactElement {
    const { token } = useParams();
    const [hasCalledVerifyEmail, setHasCalledVerifyEmail] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (token && !hasCalledVerifyEmail) {
            setHasCalledVerifyEmail(true);
            verifyEmail(token).then(() => {
                // Do nothing on purpose
            });
        }
    }, [hasCalledVerifyEmail, setHasCalledVerifyEmail, token]);

    return (
        <div>
            <MasterHeader />

            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-12 col-md-4 col-xl-3 text-center mt-5">
                        <h1 className="pi-font-size-32 mb-5">Email Verified</h1>

                        <FontAwesomeIcon className="pi-font-size-80 pi-color-primary mb-5" icon={faThumbsUp} />

                        <p className="pi-color-primary-gray">
                            You are free to get started investing! Checkout our marketplace for available investment options.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail;