import * as React from "react";

import { TradingTicketReviewProps } from "../../Types/Shared";
import { currency } from "../Pipes/Currency";
import { Button } from "react-bootstrap";

function TradingTicketReview(props: TradingTicketReviewProps): React.ReactElement {

    function handleSubmit(): void {
        props.handleSubmit();
    }

    return (
        <div>
            <div className="d-flex justify-content-between text-white pi-font-size-14 border-bottom border-secondary">
                <p>Account</p>
                <p>{props.account}</p>
            </div>

            <div className="d-flex justify-content-between text-white pi-font-size-14 pt-3 border-bottom border-secondary">
                <p>Symbol</p>
                <p>{props.asset.symbol}</p>
            </div>

            <div className="d-flex justify-content-between text-white pi-font-size-14 pt-3 border-bottom border-secondary">
                <p>Action</p>
                <p>Buy</p>
            </div>

            <div className="d-flex justify-content-between text-white pi-font-size-14 pt-3 border-bottom border-secondary">
                <p>Quantity</p>
                <p>{props.quantity}</p>
            </div>

            <div className="d-flex justify-content-between text-white pi-font-size-14 pt-3">
                <p>Estimated Value</p>
                <p>{currency(props.quantity * props.asset.market_price)}</p>
            </div>

            <Button
                className="pi-bg-color-primary border-0 w-100 py-3 mt-3"
                onClick={handleSubmit}
            >
                Submit Order
            </Button>
        </div>
    );
}

export default TradingTicketReview;
