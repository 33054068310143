import * as React from 'react';

import { DiscussionFormProps } from "../../Types/Shared";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import { Spinner } from 'react-bootstrap';


function DiscussionForm(props: DiscussionFormProps): React.ReactElement {
    return (
        <Formik
            initialValues={{
                post: ''
            }}
            onSubmit={(values, actions): void => {
                props.handleSubmit(values);
                actions.setSubmitting(false);
                values.post = '';
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                isSubmitting
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="formPost">
                        <Form.Control
                            name="post"
                            as="textarea"
                            rows={3}
                            placeholder="Post a new question or comment"
                            onChange={handleChange}
                            value={values.post}
                        />
                    </Form.Group>

                    <div className="text-right">
                        <Button className="pi-primary-btn px-4" type="submit" disabled={!values.post || isSubmitting}>
                            {isSubmitting ? <Spinner size="sm" animation="border" /> : 'Post'}
                        </Button>
                    </div>
                </Form>
            )}
        />
    )
};

export default DiscussionForm;
