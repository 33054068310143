import * as React from 'react';

import '../Styles/BuyProcess.css';

function BuyProcess(): React.ReactElement {
    return (
        <div>
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter">
                Purchase Process
            </h2>

            <div className="border rounded p-3 shadow-sm">
                <div className="row">
                    <div className="col-12 col-lg-4 pi-border">
                        <h3 className="pi-font-size-16 mb-3">
                            Steps to Buy Shares
                        </h3>

                        <ol className="mr-4">
                            <li className="mb-3 pi-color-primary-gray">Click buy shares</li>
                            <li className="mb-3 pi-color-primary-gray">Connect a bank account</li>
                            <li className="mb-3 pi-color-primary-gray">Enter desired quantity</li>
                            <li className="mb-3 pi-color-primary-gray">Sign operating & subscription agreement</li>
                            <li className="mb-3 pi-color-primary-gray">Confirm order details</li>
                            <li className="pi-color-primary-gray">Submit trade</li>
                        </ol>
                    </div>

                    <div className="col-12 col-lg-8 pl-4 mt-3 mt-lg-0">
                        <h3 className="pi-font-size-16 mb-3">
                            PeerInvest Due Diligence Process
                        </h3>

                        <p className="pi-color-primary-gray mb-5 mb-lg-4">
                            PeerInvest takes its responsibility to perform thorough and extensive due diligence seriously.
                            We analyze 100s of properties and only offer the best investment opportunities on our platform.
                            Once we find promising opportunities, we work to verify rent rolls, property condition, and validate
                            estimates and assumptions. Only then do we submit offers at prices that provide a margin of safety for our
                            investors.
                        </p>

                        <div className="row">
                            <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                                <div className="rounded p-2 position-relative">
                                    <svg width="50" height="123" className="position-absolute" viewBox="0 0 50 123" fill="none">
                                        <path d="M17.945 123V27.7361H0.541016V0.805664H49.6386V123H17.945Z" fill="#E8E9ED"></path>
                                    </svg>

                                    <div className="position-relative mt-4 ml-4">
                                        <h4 className="pi-font-size-18">Analysis</h4>

                                        <p className="pi-color-primary-gray pi-font-size-14">
                                            PeerInvest has a proprietary system to filter and rank properties based on estimated
                                            cash flow, neighborhood characteristics, appreciation potential, and more.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                                <div className="rounded p-2 position-relative">
                                    <svg width="99" height="125" className="position-absolute" viewBox="0 0 99 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M98.8519 98.0696V125H0.839844L31.8006 92.5736C42.9148 80.9709 51.2809 70.6507 56.899 61.6128C61.2958 54.7734 63.4942 48.4224 63.4942 42.56C63.4942 37.4304 62.2729 33.5222 59.8302 30.8352C57.6318 28.3926 54.6396 27.1712 50.8534 27.1712C46.3345 27.1712 42.7926 28.82 40.2278 32.1176C37.5409 35.5374 36.0753 40.5448 35.831 47.14H3.03824C3.16038 33.4611 7.67931 22.1638 16.595 13.248C25.3886 4.45442 36.9302 0.0576172 51.2198 0.0576172C65.7537 0.0576172 77.2342 4.27122 85.6614 12.6984C93.6001 20.6371 97.5695 30.652 97.5695 42.7432C97.5695 55.6894 91.5238 67.7806 79.4326 79.0168L58.9142 98.0696H98.8519Z" fill="#E8E9ED"></path>
                                    </svg>

                                    <div className="position-relative mt-4 ml-4">
                                        <h4 className="pi-font-size-18">Validation</h4>

                                        <p className="pi-color-primary-gray pi-font-size-14">
                                            PeerInvest works with industry professionals to validate rent rolls, assumptions and
                                            inspects the property to provide a margin of safety for investors.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                                <div className="rounded p-2 position-relative">
                                    <svg width="98" height="128" className="position-absolute" viewBox="0 0 98 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.205078 83.2304H32.6315C32.8757 88.36 34.5245 92.4515 37.5779 95.5048C40.5091 98.6803 44.6616 100.268 50.0355 100.268C55.0429 100.268 59.1955 98.4971 62.4931 94.9552C64.9357 92.1462 66.1571 88.7875 66.1571 84.8792C66.1571 80.3603 64.5693 76.7573 61.3939 74.0704C58.5848 71.7499 54.6765 70.5896 49.6691 70.5896C47.5928 70.5896 45.8219 70.8339 44.3563 71.3224V49.888C49.7301 50.6208 54.0659 49.5827 57.3635 46.7736C60.0504 44.4531 61.3939 41.583 61.3939 38.1632C61.3939 34.4992 60.2947 31.568 58.0963 29.3696C55.8979 27.1712 52.9056 26.072 49.1195 26.072C45.3333 26.072 42.4021 27.1102 40.3259 29.1864C37.5168 31.7512 36.1123 35.3542 36.1123 39.9952H6.61708C7.22775 28.6368 11.1971 19.2936 18.5251 11.9656C26.0973 4.02695 36.8451 0.0576172 50.7683 0.0576172C63.7144 0.0576172 73.8515 3.66055 81.1795 10.8664C87.7747 17.4616 91.0723 25.3392 91.0723 34.4992C91.0723 40.1174 89.851 45.1248 87.4083 49.5216C85.0877 53.7963 81.9733 56.9107 78.0651 58.8648C84.0496 61.0632 88.8739 64.7883 92.5379 70.04C95.9576 75.0475 97.6675 81.032 97.6675 87.9936C97.6675 98.6192 94.0645 107.535 86.8587 114.741C78.1872 123.412 66.0349 127.748 50.4019 127.748C36.1123 127.748 24.4485 123.534 15.4107 115.107C6.49494 106.924 1.42641 96.2987 0.205078 83.2304Z" fill="#E8E9ED"></path>
                                    </svg>

                                    <div className="position-relative mt-4 ml-4">
                                        <h4 className="pi-font-size-18">Closing</h4>

                                        <p className="pi-color-primary-gray pi-font-size-14">
                                            PeerInvest secures financing with the best terms possible and works with title companies to
                                            ensure a clean and smooth title transfer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyProcess;