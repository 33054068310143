import * as React from 'react';

import MailingAddressForm from '../../../Shared/Forms/MailingAddressForm';
import {
    saveContactInformation,
    savePersonalSettings,
} from '../../../Services/Settings/SettingsServices';
import { MailingAddressFormProps } from '../../../Types/Shared';
import { PersonalInformationProps } from '../../../Types/Features';
import ContactInformationForm from '../../../Shared/Forms/ContactInfoForm';

class PersonalInformation extends React.Component<
    PersonalInformationProps,
    {}
> {
    handleMailingAddressSave(values: MailingAddressFormProps): void {
        savePersonalSettings(values)
            .then(() => {
                // Do nothing here for now
            })
            .catch(() => {
                // Do nothing here for now
            });
    }

    handleContactInfoSave(values: { email: string }): void {
        saveContactInformation(values)
            .then(() => {
                // Do nothing here for now
            })
            .catch(() => {
                // Do nothing here for now
            });
    }

    render(): React.ReactElement {
        return (
            <div className="container-fluid py-3">
                <div className="row mb-5">
                    <div className="col-12 col-md-6 col-xl-4 mb-3 mb-lg-0">
                        <div className="border rounded p-3 shadow h-100">
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mb-3 border-bottom">
                                Mailing Address
                            </h1>
                            <MailingAddressForm
                                handleSave={this.handleMailingAddressSave}
                                settings={this.props.settings}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="d-flex flex-column justify-content-between border rounded p-3 shadow h-100">
                            <h1 className="pi-font-size-18 pi-color-primary-gray pb-2 mb-3 border-bottom">
                                Contact Information
                            </h1>
                            <ContactInformationForm
                                classes="flex-grow-1 d-flex flex-column justify-content-between"
                                settings={{ email: this.props.email }}
                                handleSave={this.handleContactInfoSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PersonalInformation;
