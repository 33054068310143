import * as React from 'react';

import './Styles/ESignatureContainer.css';
import { getContract } from "../../Services/ESign/ESignServices";
import { ESignatureContainerProps } from "../../Types/Shared";

import { Button } from 'react-bootstrap';

function ESignatureContainer(props: ESignatureContainerProps): React.ReactElement {
    const [signPageUrl, setSignPageUrl] = React.useState<string>('');

    React.useEffect(() => {
        const contractType = props.hasSignedInvestmentPackage ? 'SA' : 'IP';
        const contractQuantity = props.quantity - props.authorizedPurchaseQuantity + props.totalSharesOwned;

        getContract(
            props.asset.symbol, '', contractType, contractQuantity
        ).then(res => {
            if (typeof res.data === 'string') {
                setSignPageUrl(res.data)
            } else {
                setSignPageUrl(res.data.data.contract.signers[0].sign_page_url);
            }
        });
    }, [props.asset.symbol, props.authorizedPurchaseQuantity, props.hasSignedInvestmentPackage, props.quantity, props.totalSharesOwned]);

    function handleSubmit(): void {
        props.handleSubmit();
    }

    return (
        <div>
            <div id="pi-e-sign-iframe">
                { signPageUrl &&
                    <iframe
                        title="ESignatureFrame"
                        src={signPageUrl}
                        id="eSignaturesIOIframe"
                        className="e-sign-iframe"
                        style={{width: '100%', height: '100%'}}
                    ></iframe>
                }
            </div>
            <Button
                className="pi-bg-color-primary border-0 w-100 py-3 mt-3"
                onClick={handleSubmit}
            >
                Review Order
            </Button>
        </div>
    );

}

export default ESignatureContainer;