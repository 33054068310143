import * as React from 'react';
import { ReactElement } from 'react';

import './Styles/DashboardNav.css';
import { DashboardNavProps } from '../../Types/Shared';
import { ActionTypes } from '../../Store/Actions';
import IconButton from '../BasicComponents/IconButton';
import CustomToggle from '../BasicComponents/CustomToggle';

import { Redirect } from 'react-router';

import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../../Types/Store';

import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faCheckCircle,
    faExclamationTriangle,
    faSeedling,
    faUniversity,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { resendVerificationEmail } from '../../Services/Users/UserServices';
import { useLocation } from 'react-router-dom';
import TransferForm from '../Forms/TransferForm';
import { transactMoney } from '../../Services/Banking/BankingServices';
import Spinner from 'react-bootstrap/Spinner';
import { currency } from '../Pipes/Currency';
import {
    checkDwollaVerificationStatus,
    createDwollaAccount,
} from '../../Services/Auth/Auth';
import { DwollaVerificationStatus } from '../../Types/Data';
import PersonalInfoForm from '../Forms/PersonalInfoForm';
import { stateCodeMap } from '../../Assets/states';

enum ModalStep {
    DwollaVerification,
    Entry,
    Review,
    Submitting,
    Error,
    Success,
}

function DashboardNav(props: DashboardNavProps): ReactElement {
    const [toLogin, setToLogin] = React.useState<boolean>(false);
    const [isHome, setIsHome] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [account, setAccount] = React.useState<string>('');
    const [amount, setAmount] = React.useState<number>(0);
    const [modalStep, setModalStep] = React.useState<ModalStep>(
        ModalStep.Entry,
    );
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [showKBAModal, setShowKBAModal] = React.useState<boolean>(false);
    const [verificationStatus, setVerificationStatus] =
        React.useState<DwollaVerificationStatus>(
            DwollaVerificationStatus.Not_Verified,
        );
    const removeCookie = useCookies(['authCookie'])[2];
    const userInfo = useSelector((state: StoreState) => state.userInfo);
    const updates = useSelector((state: StoreState) => state.updates);
    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        setIsHome(
            location.pathname.includes('home') ||
                location.pathname.includes('support'),
        );
        if (userInfo.first_name) {
            checkDwollaVerificationStatus()
                .then((res) => {
                    if (res.data === DwollaVerificationStatus.KBA_Needed) {
                        setShowKBAModal(true);
                    }
                    setVerificationStatus(res.data);
                    if (res.data === DwollaVerificationStatus.Not_Verified) {
                        setModalStep(ModalStep.DwollaVerification);
                    }
                })
                .catch(() => {
                    // Let's add some error handling here...
                });
        }
    }, [location, userInfo]);

    function handleSidebarToggle(): void {
        props.onSidebarToggle();
    }

    function handleSignOut(): void {
        removeCookie('authCookie', { path: '/' });
        dispatch({ type: ActionTypes.SET_COOKIES, newCookies: {} });
        dispatch({
            type: ActionTypes.SET_IS_AUTHENTICATED,
            newIsAuthenticated: false,
        });

        setToLogin(true);
    }

    function handleResendVerificationEmail(): void {
        resendVerificationEmail().then(() => {
            // do nothing on purpose
        });
    }

    function handleSubmit(values: { account: string; amount: number }): void {
        setAccount(values.account);
        setAmount(values.amount);
        setModalStep(ModalStep.Review);
    }

    function handleClose(): void {
        setShowModal(false);
        setModalStep(ModalStep.Entry);
        setAmount(0);
        setAccount('');
    }

    function submitTransfer(): void {
        setModalStep(ModalStep.Submitting);
        transactMoney(account, 'DP', amount)
            .then((res) => {
                setModalStep(ModalStep.Success);
            })
            .catch((err) => {
                setModalStep(ModalStep.Error);
                setErrorMessage(err.response.data);
            });
    }

    function submitPersonalInformation(values: any): void {
        setErrorMessage('');
        const data = { ...values };
        data.address2 = values.address2 ? values.address2 : '';
        data.ssn = values.ssn.replace(/\W/g, '');
        data.dob = values.dob?.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2');
        data.state = stateCodeMap.find(
            (item: { name: string; code: string }) =>
                item.name.toLowerCase() === values.state.toLowerCase(),
        )?.code;
        createDwollaAccount(data)
            .then((res) => {
                if (res.status === 200 && res.data === 'success') {
                    setModalStep(ModalStep.Entry);
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    setErrorMessage(`There was an issue submitting your information for verification. 
					Please try again and if the issue persist, reach out to us at support@peerinvest.io.`);
                } else {
                    setErrorMessage(`Unexpected error. Please try again and if the 
					issue persists, reach out to us at support@peerinvest.io.`);
                }
            });
    }

    if (toLogin) {
        return <Redirect to={'/auth/login'} />;
    }

    return (
        <div className="bg-transparent px-2 px-md-0">
            <Modal
                show={showModal}
                onHide={(): void => {
                    setShowModal(false);
                    setModalStep(
                        modalStep == ModalStep.DwollaVerification
                            ? ModalStep.DwollaVerification
                            : ModalStep.Entry,
                    );
                    setAccount('');
                    setAmount(0);
                }}
                size={modalStep == ModalStep.DwollaVerification ? 'lg' : 'sm'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="pi-font-size-20">Add Funds</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalStep === ModalStep.DwollaVerification && (
                        <div>
                            {errorMessage && (
                                <p className="text-danger">{errorMessage}</p>
                            )}
                            <PersonalInfoForm
                                handleSubmit={submitPersonalInformation}
                                values={{
                                    address1: userInfo.address1
                                        ? userInfo.address1
                                        : '',
                                    address2: userInfo.address2
                                        ? userInfo.address2
                                        : '',
                                    city: userInfo.city ? userInfo.city : '',
                                    state: userInfo.state
                                        ? stateCodeMap.find(
                                              (item: {
                                                  name: string;
                                                  code: string;
                                              }) =>
                                                  item.code.toLowerCase() ===
                                                  userInfo.state.toLowerCase(),
                                          )?.name
                                        : '',
                                    zip: userInfo.zip
                                        ? userInfo.zip.toString()
                                        : '',
                                }}
                                lightMode={true}
                            />
                        </div>
                    )}
                    {modalStep === ModalStep.Entry && (
                        <TransferForm handleSubmit={handleSubmit} />
                    )}
                    {modalStep === ModalStep.Review && (
                        <div>
                            <h2 className="pi-font-size-24 pi-montserrat text-center mt-2">
                                {currency(amount, 2)}
                            </h2>
                            <p className="pi-color-primary-gray pi-font-size-12 text-center">
                                Will be transferred within 3-5 business days
                            </p>
                            <hr />
                            <p className="pi-font-size-14 mb-1">To</p>
                            <div className="d-flex align-items-center">
                                <div
                                    className="d-flex rounded-circle pi-bg-color-primary justify-content-center align-items-center"
                                    style={{ height: 32, width: 32 }}>
                                    <FontAwesomeIcon
                                        className="pi-font-size-18 text-white"
                                        icon={faSeedling}
                                    />
                                </div>
                                <p className="mb-0 ml-2 pi-font-size-14">
                                    PeerInvest Balance
                                </p>
                            </div>
                            <hr />
                            <p className="pi-font-size-14 mb-1">From</p>
                            <div className="d-flex align-items-center mb-4">
                                <div
                                    className="d-flex rounded-circle pi-bg-color-primary-gray justify-content-center align-items-center"
                                    style={{ height: 32, width: 32 }}>
                                    <FontAwesomeIcon
                                        className="pi-font-size-18 text-white"
                                        icon={faUniversity}
                                    />
                                </div>
                                <p className="mb-0 ml-2 pi-font-size-14">
                                    {account}
                                </p>
                            </div>
                            <Button
                                className="pi-primary-btn pi-montserrat w-100"
                                onClick={submitTransfer}>
                                Submit Transfer
                            </Button>
                        </div>
                    )}
                    {modalStep === ModalStep.Submitting && (
                        <div className="text-center py-5">
                            <Spinner animation="border" variant="primary" />
                            <div className="pi-color-primary-gray pi-font-size-16 mt-3">
                                Submitting your deposit...
                            </div>
                        </div>
                    )}
                    {modalStep === ModalStep.Success && (
                        <div className="text-center">
                            <FontAwesomeIcon
                                className="pi-color-primary pi-font-size-32 mb-2"
                                icon={faCheckCircle}
                            />
                            <p className="mb-3 pi-font-size-14">
                                You successfully initiated a deposit
                            </p>
                            <p className="mb-3 pi-font-size-14">
                                A deposit will be initiated from {account} in
                                the amount of {currency(amount, 2)}. Transfers
                                usually take 3-5 business days, once cleared
                                your cash will be deposited into your account
                                balance.
                            </p>
                            <Button
                                className="pi-bg-color-primary border-0 w-100 py-3 pi-montserrat"
                                onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    )}
                    {modalStep === ModalStep.Error && (
                        <div className="text-center">
                            <FontAwesomeIcon
                                className="pi-color-primary pi-font-size-32 mb-2"
                                icon={faExclamationTriangle}
                            />
                            <p className="mb-3 pi-font-size-14">
                                Something went wrong while submitting your
                                deposit!
                            </p>
                            <p className="mb-3 pi-font-size-14">
                                Don&apos;t worry, no transfer has been
                                initiated. Please close this window and try
                                again. If the problem persists, please contact
                                our support team.
                            </p>
                            <p className="pi-font-size-14">
                                Error: {errorMessage}
                            </p>
                            <Button
                                className="pi-bg-color-primary border-0 w-100 py-3 pi-montserrat"
                                onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <div className="d-flex justify-content-between px-0 px-md-3 py-1">
                <div className="d-flex align-items-center">
                    {props.hasMenu && (
                        <IconButton
                            classes="pi-color-primary-gray mr-0 mr-md-3 ml-0 fa-button ml-2 ml-md-0 px-0"
                            onClick={handleSidebarToggle}>
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    )}
                    {isHome ? (
                        <Navbar.Brand href="/dashboard">
                            <FontAwesomeIcon
                                className="pi-font-size-32 text-white"
                                icon={faSeedling}
                            />
                            <span className="ml-2 text-white pi-font-size-24">
                                PeerInvest
                            </span>
                        </Navbar.Brand>
                    ) : (
                        <div></div>
                    )}
                </div>

                <div className="d-flex align-items-center justify-content-end">
                    <Button
                        className="pi-primary-outline-btn rounded-pill mr-0 mr-lg-4 pi-montserrat"
                        onClick={(): void => setShowModal(true)}>
                        Add Funds
                    </Button>

                    <Dropdown style={{ width: 50 }} alignRight>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-profile">
                            <div className="position-relative mr-2 mr-md-3">
                                <FontAwesomeIcon
                                    className="pi-color-primary-gray pi-font-size-24"
                                    icon={faBell}
                                />
                                <div
                                    className="position-absolute bg-danger text-white rounded-circle pi-font-size-10 d-flex align-items-center justify-content-center"
                                    style={{
                                        height: 15,
                                        width: 15,
                                        top: 7,
                                        right: -5,
                                    }}>
                                    {updates.length}
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="py-0 shadow">
                            {updates.slice(0, 5).map((update, index) => (
                                <Dropdown.Item
                                    key={update.id}
                                    href="#"
                                    className="py-3 border-bottom">
                                    <div className="pi-color-primary-gray pi-font-size-14 pi-montserrat">
                                        {update.title}
                                    </div>
                                    <div className="pi-color-medium-gray pi-font-size-12 pi-montserrat">
                                        <span className="text-wrap">
                                            {update.description}
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            ))}
                            <Dropdown.Item
                                href="/dashboard/news-and-updates"
                                className="pi-color-primary pi-font-size-14 pi-montserrat py-2 d-flex justify-content-between">
                                <span>See all updates</span>
                                <span>{'>'}</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {userInfo.first_name && (
                        <h2 className="d-none d-md-block mb-0 pi-font-size-18 mr-3 pi-montserrat pi-color-primary-gray">
                            {'Hi, ' + userInfo.first_name}
                        </h2>
                    )}

                    <Dropdown
                        alignRight
                        style={{ width: 58 }}
                        className="mr-1 mr-md-0">
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-profile">
                            <FontAwesomeIcon
                                className="pi-color-primary-gray"
                                icon={faUserCircle}
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                href="/account"
                                className="pi-montserrat">
                                Account
                            </Dropdown.Item>
                            <Dropdown.Item
                                href="/dashboard"
                                className="pi-montserrat">
                                Dashboard
                            </Dropdown.Item>
                            <Dropdown.Item
                                href="/support/request"
                                className="pi-montserrat">
                                Contact us
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleSignOut}
                                className="pi-montserrat">
                                Sign out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            {!userInfo.is_email_verified && (
                <div className="container-fluid">
                    <div className="row pi-bg-color-secondary">
                        <div className="col-12">
                            <div className="d-block d-md-flex justify-content-center align-items-center my-2 pi-font-size-14">
                                <div className="d-inline d-md-block text-white mb-0">
                                    Please verify your email. Some features will
                                    be disabled until your email address has
                                    been verified.
                                </div>
                                <Button
                                    variant="link"
                                    onClick={handleResendVerificationEmail}
                                    className="text-white p-0 ml-1 pi-align-self-inherit pi-font-size-14">
                                    Resend
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DashboardNav;
