import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import { ActionTypes } from '../Store/Actions';
import { AuthGuardProps } from '../Types/Routes';

import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../Types/Store';
import { checkAuth } from '../Services/Auth/Auth';
import axios from 'axios';
import { useCookies } from 'react-cookie';

function AuthGuard(props: AuthGuardProps): ReactElement {
    const [isAuthenticated, userInfo] = useSelector((state: StoreState) => [
        state.isAuthenticated,
        state.userInfo,
    ]);
    const [cookies] = useCookies(['authCookie']);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userInfo.first_name) {
            axios.defaults.headers.common[
                'Authorization'
            ] = `JWT ${cookies.authCookie}`;
            dispatch({
                type: ActionTypes.SET_HAS_CHECKED_AUTH,
                newHasCheckedAuth: false,
            });
            checkAuth()
                .then((res) => {
                    dispatch({
                        type: ActionTypes.SET_USER_INFO,
                        newUserInfo: res.data,
                    });
                })
                .catch(() => {
                    dispatch({
                        type: ActionTypes.SET_IS_AUTHENTICATED,
                        newIsAuthenticated: false,
                    });
                })
                .finally(() => {
                    dispatch({
                        type: ActionTypes.SET_HAS_CHECKED_AUTH,
                        newHasCheckedAuth: true,
                    });
                });
        }
    }, [dispatch, userInfo.first_name]);

    let elementToRender: ReactElement;

    if (props.redirect) {
        if (isAuthenticated || window.location.pathname === '/auth/login') {
            elementToRender = props.children;
        } else {
            elementToRender = <Redirect to={'/auth/login'} />;
        }
    } else {
        elementToRender = props.children;
    }

    return (
        <Route path={props.path} render={(): ReactElement => elementToRender} />
    );
}

export default AuthGuard;
