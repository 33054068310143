import * as React from 'react';

import { EmailCaptureFormProps } from "../../Types/Shared";
import "./Styles/EmailCaptureForm.css";

import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Formik, Field } from "formik";

function validateEmail(value: string): string | undefined {
    let error;

    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }

    return error;
}

const EmailCaptureForm = (props: EmailCaptureFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                email: ''
            }}
            onSubmit={(values, actions): void => {
                props.captureEmail(values, props.formId);
                values.email = '';
                actions.setSubmitting(false);
            }}
            render={({
                         errors,
                         touched,
                         isSubmitting,
                         handleSubmit
            }): React.ReactElement => (
                <div>
                    {props.submissionError && !errors.email && <div className="text-center mt-2">{props.submissionError}</div>}
                    {errors.email && touched.email && <div className="text-center mt-2">{errors.email}</div>}
                    <Form className={'mailchimp mb-2 ' + props.classes} onSubmit={handleSubmit}>
                        <InputGroup className="d-block d-md-flex">
                            <Field
                                name="email"
                                type="email"
                                className="form-control p-4 flex-grow-1 mailchimp-input text-center text-md-left"
                                validate={validateEmail}
                                placeholder="Enter Email Address"
                            />

                            <Button type="submit" className={"rounded-right border-0 d-block d-md-inline-block w-sm-100 py-3 py-md-0 " + props.buttonClasses} disabled={isSubmitting}>
                                Join Our Mailing List
                            </Button>
                        </InputGroup>
                    </Form>
                    <span className="px-0 px-lg-5 pi-font-size-14 pi-color-primary-gray">
                        *By signing up, you are agreeing to receive periodic emails regarding PeerInvest.
                    </span>
                </div>
            )}
        />
    )
};

export default EmailCaptureForm;
