import * as React from "react";
import { ReactElement } from "react";

import HomeNav from "../../Shared/HomeNav/HomeNav";
import ResetPwdForm from "../../Shared/Forms/ResetPwdForm";
import { ResetPwdState } from "../../Types/Features";
import { resetPwd } from "../../Services/Auth/Auth";

import './Styles/LogIn.css';

import { Redirect } from "react-router-dom";

class ResetEmail extends React.Component<{}, ResetPwdState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			toLogin: false,
			unsuccessfulReset: false,
			values: {},
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(values: {email: string}): void {
		resetPwd(values.email).then(() => {
			this.setState({
                toLogin: true
            });
        }).catch(() => {
			/*
             TODO(@grthomps): Add error handling here.
            */
        });
	}

	render(): ReactElement {
		if (this.state.toLogin) {
            return <Redirect to='/auth/login' />
        }
        return (
			<div className="container">
				<HomeNav />
				<div className="row justify-content-center mt-5 mx-0">
					<div className="col-12 col-md-5 col-lg-4 col-xl-3 mt-5">
						<h1 className="display-4 pi-font-size-32 text-center mb-3">Reset Your Password</h1>

						{this.state.unsuccessfulReset ? (
							<p className="text-danger text-center">Password Reset Email Sent.</p>
						) : null}

						<ResetPwdForm handlePwdReset={this.handleSubmit} />
					</div>
				</div>
			</div>
        );
	}
}

export default ResetEmail;
