import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import Home from '../Features/Home/Home';
import Dashboard from '../Features/Dashboard/Dashboard';
import Account from '../Features/Account/Account';
import Support from '../Features/Home/Support/Support';
import TermsOfService from '../Features/Home/TermsOfService/TermsOfService';
import PrivacyPolicy from '../Features/Home/PrivacyPolicy/PrivacyPolicy';
import Faq from '../Features/Home/Faq/Faq';
import { ActionTypes } from '../Store/Actions';
import AuthGuard from './AuthGuard';
import AuthRoutes from './AuthRoutes';
import AboutUs from '../Features/Home/AboutUs/AboutUs';
import CareerRoutes from './CareerRoutes';
import BlogRoutes from './BlogRoutes';
import HowItWorks from '../Features/Home/HowItWorks/HowItWorks';
import VerifyEmail from '../Features/Home/VerifyEmail/VerifyEmail';
import KnowledgeCenterRoutes from './KnowledgeCenterRoutes';
import Error404Page from '../Features/Error/404Page';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { useCookies } from 'react-cookie';

import axios from 'axios';

import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

const history = createHistory();
history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

function Routes(): ReactElement {
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['authCookie']);

    useEffect(() => {
        dispatch({ type: ActionTypes.SET_COOKIES, newCookies: cookies });
        axios.defaults.headers.common[
            'Authorization'
        ] = `JWT ${cookies.authCookie}`;
        setResponseInterceptor();
    });

    function setResponseInterceptor(): any {
        axios.interceptors.request.use((value) => {
            if (cookies?.authCookie) {
                axios.defaults.headers.common[
                    'Authorization'
                ] = `JWT ${cookies.authCookie}`;
            }
            return value;
        });
        axios.interceptors.response.use(
            (res) => {
                return res;
            },
            (err) => {
                if (err.status === 401) {
                    removeCookie('authCookie', { path: '/' });
                    dispatch({ type: ActionTypes.SET_COOKIES, newCookies: {} });

                    window.location.href = '/auth/login';
                }

                return Promise.reject(err);
            },
        );
    }

    return (
        <BrowserRouter>
            <Switch>
                {/* Home Routes */}
                <Route exact path="/" component={Home} />

                <AuthGuard redirect={false} path="/company">
                    <Switch>
                        <Route
                            path="/company/terms-of-service"
                            component={TermsOfService}
                        />
                        <Route
                            path="/company/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route path="/company/about-us">
                            <AboutUs />
                        </Route>
                        <Route path="/company/careers">
                            <CareerRoutes />
                        </Route>
                        {/*404 error page. Keep this at the end of each switch.*/}
                        <Route path="/not-found" component={Error404Page} />
                        <Redirect to="/not-found" />
                    </Switch>
                </AuthGuard>

                <AuthGuard redirect={false} path="/support">
                    <Switch>
                        <Route path="/support/request" component={Support} />
                        <Route path="/support/faq" component={Faq} />
                        <Route path="/support/blog">
                            <BlogRoutes />
                        </Route>
                        <Route
                            path="/support/how-it-works"
                            component={HowItWorks}></Route>
                        <Route
                            path="/support/verify-email/:token"
                            component={VerifyEmail}></Route>
                        <Route path="/support/knowledge-center">
                            <KnowledgeCenterRoutes />
                        </Route>
                        {/*404 error page. Keep this at the end of each switch.*/}
                        <Route path="/not-found" component={Error404Page} />
                        <Redirect to="/not-found" />
                    </Switch>
                </AuthGuard>

                {/* Authentication Routes */}
                <Route path="/auth">
                    <AuthRoutes />
                </Route>

                {/* User Specific Routes ** Requires Auth ** */}
                <AuthGuard redirect={true} path="/dashboard">
                    <Dashboard />
                </AuthGuard>
                <AuthGuard redirect={true} path="/account">
                    <Account />
                </AuthGuard>

                {/*404 error page. Keep this at the end of each switch.*/}
                <Route path="/not-found" component={Error404Page} />
                <Redirect to="/not-found" />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
