function shortFormatter(num: number, decimals: number): string {
    if (num >= 1000) {
        return (
            '$' +
            (num / 1000)
                .toFixed(decimals)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            'K'
        );
    } else if (num >= 1000000) {
        return (
            '$' +
            (num / 1000000)
                .toFixed(decimals)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            'M'
        );
    }

    return (
        '$' +
        num
            .toFixed(decimals)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
}

export function currency(
    amount: number | string,
    decimals = 2,
    shortForm: boolean = false,
): string {
    const newAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    if (shortForm) {
        return shortFormatter(newAmount, decimals);
    }

    return (
        '$' +
        newAmount
            .toFixed(decimals)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
}
