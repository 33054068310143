import * as React from "react";
import { ReactElement } from "react";

import { useSelector } from "react-redux";
import { StoreState } from "../../Types/Store";
import DashboardNav from "../DashboardNav/DashboardNav";
import HomeNav from "../HomeNav/HomeNav";

function MasterHeader(): ReactElement {
    const isAuthenticated = useSelector((state: StoreState) => state.isAuthenticated);
    const hasCheckedAuth = useSelector((state: StoreState) => state.hasCheckedAuth);

    if (isAuthenticated && hasCheckedAuth) {
        return <DashboardNav hasMenu={false}></DashboardNav>;
    } else {
        return <HomeNav />;
    }
}

export default MasterHeader;
