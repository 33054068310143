/** Authentication **/

export interface LogIn {
    token: string;
    user: UserInfo;
}

/** Assets App **/

export interface Property {
    id: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: number;
    description: string;
    purchase_price: number;
    estimated_after_repair_value: number;
    down_payment: number;
    closing_cost: number;
    purchase_date: string;
    taxes: number;
    insurance: number;
    maintenance_cost_budget: number;
    cap_ex_budget: number;
    property_management_budget: number;
    rehab_budget: number;
    vacancy_rate: number;
    llc: number;
    lot_size: number;
    year_built: number;
    property_type: string;
    property_class: string;
    cash: number;
    equity: number;
    debt: number;
    refinance_date: string;
    sale_date: string;
    longitude: number;
    latitude: number;
    utilities_budget: number;
    schools_rating: number;
    units: Unit[];
    updates: Update[];
    images: Image[];
    documents: Document[];
    calculations: Calculations;
    market_rent_calculations: Calculations;
    posts: Post[];
    investment_type: string;
    actual_after_repair_value: number;
    actual_refinance_closing_costs: number;
    actual_rehab_cost: number;
    estimated_refinance_closing_costs: number;
}

export class PropertyImpl implements Property {
    id: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: number;
    description: string;
    purchase_price: number;
    estimated_after_repair_value: number;
    down_payment: number;
    closing_cost: number;
    purchase_date: string;
    taxes: number;
    insurance: number;
    maintenance_cost_budget: number;
    cap_ex_budget: number;
    property_management_budget: number;
    rehab_budget: number;
    vacancy_rate: number;
    llc: number;
    lot_size: number;
    year_built: number;
    property_type: string;
    property_class: string;
    cash: number;
    equity: number;
    debt: number;
    refinance_date: string;
    sale_date: string;
    longitude: number;
    latitude: number;
    utilities_budget: number;
    schools_rating: number;
    units: Unit[];
    updates: Update[];
    images: Image[];
    documents: Document[];
    calculations: Calculations;
    market_rent_calculations: Calculations;
    posts: Post[];
    investment_type: string;
    actual_after_repair_value: number;
    actual_refinance_closing_costs: number;
    actual_rehab_cost: number;
    estimated_refinance_closing_costs: number;

    constructor(
        id: number,
        address1: string,
        address2: string,
        city: string,
        state: string,
        zip: number,
        description: string,
        purchase_price: number,
        estimated_after_repair_value: number,
        down_payment: number,
        closing_cost: number,
        purchase_date: string,
        taxes: number,
        insurance: number,
        maintenance_cost_budget: number,
        cap_ex_budget: number,
        property_management_budget: number,
        rehab_budget: number,
        vacancy_rate: number,
        llc: number,
        lot_size: number,
        year_built: number,
        property_type: string,
        property_class: string,
        cash: number,
        equity: number,
        debt: number,
        refinance_date: string,
        sale_date: string,
        longitude: number,
        latitude: number,
        utilities_budget: number,
        schools_rating: number,
        units: Unit[],
        updates: Update[],
        images: Image[],
        documents: Document[],
        calculations: Calculations,
        market_rent_calculations: Calculations,
        posts: Post[],
        investment_type: string,
        actual_after_repair_value: number,
        actual_refinance_closing_costs: number,
        actual_rehab_cost: number,
        estimated_refinance_closing_costs: number,
    ) {
        this.id = id;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.description = description;
        this.purchase_price = purchase_price;
        this.estimated_after_repair_value = estimated_after_repair_value;
        this.down_payment = down_payment;
        this.closing_cost = closing_cost;
        this.purchase_date = purchase_date;
        this.taxes = taxes;
        this.insurance = insurance;
        this.maintenance_cost_budget = maintenance_cost_budget;
        this.cap_ex_budget = cap_ex_budget;
        this.property_management_budget = property_management_budget;
        this.rehab_budget = rehab_budget;
        this.vacancy_rate = vacancy_rate;
        this.llc = llc;
        this.lot_size = lot_size;
        this.year_built = year_built;
        this.property_type = property_type;
        this.property_class = property_class;
        this.cash = cash;
        this.equity = equity;
        this.debt = debt;
        this.refinance_date = refinance_date;
        this.sale_date = sale_date;
        this.longitude = longitude;
        this.latitude = latitude;
        this.utilities_budget = utilities_budget;
        this.schools_rating = schools_rating;
        this.units = units;
        this.updates = updates;
        this.images = images;
        this.documents = documents;
        this.calculations = calculations;
        this.market_rent_calculations = market_rent_calculations;
        this.posts = posts;
        this.investment_type = investment_type;
        this.actual_after_repair_value = actual_after_repair_value;
        this.actual_refinance_closing_costs = actual_refinance_closing_costs;
        this.actual_rehab_cost = actual_rehab_cost;
        this.estimated_refinance_closing_costs =
            estimated_refinance_closing_costs;
    }

    public static initialize(): Property {
        return new PropertyImpl(
            0,
            '',
            '',
            '',
            '',
            0,
            '',
            0,
            0,
            0,
            0,
            '',
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            'SF',
            'A',
            0,
            0,
            0,
            '',
            '',
            0,
            0,
            0,
            5,
            [],
            [],
            [],
            [],
            {
                annualized_return: 0,
                appreciation: 0,
                cap_rate: 0,
                cash_flow: 0,
                gross_yield: 0,
                total_return: [0, 0, 0, 0],
                cash_flows: [0, 0, 0],
                expected_rents: [0, 0, 0],
                expenses: [0, 0, 0],
                property_taxes: [0, 0, 0],
                mortgage_payment: 0,
                appreciation_series: [],
                equity_series: [],
                cash_flow_series: [],
                cash_on_cash: 0,
            },
            {
                annualized_return: 0,
                appreciation: 0,
                cap_rate: 0,
                cash_flow: 0,
                gross_yield: 0,
                total_return: [0, 0, 0, 0],
                cash_flows: [0, 0, 0],
                expected_rents: [0, 0, 0],
                expenses: [0, 0, 0],
                property_taxes: [0, 0, 0],
                mortgage_payment: 0,
                appreciation_series: [],
                equity_series: [],
                cash_flow_series: [],
                cash_on_cash: 0,
            },
            [],
            'TK',
            0,
            0,
            0,
            0,
        );
    }
}

export interface Calculations {
    annualized_return: number;
    appreciation: number;
    cap_rate: number;
    cash_flow: number;
    gross_yield: number;
    total_return: number[];
    cash_flows: number[];
    expected_rents: number[];
    expenses: number[];
    property_taxes: number[];
    mortgage_payment: number;
    appreciation_series: number[];
    equity_series: number[];
    cash_flow_series: number[];
    cash_on_cash: number;
}

export interface Unit {
    unit_num: string;
    bedrooms: number;
    bathrooms: number;
    sq_foot: number;
    rent: number;
    market_rent: number;
    expenses: Expense[];
}

export interface Expense {
    description: string;
    expense_type: ExpenseType;
    cost: number;
    payment_date: string;
}

export interface Update {
    id: number;
    created_at: string;
    title: string;
    description: string;
    content: string;
}

export interface Image {
    location: string;
    classifier: string;
}

enum ExpenseType {
    CAPEX = 'Capital Expenditures',
    MNTNC = 'Maintence',
    DWNPY = 'Down Payment',
    MRGTG = 'Mortgage',
    CLCST = 'Closing Cost',
    INSNC = 'Insurance',
    TAXES = 'Taxes',
    PMGTF = 'Property Management Fee',
    IMGTF = 'Investment Manager Fee',
    REHAB = 'Rehab',
    UTLTS = 'Utilities',
}

/** Users App **/

export interface UserInfo {
    first_name: string;
    last_name: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: number;
    is_email_verified: boolean;
    is_accredited: boolean;
    is_sophisticated: boolean;
    positions: Position[];
    documents: Document[];
    signature_documents: SignatureDocument[];
    favorites: FavoriteProperty[];
    account_balance_points: AccountBalancePoint[];
}

export class UserInfoImpl implements UserInfo {
    first_name: string;
    last_name: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: number;
    is_email_verified: boolean;
    is_accredited: boolean;
    is_sophisticated: boolean;
    positions: Position[];
    documents: Document[];
    signature_documents: SignatureDocument[];
    favorites: FavoriteProperty[];
    account_balance_points: AccountBalancePoint[];

    constructor(
        first_name: string,
        last_name: string,
        email: string,
        address1: string,
        address2: string,
        city: string,
        state: string,
        zip: number,
        is_email_verified: boolean,
        is_accredited: boolean,
        is_sophisticated: boolean,
        positions: Position[],
        documents: Document[],
        signature_documents: SignatureDocument[],
        favorites: FavoriteProperty[],
        account_balance_points: AccountBalancePoint[],
    ) {
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.is_email_verified = is_email_verified;
        this.is_accredited = is_accredited;
        this.is_sophisticated = is_sophisticated;
        this.positions = positions;
        this.documents = documents;
        this.signature_documents = signature_documents;
        this.favorites = favorites;
        this.account_balance_points = account_balance_points;
    }
}

export interface Position {
    id: number;
    num_shares: number;
    cost: number;
    transactions: Transaction[];
    asset: Asset;
}

export interface Document {
    id: number;
    location: string;
    classifier: string;
    name: string;
    description: string;
}

export interface Transaction {
    id: number;
    description: string;
    transaction_type: TransactionType;
    num_shares: number;
    cost: number;
    transact_date: string;
    status: string;
}

export enum TransactionType {
    Buy = 'BY',
    Sell = 'SL',
    Dividend = 'DV',
    Refinance_Payout = 'RF',
    Asset_Sale_Payout = 'LQ',
    Cash_Deposit = 'DP',
    Cash_Withdrawal = 'WD',
}

export interface Asset {
    id: number;
    symbol: string;
    fixed_income_rate: number;
    tax_id: string;
    total_fixed_income: number;
    unsold_fixed_income: number;
    total_shares: number;
    unsold_shares: number;
    market_price: number;
    llc: number;
    minimum_investment: number;
    active_offering: boolean;
    subscription_information?: SubscriptionInformation[];
    asset_price_points: AssetPricePoint[];
}

export class AssetImpl implements Asset {
    id: number;
    symbol: string;
    fixed_income_rate: number;
    tax_id: string;
    total_fixed_income: number;
    unsold_fixed_income: number;
    total_shares: number;
    unsold_shares: number;
    market_price: number;
    llc: number;
    minimum_investment: number;
    active_offering: boolean;
    asset_price_points: AssetPricePoint[];

    constructor(
        id: number,
        symbol: string,
        fixed_income_rate: number,
        tax_id: string,
        total_fixed_income: number,
        unsold_fixed_income: number,
        total_shares: number,
        unsold_shares: number,
        market_price: number,
        llc: number,
        minimum_investment: number,
        active_offering: boolean,
        asset_price_points: AssetPricePoint[],
    ) {
        this.id = id;
        this.symbol = symbol;
        this.fixed_income_rate = fixed_income_rate;
        this.tax_id = tax_id;
        this.total_fixed_income = total_fixed_income;
        this.unsold_fixed_income = unsold_fixed_income;
        this.total_shares = total_shares;
        this.unsold_shares = unsold_shares;
        this.market_price = market_price;
        this.llc = llc;
        this.minimum_investment = minimum_investment;
        this.active_offering = active_offering;
        this.asset_price_points = asset_price_points;
    }

    public static initialize(): Asset {
        return new AssetImpl(0, '', 0, '', 0, 0, 0, 0, 0, 0, 0, false, []);
    }
}

export interface PlaidNumber {
    ach: ACH[];
    eft: EFT[];
    international: International[];
    bacs: BACS[];
}

export interface ACH {
    account_id: string;
    account: string;
    routing: string;
    wire_routing: string;
}

export interface EFT {
    account_id: string;
    account: string;
    institution: string;
    branch: string;
}

export interface International {
    account_id: string;
    iban: string;
    bic: string;
}

export interface BACS {
    account_id: string;
    account: string;
    sort_code: string;
}

export interface Institutions {
    accounts: PlaidAccount[];
    dwolla_funding_sources: string[];
    numbers: PlaidNumber;
    item: any;
}

export interface Post {
    id: number;
    created_at: string;
    title: string;
    description: string;
    content: string;
    img: string;
}

export interface JobPosting {
    id: number;
    created_at: string;
    active: boolean;
    title: string;
    type: string;
    location: string;
    responsibilities: string;
    qualifications: string;
}

export interface SignatureDocument {
    id: number;
    contract_id: string;
    contract_type: string;
    signed: boolean;
    sign_page_url: boolean;
    asset_id: number;
    quantity: number;
    contract_pdf_url: string;
}

export interface PlaidAccount {
    account_id: string;
    label: string;
    item_id: string;
}

export interface DwollaFundingSource {
    bank_account_id: string;
}

export interface DwollaFundingSource {
    bank_account_id: string;
}

export const ContractTypeChoices = {
    SA: 'SA',
    PPM: 'PPM',
    MCM: 'MCM',
    OA: 'OA',
    IQ: 'IQ',
    IP: 'IP',
};

export function ContractTypeChoiceConversion(type: string): string {
    switch (type) {
        case 'SA':
            return 'Subscription Agreement';
        case 'PPM':
            return 'Private Placement Memorandum';
        case 'MCM':
            return 'Management Compensation Memorandum';
        case 'OA':
            return 'Operating Agreement';
        case 'IQ':
            return 'Investor Questionnaire';
        case 'IP':
            return 'Investment Package';
        default:
            return 'Unknown';
    }
}

export interface SubscriptionInformation {
    id: number;
    city: string;
    state: string;
    investment_package_template_id: string;
    subscription_agreement_template_id: string;
    formation_date: Date;
    minimum_units_sold: number;
    maximum_units_sold: number;
    offering_price_per_unit: number;
    minimum_required_purchase: number;
    offering_end_date: Date;
    offering_extension_date: Date;
    minimum_target_acquisition_price: number;
    maximum_target_acquisition_price: number;
    minimum_dollars_raised: number;
    maximum_dollars_raised: number;
}

export interface KBAQuestions {
    kba_questions: Questions;
    kba_url: string;
}

export interface Questions {
    id: string;
    questions: Question[];
}

export interface Question {
    id: string;
    text: string;
    answers: Answer[];
}

export interface Answer {
    id: string;
    text: string;
}

export enum DwollaVerificationStatus {
    Pending = 'PN',
    Verified = 'VR',
    KBA_Needed = 'KB',
    Document_Needed = 'DC',
    Deactivated = 'DT',
    Suspended = 'SS',
    Not_Verified = 'NV',
}

export enum MicrodepositModalState{
    deposit,
    postDeposit,
    verify,
    postVerify
}

export enum PlaidModalState{
    select,
    error,
    success
}

export interface KnowledgeArticle {
    id: number;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    tags: Tag[];
}

export interface Tag {
    id: number;
    text: string;
}

export interface FavoriteProperty {
    llc: number;
}

export interface Post {
    id: number;
    created_at: string;
    is_staff: boolean;
    full_name: string;
    post: string;
    replies: Reply[];
}

export interface Reply {
    id: number;
    created_at: string;
    is_staff: boolean;
    full_name: string;
    reply: string;
}

export interface AccountBalancePoint {
    created_at: string;
    value: number;
}

export interface AssetPricePoint {
    created_at: string;
    value: number;
}

export enum InvestmentType {
    FlipToRent = 'BR',
    Turnkey = 'TK',
    ValueAdd = 'VA',
}

export function InvestmentTypeChoiceConversion(type: string): string {
    switch (type) {
        case 'BR':
            return 'Flip to Rent';
        case 'TK':
            return 'Turnkey';
        case 'VA':
            return 'Value Add';
        default:
            return 'Unknown';
    }
}
