import * as React from 'react';

import { PropertyUpdateProps } from "../../../../Types/Features";
import '../Styles/PropertyUpdate.css';

function PropertyUpdate(props: PropertyUpdateProps): React.ReactElement {
    return (
        <div className="border rounded p-3 shadow-sm pi-min-height d-flex flex-column">
            <h2 className="pi-font-size-18 pi-color-primary-gray font-weight-lighter text-center mb-2 mb-lg-4">
                Update Details
            </h2>

            {props.update &&
                <div dangerouslySetInnerHTML={{__html: props.update.content}}></div>
            }

            {!props.update &&
                <div className="d-flex align-items-center justify-content-center flex-grow-1">
                    <p>Select an update to view details</p>
                </div>
            }
        </div>
    )
}

export default PropertyUpdate;