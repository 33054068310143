import * as React from "react";

import { PersonalInfoModalProps } from "../../Types/Shared";
import PersonalInformationForm from "../Forms/PersonalInfoForm";

import Spinner from "react-bootstrap/Spinner";
import { stateCodeMap } from "../../Assets/states";
import { createDwollaAccount } from "../../Services/Auth/Auth";

function PersonalInfoModal(props: PersonalInfoModalProps): React.ReactElement {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [verificationSuccess, setVerificationSuccess] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [values, setValues] = React.useState<any>({
        address1: props.address1,
        address2: props.address2,
        city: props.city,
        state: stateCodeMap.find((item: {name: string; code: string}) => item.name === props.state)?.name,
        zip: props.zip
    });

    function submitPersonalInformation(values: any): void {
        setIsLoading(true);
        setValues({...values});
        setErrorMessage('');
        const data = {...values};
        data.address2 = values.address2 ? values.address2 : '';
        data.ssn = values.ssn.replace(/\W/g, '');
        data.dob = values.dob?.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
        data.state = stateCodeMap.find((item: {name: string; code: string}) => item.name === values.state)?.code;
        createDwollaAccount(data).then(res => {
            if (res.status === 200 && res.data === 'success') {
                setVerificationSuccess(true);
                setIsLoading(false);
                setTimeout(() => {
                    props.handleClose(true);
                }, 3000);
            }
        }).catch(err => {
            if (err.status === 400) {
                setIsLoading(false);
                setErrorMessage(`There was an issue submitting your information for verification. 
					Please try again and if the issue persist, reach out to us at support@peerinvest.io.`);
            } else {
                setIsLoading(false);
                setErrorMessage(`Unexpected error. Please try again and if the 
					issue persists, reach out to us at support@peerinvest.io.`);
            }
        });
    }

    return (
        <div>
            {!isLoading && !verificationSuccess &&
                <PersonalInformationForm
                    handleSubmit={submitPersonalInformation}
                    values={values}
                    lightMode={props.lightMode}
                />
            }

            {isLoading &&
                <div className="text-center my-5">
                    <Spinner className="pi-color-primary spinner-size" animation="border" />
                </div>
            }

            {verificationSuccess &&
                <p className={"text-center my-5 " + (props.lightMode ? '' : 'text-white')}>
                    Your information has successfully been submitted for verification. Once your identity has been verified
                    you can start investing on our platform!
                </p>
            }

            {!verificationSuccess && errorMessage &&
                <p className={"text-center my-4 " + (props.lightMode ? '' : 'text-white')}>
                    {errorMessage}
                </p>
            }
        </div>
    )
}

export default PersonalInfoModal;