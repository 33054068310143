import * as React from 'react';

import './Styles/HowItWorks.css';
import MasterHeader from "../../../Shared/MasterHeader/MasterHeader";
import HomeFooter from "../../../Shared/HomeFooter/HomeFooter";
import Appreciation from "../../../Assets/appreciation.svg";
import CashFlow from "../../../Assets/cash_flow.svg";
import PrincipalPayments from "../../../Assets/principal_payments.svg";
import ProcessComparison from "../../../Assets/process_comparison.png";

import Button from 'react-bootstrap/Button';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import BuyingProcessImg from "../../../Assets/undraw_buy_house_560d.svg";

function HowItWorks(): React.ReactElement {
    const history = useHistory();

    return (
        <div>
            <div className="container">
                <MasterHeader />
            </div>

            <div className="pi-background">
                <div className="container">
                    <div className="row justify-content-center pt-5 pb-2py-lg-5">
                        <div className="col-12 col-md-8 align-self-center">
                            <h1 className="pi-font-size-32 mb-4 text-center">
                                Ready to start building passive income?
                                <br></br>Here is how it works.</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-2 pb-3 py-lg-5">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 px-2 px-lg-5 mb-5 mb-lg-0">
                        <img alt={'buying process'} className="w-100 mx-auto" src={BuyingProcessImg} />
                    </div>

                    <div className="col-12 col-lg-6">
                        <div className="d-flex mb-4">
                            <div className="rounded-circle pi-bg-color-primary pi-circle-icon">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <FontAwesomeIcon className="pi-font-size-24 text-white" icon={faChevronRight} />
                                </div>
                            </div>
                            <div className="ml-3">
                                <h2 className="pi-font-size-24">Pick a property</h2>
                                <p>Browse our marketplace for properties that fit your investment goals</p>
                            </div>
                        </div>

                        <div className="d-flex mb-4">
                            <div className="rounded-circle pi-bg-color-primary pi-circle-icon">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <FontAwesomeIcon className="pi-font-size-24 text-white" icon={faChevronRight} />
                                </div>
                            </div>
                            <div className="ml-3">
                                <h2 className="pi-font-size-24">Purchase shares</h2>
                                <p>No agents, no lenders, no closing; real estate investing made simple.</p>
                            </div>
                        </div>

                        <div className="d-flex mb-4">
                            <div className="rounded-circle pi-bg-color-primary pi-circle-icon">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <FontAwesomeIcon className="pi-font-size-24 text-white" icon={faChevronRight} />
                                </div>
                            </div>
                            <div className="ml-3">
                                <h2 className="pi-font-size-24">Earn passive income & build equity</h2>
                                <p>Receive a quarterly dividend and watch your equity grow as your investment appreciates.</p>
                            </div>
                        </div>

                        <div className="d-flex mb-4">
                            <div className="rounded-circle pi-bg-color-primary pi-circle-icon">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <FontAwesomeIcon className="pi-font-size-24 text-white" icon={faChevronRight} />
                                </div>
                            </div>
                            <div className="ml-3">
                                <h2 className="pi-font-size-24">Create more opportunities</h2>
                                <p>We are always adding new investment opportunities to the platform, reinvest distributions to compound wealth.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pi-bg-color-light-gray">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h1 className="pi-font-size-32 mb-4 text-center">How You Make Money</h1>
                        </div>
                    </div>

                    <div className="row justify-content-center mb-4 align-items-stretch">
                        <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                            <div className="p-5 bg-white rounded h-100 shadow d-flex flex-column justify-content-between">
                                <div>
                                    <h2 className="pi-font-size-24 mb-3">
                                        Appreciation
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14 text-justify">
                                        Appreciation is the change in value of the property. As an equity owner,
                                        the value of your investment changes proportionally to the change in value of the underlying
                                        property.
                                    </p>
                                </div>
                                <div className="w-100 text-right">
                                    <img alt={'how it works - appreciation'} className="w-50" src={Appreciation} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 col-lg-3 mb-4 mb-lg-0">
                            <div className="p-5 bg-white rounded h-100 shadow d-flex flex-column justify-content-between">
                                <div>
                                    <h2 className="pi-font-size-24 mb-3">
                                        Cash Flow
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14 text-justify">
                                        Properties are leased and rent payments are used to cover operating
                                        expenses and mortgage payments. The excess is returned
                                        to investors in the form of a dividend every fiscal quarter.
                                    </p>
                                </div>
                                <div className="w-100 text-right">
                                    <img alt={'how it works - cash flow'} className="w-50" src={CashFlow} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 col-lg-3">
                            <div className="p-5 bg-white rounded h-100 shadow d-flex flex-column justify-content-between">
                                <div>
                                    <h2 className="pi-font-size-24 mb-3">
                                        Principal Payments
                                    </h2>
                                    <p className="pi-color-primary-gray pi-font-size-14 text-justify">
                                        Principal payments, a portion of the mortgage payments, increases the
                                        value of your shares of the property. These payments are made from rental income.
                                    </p>
                                </div>
                                <div className="w-100 text-right">
                                    <img alt={'how it works - principal'} className="w-50" src={PrincipalPayments} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="pi-font-size-32 mb-4 text-center">Simplifying the Process</h1>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        <p className="pi-color-primary-gray text-center pi-font-size-20">
                            PeerInvest is taking the pain out of real estate investing. You can now invest from the comfort of your
                            home with a few clicks. You don't have to worry about property management, maintenance, or insurance. We do all the work.
                        </p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 pi-process-container rounded">
                        <img className="h-100" src={ProcessComparison} alt="PeerInvest process comparing how simple investing with us is." />
                    </div>
                </div>
            </div>

            <div className="pi-bg-color-secondary">
                <div className="container">
                    <div className="row py-5 justify-content-around">
                        <div className="col-12 col-lg-5 text-center text-lg-left mb-4 mb-lg-0">
                            <h2 className="pi-font-size-28 text-white">
                                Ready to start building passive income?
                            </h2>
                        </div>

                        <div className="col-12 col-lg-5 text-center text-lg-right">
                            <Button
                                className="pi-primary-btn px-4 pi-font-size-20"
                                onClick={(): void => history.push('/auth/signup')}
                            >Get Started</Button>
                        </div>
                    </div>
                </div>
            </div>

            <HomeFooter />
        </div>
    );
}

export default HowItWorks;
