import * as React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Formik } from 'formik';
import { LogInFormProps } from '../../Types/Shared';

const LogInForm = (props: LogInFormProps): React.ReactElement => {
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={(values, actions): void => {
                props.handleLogin(values);
                actions.setSubmitting(false);
            }}
            render={({
                values,
                handleSubmit,
                handleChange,
                isSubmitting,
            }): React.ReactElement => (
                <Form className={props.classes} onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicUsername">
                        <Form.Control
                            name="email"
                            type="text"
                            placeholder="Email"
                            className="h-auto"
                            onChange={handleChange}
                            value={values.email}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="h-auto"
                            onChange={handleChange}
                            value={values.password}
                        />
                    </Form.Group>

                    <div className="border-bottom pb-4 mb-2">
                        <Button
                            className="w-100 pi-primary-btn py-2 pi-font-size-20"
                            disabled={isSubmitting}
                            type="submit">
                            Log in
                        </Button>
                    </div>
                </Form>
            )}
        />
    );
};

export default LogInForm;
