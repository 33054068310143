import * as React from 'react';
import { currency } from '../../../Shared/Pipes/Currency';
import AccountBalance from '../../../Shared/Charts/AccountBalance';
import { Button } from 'react-bootstrap';
import { Serie } from '@nivo/line';
import { getTotalBalance } from '../../../Utils/Calculations';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../Types/Store';
import { AccountBalancePoint } from '../../../Types/Data';
import { lineDataManipulator } from '../../../Utils/ChartData';
import { percent } from '../../../Shared/Pipes/Percent';
import { ReactElement } from 'react';

function PortfolioBalanceChart(): React.ReactElement {
    const [graphValue, setGraphValue] = React.useState<number>(0);
    const [min, setMin] = React.useState<number>(0);
    const [series, setSeries] = React.useState<Serie[]>([]);
    const [change, setChange] = React.useState<number>(0);
    const [startingBalance, setStartingBalance] = React.useState<number>(1);
    const positions = useSelector(
        (store: StoreState) => store.userInfo.positions,
    );
    const accountBalancePoints = useSelector(
        (store: StoreState) => store.userInfo.account_balance_points,
    );

    React.useEffect(() => {
        if (positions) {
            setGraphValue(getTotalBalance(positions));
        }

        if (accountBalancePoints && accountBalancePoints.length) {
            let minValue = accountBalancePoints[0].value;
            accountBalancePoints.forEach((point) => {
                if (point.value < minValue) {
                    minValue = point.value;
                }
            });
            setMin(minValue);
            const sortedPoints = sortPoints(accountBalancePoints);
            if (sortedPoints[0].value > 0) {
                setStartingBalance(sortedPoints[0].value);
                setChange(
                    (sortedPoints[sortedPoints.length - 1].value -
                        sortedPoints[0].value) /
                        sortedPoints[0].value,
                );
            }

            setChartSeries(sortedPoints);
        }
    }, [accountBalancePoints, positions]);

    function setChartSeries(points: AccountBalancePoint[]): void {
        setSeries(lineDataManipulator(points));
    }

    function sortPoints(points: AccountBalancePoint[]): AccountBalancePoint[] {
        return points.sort((a, b) => {
            return (
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            );
        });
    }

    function filterBalance(timeframe: string): void {
        let date: Date = new Date();

        switch (timeframe) {
            case '1w':
                const milliseconds = 7 * 24 * 3600 * 1000;
                date = new Date(date.getTime() - milliseconds);
                break;
            case '3m':
                date = new Date(date.setMonth(date.getMonth() - 3));
                break;
            case '6m':
                date = new Date(date.setMonth(date.getMonth() - 6));
                break;
            case '1y':
                date = new Date(date.setFullYear(date.getFullYear() - 1));
                break;
            default:
                setChartSeries(accountBalancePoints);
                return;
        }

        const balances = accountBalancePoints.filter(
            (point) => new Date(point.created_at) > date,
        );
        if (balances && balances.length) {
            setStartingBalance(balances[0].value);
        }
        setChartSeries(sortPoints(balances));
    }

    function getStyledPercent(percentage: number): ReactElement {
        let classes = 'pi-color-primary-gray';
        let sign = '';
        if (percentage > 0) {
            classes = 'text-success';
            sign = '+';
        } else if (percentage < 0) {
            classes = 'text-danger';
            sign = '-';
        }
        return (
            <span className={classes}>
                {sign + percent(Math.abs(percentage), 2)}
            </span>
        );
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h1 className="mb-0">
                    <span className="pi-montserrat pi-font-size-20">
                        {currency(graphValue, 2)}
                    </span>
                    <span className="pi-montserrat ml-3 pi-font-size-16">
                        {getStyledPercent(change)}
                    </span>
                </h1>

                <div className="text-center d-flex mt-3 mt-lg-0 ml-auto ml-lg-0">
                    <Button
                        className="pi-primary-btn pi-montserrat mr-2 pi-font-size-12 shadow-sm d-flex justify-content-center align-items-center px-0 py-1"
                        style={{ opacity: 0.8, minWidth: 40 }}
                        onClick={() => filterBalance('1w')}>
                        1w
                    </Button>
                    <Button
                        className="pi-primary-btn pi-montserrat mr-2 pi-font-size-12 shadow-sm d-flex justify-content-center align-items-center px-0 py-1"
                        style={{ opacity: 0.8, minWidth: 40 }}
                        onClick={() => filterBalance('3m')}>
                        3m
                    </Button>
                    <Button
                        className="pi-primary-btn pi-montserrat mr-2 pi-font-size-12 shadow-sm d-flex justify-content-center align-items-center px-0 py-1"
                        style={{ opacity: 0.8, minWidth: 40 }}
                        onClick={() => filterBalance('6m')}>
                        6m
                    </Button>
                    <Button
                        className="pi-primary-btn pi-montserrat mr-2 pi-font-size-12 shadow-sm d-flex justify-content-center align-items-center px-0 py-1"
                        style={{ opacity: 0.8, minWidth: 40 }}
                        onClick={() => filterBalance('1y')}>
                        1y
                    </Button>
                    <Button
                        className="pi-primary-btn pi-montserrat mr-2 pi-font-size-12 shadow-sm d-flex justify-content-center align-items-center px-0 py-1"
                        style={{ opacity: 0.8, minWidth: 40 }}
                        onClick={() => filterBalance('all')}>
                        All
                    </Button>
                </div>
            </div>

            <div style={{ width: '100%', height: '20rem' }}>
                <AccountBalance
                    data={series}
                    mouseOverHandler={(value: number): void => {
                        setGraphValue(value);
                        setChange((value - startingBalance) / startingBalance);
                    }}
                    min={min}
                    increment={Math.round(
                        accountBalancePoints.length /
                            (window.innerWidth > 900 ? 3 : 2),
                    )}
                />
            </div>
        </div>
    );
}

export default PortfolioBalanceChart;
